import { Button, CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useQuery } from '@apollo/client'
import { WORK_PREP_REDIRECT_OBJECT_BY_UUID } from '../graphql/queries/workPreps'
import { navigationResponse } from '../types'

const Redirect = () => {
	const navigate = useNavigate()
	const params = useParams()
	const [isBadRedirectUUID, setIsBadRedirectUUID] = React.useState(false)

	const { loading, data } = useQuery(WORK_PREP_REDIRECT_OBJECT_BY_UUID, {
		variables: { uuid: params.redirectUUID },
	})

	const defaultPage = {
		keyWord: 'sendToHomePage',
		url: '/',
	}

	useEffect(() => {
		if (data) {
			if (data.workPrepRedirectObjectByUUID.length > 0) {
				//Skapa olika redirects
				const parsedData = JSON.parse(data.workPrepRedirectObjectByUUID)
				const navigationObj: navigationResponse = {
					redirectType: parsedData.redirectType,
					workPrepId: parsedData.workPrepId,
				}

				navigateToCorrectScreen(navigationObj)
			} else {
				setIsBadRedirectUUID(true)
			}
		}
	}, [data])

	const navigateToCorrectScreen: (
		navigationObject: navigationResponse
	) => void = (navigationObject) => {
		switch (navigationObject.redirectType) {
			case defaultPage.keyWord:
				navigate(defaultPage.url)
				break
			case 'completionRequest':
				navigate(
					`/admin-panel/editWorkPrep/${navigationObject.workPrepId}`,
					{
						state: {
							isFromCompleteRequest: true,
							navigationObject: navigationObject,
						},
					}
				)
				break
			case 'updateEndDate':
				navigate(`/updateEndDate/${navigationObject.workPrepId}`, {
					state: {
						isFromEmailRedirect: true,
						navigationObject: navigationObject,
					},
				})
				break
			case 'completionRequestFireAlarm':
				navigate(`/fire-alarm-edit/${navigationObject.workPrepId}`, {
					state: {
						isFromCompleteRequest: true,
						navigationObject: navigationObject,
					},
				})
				break
		}
	}

	if (isBadRedirectUUID) {
		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				height="100vh"
				flexDirection="column"
				gap="2rem"
			>
				<Typography
					id="transition-modal-title"
					variant="h6"
					component="h2"
				>
					<Trans i18nKey={'common.badRedirectUUID'} />
				</Typography>
				<Button variant="contained" onClick={() => navigate('/')}>
					<Trans i18nKey={'common.toStart'} />
				</Button>
			</Box>
		)
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			height="100vh"
			flexDirection="column"
			gap="2rem"
		>
			<CircularProgress size={'4rem'} />
			<Typography id="transition-modal-title" variant="h6" component="h2">
				<Trans i18nKey={'common.waitPlease'} />
			</Typography>
		</Box>
	)
}

export default Redirect
