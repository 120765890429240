import { Grid, Typography } from '@mui/material'
import { WorkPrepAnswers } from '../../types'
import { useTranslation } from 'react-i18next'
import { PrettyDateWithTime } from '../common/PrettyDate'

interface Props {
	workPrep: WorkPrepAnswers
	className?: string
}

const headerText = {
	fontWeight: 'medium',
	fontSize: 14,
}
const infoText = {
	fontSize: 14,
	color: 'gray',
}

const generateListItems: (
	workPrep: WorkPrepAnswers,
	t: any
) => JSX.Element[] = (workPrep, t) => {
	const returnList: JSX.Element[] = []
	returnList.push(
		<Grid item width={'200px'} marginBottom={'50px'} key={'1'}>
			<Grid container direction={'column'} rowSpacing={1}>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.createdBy')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.name}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.status')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.signStatus || '-'}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.date')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.signStatusChangedOn ? (
							<PrettyDateWithTime
								dateString={
									workPrep.creatorOfWorkPrep
										.signStatusChangedOn
								}
							/>
						) : (
							'-'
						)}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.signatureType')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.creatorOfWorkPrep.signatureType
							? t(
									'workPrepDetails.' +
										workPrep.creatorOfWorkPrep.signatureType
							  )
							: '-'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
	if (workPrep.projectManager?.exists) {
		returnList.push(
			<Grid item width={'200px'} marginBottom={'50px'} key={'2'}>
				<Grid container direction={'column'} rowSpacing={1}>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.projectManager')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.name || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.status')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.signStatus || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.date')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.signStatusChangedOn ? (
								<PrettyDateWithTime
									dateString={
										workPrep.projectManager
											.signStatusChangedOn
									}
								/>
							) : (
								'-'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.signatureType')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.projectManager.signatureType
								? t(
										'workPrepDetails.' +
											workPrep.projectManager
												.signatureType
								  )
								: '-'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	}
	if (workPrep.constructionWorkWillTakePlace.urwBaseU.exists) {
		returnList.push(
			<Grid item width={'200px'} marginBottom={'50px'} key={'3'}>
				<Grid container direction={'column'} rowSpacing={1}>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.urwBaseU')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.urwBaseU
								.name || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.status')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.urwBaseU
								.signStatus || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.date')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.urwBaseU
								.signStatusChangedOn ? (
								<PrettyDateWithTime
									dateString={
										workPrep.constructionWorkWillTakePlace
											.urwBaseU.signStatusChangedOn
									}
								/>
							) : (
								'-'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.signatureType')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.urwBaseU
								.signatureType
								? t(
										'workPrepDetails.' +
											workPrep
												.constructionWorkWillTakePlace
												.urwBaseU.signatureType
								  )
								: '-'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	}
	if (workPrep.constructionWorkWillTakePlace.baseP.exists) {
		returnList.push(
			<Grid item width={'200px'} marginBottom={'50px'} key={'4'}>
				<Grid container direction={'column'} rowSpacing={1}>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.baseP')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.baseP
								.name || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.status')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.baseP
								.signStatus || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.date')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.baseP
								.signStatusChangedOn ? (
								<PrettyDateWithTime
									dateString={
										workPrep.constructionWorkWillTakePlace
											.baseP.signStatusChangedOn
									}
								/>
							) : (
								'-'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.signatureType')}
						</Typography>
						<Typography sx={infoText}>
							{workPrep.constructionWorkWillTakePlace.baseP
								.signatureType
								? t(
										'workPrepDetails.' +
											workPrep
												.constructionWorkWillTakePlace
												.baseP.signatureType
								  )
								: '-'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	}
	returnList.push(
		<Grid item width={'200px'} marginBottom={'50px'} key={'5'}>
			<Grid container direction={'column'} rowSpacing={1}>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.management')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.statusChangedBy || '-'}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.status')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.status || '-'}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.date')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.statusChangedOn ? (
							<PrettyDateWithTime
								dateString={workPrep.statusChangedOn}
							/>
						) : (
							'-'
						)}
					</Typography>
				</Grid>
				<Grid item>
					<Typography sx={headerText}>
						{t('workPrep.signatureType')}
					</Typography>
					<Typography sx={infoText}>
						{workPrep.status
							? t('workPrepDetails.AdminPanel')
							: '-'}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
	return returnList
}
const SignStatusList = ({ workPrep, className = '' }: Props) => {
	const { t } = useTranslation()

	return (
		<Grid container paddingLeft={'20px'} marginY={2} columnSpacing={10}>
			{generateListItems(workPrep, t)}
		</Grid>
	)
}

export default SignStatusList
