import { useEffect, useState } from 'react'
import { ServiceWorkAnswers, SnackbarData } from '../../types'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { SERVICE_WORK_BY_ID } from '../../graphql/queries/workPreps'
import {
	Alert,
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { ScrollToError } from '../../components/formik/ScrollToError'
import ArrowForward from '@mui/icons-material/ArrowForward'
import * as Yup from 'yup'
import { pdf } from '@react-pdf/renderer'
import ServiceWorkPDF from '../../components/admin/pdf/ServiceWorkPDF'
import { UPDATE_SERVICE_WORK } from '../../graphql/mutations/workPreps'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import EditServiceWorkForm from '../../components/forms/EditServiceWorkForm'
import InfoText from '../../components/common/InfoText'
import CustomDivider from '../../components/common/CustomDivider'
import PaperLooksContainer from '../../components/common/PaperLooksContainer'
import { deepCopyObject } from '../../utils/helperFunctions'
import { useAuth } from '../../hooks/useAuth'

const EditServiceWork = () => {
	const [serviceWork, setServiceWork] = useState<ServiceWorkAnswers | null>(
		null
	)
	const { userEmail, jwt, loadingRole } = useAuth()

	const [updateServiceWork] = useMutation(UPDATE_SERVICE_WORK)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const params = useParams()

	const { loading, data } = useQuery(SERVICE_WORK_BY_ID, {
		variables: { serviceWorkId: params.serviceWorkId, jwt },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		window.scrollTo(0, 0) //Scroll to top
		if (data) {
			const serviceWork: ServiceWorkAnswers = JSON.parse(
				data.serviceWorkById
			)
			setServiceWork(deepCopyObject(serviceWork))
		}
	}, [data])

	if (loading || loadingRole) return <LoadingSpinner />

	if (!serviceWork) return <NotLoggedIn />

	const editServiceWorkValidationSchema = Yup.object({
		createdByCompany: Yup.string().required(t('common.required')),
		createdByPersonName: Yup.string().required(t('common.required')),
		telephoneNumberToCreator: Yup.string().required(t('common.required')),
		emailToCreator: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.required(t('common.required')),
		iHaveRead: Yup.bool().oneOf([true], t('common.required')),
		workSteps: Yup.string().required(t('common.required')),
		relevantMall: Yup.string().required(t('common.required')),

		workArea: Yup.string().required(t('common.required')),
		floor: Yup.string().required(t('common.required')),
		storeIdOrName: Yup.string().required(t('common.required')),
		startDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required')),
		endDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required'))
			.min(Yup.ref('startDate'), t('common.beforeStart')),
		startingTime: Yup.date()
			.typeError(t('common.invalidTime'))
			.required(t('common.required')),
		endingTime: Yup.date()
			.typeError(t('common.invalidTime'))
			.required(t('common.required')),
		numPeople: Yup.number()
			.min(1, t('workPrep.validation.atLeastOnePerson'))
			.integer(t('common.mustBeInteger'))
			.typeError(t('common.mustBeInteger'))
			.required(t('common.required')),
	})

	const initialFormikValues = {
		id: serviceWork.id,
		caseId: serviceWork.creatorOfWorkPrep.caseId,
		workSteps: serviceWork.workSteps,
		relevantMall: serviceWork.relevantMall,
		createdByCompany: serviceWork.creatorOfWorkPrep.company,
		createdByPersonName: serviceWork.creatorOfWorkPrep.name,
		telephoneNumberToCreator: serviceWork.creatorOfWorkPrep.telephoneNumber,
		emailToCreator: serviceWork.creatorOfWorkPrep.email,
		workArea: serviceWork.workArea,
		floor: serviceWork.floor,
		numPeople: serviceWork.numPeople,
		storeIdOrName: serviceWork.storeIdOrName,
		startDate: serviceWork.startDate,
		endDate: serviceWork.endDate,
		startingTime: new Date(
			1,
			1,
			1,
			Number(serviceWork.workingHours.startingTime.split(':')[0]),
			Number(serviceWork.workingHours.startingTime.split(':')[1])
		),
		endingTime: new Date(
			1,
			1,
			1,
			Number(serviceWork.workingHours.endingTime.split(':')[0]),
			Number(serviceWork.workingHours.endingTime.split(':')[1])
		),
		userEmail: serviceWork.isCreatedLoggedIn.email,
	}

	const handleSubmit = async (values: any, actions: any) => {
		let startingTimeHours = `${values.startingTime.getHours()}`,
			startingTimeMinutes = `${values.startingTime.getMinutes()}`,
			endingTimeHours = `${values.endingTime.getHours()}`,
			endingTimeMinutes = `${values.endingTime.getMinutes()}`

		if (parseInt(startingTimeHours) < 10) {
			startingTimeHours = `0${startingTimeHours}`
		}

		if (parseInt(startingTimeMinutes) < 10) {
			startingTimeMinutes = `0${startingTimeMinutes}`
		}

		if (parseInt(endingTimeHours) < 10) {
			endingTimeHours = `0${endingTimeHours}`
		}

		if (parseInt(endingTimeMinutes) < 10) {
			endingTimeMinutes = `0${endingTimeMinutes}`
		}

		const serviceWorkAnswers: ServiceWorkAnswers = {
			id: serviceWork.id,
			status: '',
			statusChangedBy: '',
			statusChangedOn: '',
			workSteps: values.workSteps,
			relevantMall: values.relevantMall,
			creatorOfWorkPrep: {
				company: values.createdByCompany,
				name: values.createdByPersonName,
				telephoneNumber: values.telephoneNumberToCreator,
				email: values.emailToCreator,
				signStatus: '',
				signStatusChangedOn: '',
				signatureType: '',
				caseId: serviceWork.creatorOfWorkPrep.caseId,
			},
			establishedDate: serviceWork.establishedDate,
			workArea: values.workArea,
			floor: values.floor,
			storeIdOrName: values.storeIdOrName,
			startDate: values.startDate!,
			endDate: values.endDate!,
			workingHours: {
				startingTime: `${startingTimeHours}:${startingTimeMinutes}`,
				endingTime: `${endingTimeHours}:${endingTimeMinutes}`,
			},
			numPeople: values.numPeople.toString(),
			isCreatedLoggedIn: serviceWork.isCreatedLoggedIn,
			lastEdited: {
				lastEditedOn: new Date().toString(),
				lastEditedBy: userEmail ? userEmail : '',
			},
		}

		setSnackbarData({
			open: true,
			severity: 'info',
			message: t('serviceWork.submittingServiceWork'),
		})

		const serviceWorkBlob = await pdf(
			<ServiceWorkPDF
				currentServiceWork={serviceWorkAnswers}
				customLang="sv"
			/>
		).toBlob()

		const serviceWorkPDF = new File([serviceWorkBlob], 'filename.pdf')

		const res = await updateServiceWork({
			variables: {
				serviceWorkAnswers: JSON.stringify(serviceWorkAnswers),
				serviceWorkPDF,
				jwt,
			},
		})

		if (res.data.updateServiceWork === 'Assently error') {
			setSnackbarData({
				message: t('workPrep.assentlyError'),
				severity: 'error',
				open: true,
			})
		} else {
			setSnackbarData({
				message: t('serviceWork.serviceWorkSubmitted'),
				severity: 'success',
				open: true,
			})
			// if change is made by admin, signature notification is sent instead.
			if (res.data.updateServiceWork === 'Notification sent') {
				navigate(`/service-work-panel/${params.serviceWorkId}`)
			} else {
				try {
					const redirectUrl = new URL(res.data.updateServiceWork)
					window.location.replace(redirectUrl)
				} catch {
					setSnackbarData({
						message: t('workPrep.urlError'),
						severity: 'error',
						open: true,
					})
				}
			}
		}
	}

	return (
		<>
			<PaperLooksContainer
				backBtnPath={`/service-work-panel/${params.serviceWorkId}`}
			>
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
				>
					<Grid item>
						<h1>
							{t('serviceWork.title') +
								': ' +
								params.serviceWorkId}
						</h1>
					</Grid>
					<CustomDivider />
					<Formik
						initialValues={initialFormikValues}
						validationSchema={editServiceWorkValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form>
								<ScrollToError />
								<EditServiceWorkForm />
								<Grid item>
									<InfoText
										sx={{
											borderColor: '#ef4444',
											backgroundColor: '#fef2f2',
											marginBottom: '10px',
										}}
									>
										<Typography>
											{t('editWorkPrep.warning')}
										</Typography>
									</InfoText>
								</Grid>
								<Grid
									item
									display={'flex'}
									justifyContent={'right'}
								>
									<Button
										disabled={isSubmitting}
										type="submit"
										variant="contained"
										endIcon={
											isSubmitting ? (
												<CircularProgress size={20} />
											) : (
												<ArrowForward />
											)
										}
										sx={{ height: 50 }}
									>
										{t('workPrep.save')}
									</Button>
								</Grid>
							</Form>
						)}
					</Formik>
				</Grid>
			</PaperLooksContainer>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default EditServiceWork
