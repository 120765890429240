import { SxProps, Typography } from '@mui/material'
import Box from '@mui/material/Box'

interface Props {
	title: string
	comment: string
	children?: JSX.Element | JSX.Element[]
	sx?: SxProps
}

const CustomInfoField = ({ title, comment, children, sx }: Props) => {
	return (
		<Box
			sx={{ ...sx, pb: '10px', display: 'flex', flexDirection: 'column' }}
		>
			<Box
				sx={{
					display: 'flex',
					pr: '10px',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}
			>
				<Typography fontWeight={500}>{title}</Typography>
				<Typography fontWeight={300} fontSize={14}>
					{comment}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
				{children}
			</Box>
		</Box>
	)
}

export default CustomInfoField
