import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import Bullet from '../common/Bullet'
import CustomDivider from '../common/CustomDivider'
import InfoText from '../common/InfoText'
import CustomTextInput from '../formik/CustomTextInput'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'

const CreatorForm = () => {
	const { t } = useTranslation()

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByCompany')}
					name="createdByCompany"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByPersonName')}
					name="createdByPersonName"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.telephoneNumberToCreator')}
					name="telephoneNumberToCreator"
					type="text"
					inputMode="tel"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.emailToCreator')}
					name="emailToCreator"
					type="text"
					inputMode="email"
				/>
			</Grid>
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						mt: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('workPrep.infoBox1.title')}
					</Typography>

					<ul className="ml-4">
						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet1')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet2')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet3')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet4')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet5')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet6')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								<Trans i18nKey="workPrep.infoBox1.bullet7">
									So called Dust-Control
									<span className="font-medium underline">
										SHOULD
									</span>
									be on the work surface during dust and smoke
									generating operations.
								</Trans>
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet8')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet9')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet10')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography
								fontWeight={500}
								sx={{
									textDecoration: 'underline',
								}}
							>
								{t('workPrep.infoBox1.bullet11')}
							</Typography>
						</Bullet>
					</ul>
				</InfoText>
			</Grid>
			<Grid item>
				<CustomCheckboxInput
					label={t('workPrep.iHaveRead')}
					name="iHaveRead"
				/>
			</Grid>

			<CustomDivider />
		</Grid>
	)
}

export default CreatorForm
