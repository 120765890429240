import LoadingSpinner from '../../components/common/LoadingSpinner'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import { useAuth } from '../../hooks/useAuth'

interface Props {
	children: JSX.Element
}

const RoleWrapper = ({ children }: Props) => {
	const { role, loadingRole } = useAuth()

	if (loadingRole) return <LoadingSpinner />

	if (!role) return <NotLoggedIn />

	return <>{children}</>
}
export default RoleWrapper
