import { useEffect, useState } from 'react'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { useQuery } from '@apollo/client'
import { GET_USERS } from '../../graphql/queries/users'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material'
import CustomDataGrid from '../../components/admin/CustomDataGrid'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { displayRole, roleIsAuthorizedOver } from '../../utils/helperFunctions'
import ClearIcon from '@mui/icons-material/Clear'
import { useAuth } from '../../hooks/useAuth'

export interface UserItem {
	name: string
	email: string
	role: string
}

const UsersPanel = () => {
	const navigate = useNavigate()
	const { role, jwt } = useAuth()
	const { t } = useTranslation()
	const [allUsers, setAllUsers] = useState<UserItem[]>([])
	const [search, setSearch] = useState<string>('')
	const [filterRole, setFilterRole] = useState<string>('')

	const { loading, error, data } = useQuery(GET_USERS, {
		variables: { jwt },
		fetchPolicy: 'network-only',
	})

	const newUser = () => {
		navigate('/create-user')
	}

	useEffect(() => {
		if (data) {
			const allUsersParsed = JSON.parse(data.getUsers)

			//Filter our prod test accounts
			let filteredUsers = allUsersParsed.filter(
				(user: UserItem) => !user.name.includes('#WKI')
			)

			filteredUsers = filteredUsers.filter((user: UserItem) => {
				if (
					user.name.toLowerCase().includes(search.toLowerCase()) ||
					user.email.toLowerCase().includes(search.toLowerCase())
				) {
					return true
				}
				return false
			})
			filteredUsers = filteredUsers.filter((user: UserItem) =>
				user.role.toLowerCase().includes(filterRole.toLowerCase())
			)

			const userItems: UserItem[] = filteredUsers.map(
				(user: UserItem) => {
					return {
						id: user.email,
						name: user.name,
						email: user.email,
						role: displayRole(user.role),
					}
				}
			)
			setAllUsers(userItems)
		}
	}, [data, search, filterRole])

	if (!role) return <LoadingSpinner />
	if (loading) return <LoadingSpinner />

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: t('userPanel.name'),
			width: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'email',
			headerName: t('userPanel.email'),
			width: 300,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'role',
			headerName: t('userPanel.role'),
			width: 300,
			headerAlign: 'left',
			align: 'left',
		},
	]

	const getMenuItems = () => {
		const authorizedOver = roleIsAuthorizedOver(role)
		const menuItems = authorizedOver.map((role) => (
			<MenuItem key={role} value={role}>
				{displayRole(role)}
			</MenuItem>
		))
		menuItems.unshift(
			<MenuItem key={'noRole'} value={''}>
				{t('userPanel.noRole')}
			</MenuItem>
		)
		return menuItems
	}

	return (
		<Grid
			container
			direction="column"
			spacing={2}
			paddingX={{ xs: 1, sm: 5 }}
		>
			<Grid item marginTop={'50px'}>
				<Typography variant="h5" fontWeight={'500'}>
					{t('userPanel.title')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>{t('userPanel.helperText')}</Typography>
			</Grid>
			<Grid item>
				<Grid
					container
					justifyContent={'space-between'}
					columnGap={1}
					rowGap={1}
				>
					<Grid item flex={1}>
						<Grid
							container
							direction={'row'}
							columnSpacing={1}
							rowSpacing={1}
							maxWidth={'500px'}
						>
							<Grid item flex={1}>
								<TextField
									fullWidth
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '0px',
										},
									}}
									id="filterName"
									label={t('userPanel.search')}
									variant="outlined"
									value={search}
									onChange={(
										event: React.ChangeEvent<HTMLInputElement>
									) => {
										setSearch(event.target.value)
									}}
									InputProps={{
										endAdornment: (
											<IconButton
												sx={{
													visibility: search.length
														? 'visible'
														: 'hidden',
												}}
												onClick={() => setSearch('')}
											>
												<ClearIcon />
											</IconButton>
										),
									}}
								/>
							</Grid>
							<Grid item flex={1}>
								<TextField
									fullWidth
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '0px',
										},
									}}
									id="filterRole"
									label={t('userPanel.searchRole')}
									value={filterRole}
									select
									onChange={(event: any) => {
										setFilterRole(event.target.value)
									}}
								>
									{getMenuItems()}
								</TextField>
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<Button
							sx={{
								height: '50px',
								minWidth: {
									xs: 'calc(100vw - 16px)',
									sm: '220px',
								},
								whiteSpace: 'nowrap',
							}}
							variant="contained"
							startIcon={<Add />}
							onClick={newUser}
						>
							{t('userPanel.newUser')}
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Box height={750}>
					<CustomDataGrid
						type={'user-panel'}
						data={allUsers}
						columns={columns}
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export default UsersPanel
