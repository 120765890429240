import { title } from 'process'

export const SV_TRANSLATIONS = {
	homePage: {
		welcomeTitle:
			'Välkommen till Unibail Rodamco Westfield hemsida för arbetsberedning!',
		welcomeTitle1: 'Välkommen till',
		welcomeTitle2: 'Unibail Rodamco Westfield',
		welcomeTitle3: 'hemsida för arbetsberedning!',
		fillOutFireAlarmShutdownBtnText:
			'Ansök om Brandlarmsavstängning & Heta arbeten',
		fillOutWorkPrepBtnText: 'Fyll i arbetsberedning',
		fillOutServiceWorkBtnText: 'Ansök om servicejobb',
		adminPanelBtnText: 'Admin-panel',
		createUserBtnText: 'Skapa användare',
		loginBtnText: 'Logga in',
		logoutBtnText: 'Logga ut',
	},
	createUser: {
		title: 'Skapa användare',
		form: {
			name: 'Namn',
			email: 'E-post',
			password: 'Lösenord',
			minPasswordLength: 'Lösenordet måste vara minst sex tecken',
			confirmPassword: 'Bekräfta lösenord',
			chooseARole: 'Välj en roll',
			role: 'Roll',
			submitBtnText: 'Skapa användare',
		},
		creatingUser: 'Skapar användare...',
		userCreated:
			'Användare skapad! Ett mail har skickats till den angivna e-posten.',
		error: 'Ett fel uppstod...',
		passwordInfo:
			'Genom att klicka på knappen "Skapa användare" kommer ett mejl med instruktioner för att skapa ett lösenord skickas till e-posten kopplat till användaren.',
	},
	fireAlarmShutdown: {
		idTitle:
			'Välkommen till ansökan för Brandlarmsavstängning & Heta Arbeten',
		idInfoText:
			'För att kunna ansöka om dessa arbeten behöver det finnas en godkänd arbetsberedning som sedan genererar ett ID-nummer som skrivs in enligt nedan.',
		fillWorkPrep: 'Här fyller du i en arbetsberedning',
		findId: 'Så hittar du ID-numret på en godkänd arbetsberedning',
		questions:
			'För frågor kontakta: \n Westfield Mall of Scandinavia - work.wmos@urw.se \n Westfield Täby centrum - work.wtc@urw.se \n Nacka Forum - work.nacka@urw.se',
		idInput: 'ID på arbetsberedning',
		idSubmit: 'Fyll i Brandlarmsavstängning & Heta arbeten',
		idChecking: 'Kollar ID-nummber',
		idSubmitFail: 'Ange ett giltigt ID',
		idWrongFormat: 'Ange på formatet XXXX-XX-XX',
		noAccess: 'Du kan inte komma åt denna sida utan ett giltigt ID',
		form: {
			title: 'Ansök om Brandlarmsavstängning & Heta arbeten',
			applies: 'Gäller arbetsberedning med ID: ',
			fireAlarmOrHotWork: 'Brandlarmsavstängning eller Heta arbeten',
			fireAlarm: 'Brandlarmsavstängning',
			hotWork: 'Heta arbeten',
			workArea: 'Område/verksamhet/lokal som berörs av frånkopplingen',
			shutdownReason: 'Orsak till frånkoppling:',
			dateShutdown: 'Datum för frånkoppling:',
			dateReconnect: 'Datum för tillkoppling:',
			nameApplicant: 'Namn kontaktperson på plats',
			phonenumberApplicant: 'Telefonnummer kontaktperson på plats',
			emailApplicant: 'Email kontaktperson på plats',
			companyApplicant: 'Företag kontaktperson på plats',
			contactPerson: 'Kontaktperson hos fastighetsägaren',
			submitForm: 'Skicka in Brandlarmsavstängning & Heta arbeten',
			submittingForm: 'Förbereder formulär...',
			uploadingData: 'Laddar upp data',
			submittedForm: 'Brandlarmsavstängning & Heta arbeten inskickad',
			submittedFormError: 'Något gick fel, testa igen',
			times: 'Tider då brandlarmet behöver vara frånkopplat',
			timesInfo:
				'Vid avstängning brandlarm för ”ALLA DAGAR, DYGNET RUNT” krävs bemannad brandvakt under hela perioden',
			weekdays: 'Helgfria dagar (måndag - fredag)',
			weekends: 'Helger (lördag + söndag + röda dagar)',
			aroundTheClock: 'Alla dagar, dygnet runt*',
			aroundTheClockWithoutInfo: 'Alla dagar, dygnet runt',
			creator: 'Upprättare',
			contactPersonOnSite:
				'Kontaktperson på plats hos tillståndsansökaren under frånkopplingen',
			assentlyError:
				'Något gick fel med eSign-tjänsten. Se till att alla emails är korrekta.',
			addContactPerson: 'Lägg till kontaktperson på plats',
			infoBox: {
				title: 'Tillståndskrav vid avstängning av brandlarm:',
				bullet1:
					'Arbetet får inte påbörjas förrän bekräftelse från anläggningsskötare att brandlarmet är frånkopplat enligt begäran från tillståndansökaren.',
				bullet2: '',
				bullet3:
					'När brandlarmet är avstängt ska tillståndsansökarens personal alltid finnas inom det avstängda området för att snabbt upptäcka en brand samt larma och försöka släcka branden. Detta gäller även under arbetsraster. Larmning ska ske genom att trycka in en larmknapp och kontakta vakt. Om arbetsplatsen vid kontroll inte är bemannad så ogiltigförklaras tillståndet och brandlarm tillkopplas. Alternativt så avskiljs de frånkopplade området från publika delar i lägst E30 eller förses med byggbrandlarm.',
				bullet4:
					'Eventuella kostnader för räddningstjänstens utryckningar vid brandlarm som orsakats av tillståndsansökarens försummelse bekostas av tillståndsansökaren. Det tillkommer även en extra administrativ kostnad på 5 000 kr för Unibail-Rodamco-Westfields hantering av insatsen.',
				bullet5: 'Utrymningslarm i publika delar ska vara i drift.',
				bullet6:
					'*Vid avstängning brandlarm för ”ALLA DAGAR, DYGNET RUNT” krävs bemannad brandvakt under hela perioden.',
			},
			stepper: {
				scope: 'Omfattning',
				sign: 'Signering',
			},
			submitted: 'Inskickad',
			notSubmitted: 'E-signeringen avbröts',
			submittedInfoText:
				'Ansökan är nu signerad av er, innan berört arbete får starta så behöver ansökan godkännas av centrumledningen. Beslut meddelas via email. För frågor gällande en ansökan kontakta: \n \n Westfield Mall of Scandinavia: work.wmos@urw.se \n Westfield Täby Centrum: work.wtc@urw.se \n Nacka Centrum: work.nacka@urw.se',
			notSubmittedInfoText:
				'Signering av arbetet avbröts, en påminnelse skickas ut till den angivna emailadressen om en dag',
			toStart: 'Till startsida',
			tryAgain: 'Tillbaka till signering',
		},
		edit: {
			updating: 'Uppdaterar Brandlarmsavstängning & Heta arbeten...',
			updated: 'Brandlarmsavstängning & Heta arbeten uppdaterad!',
		},
	},
	serviceWork: {
		title: 'Servicejobb',
		introText:
			'Godkänd servicejobb ska kunna uppvisas av personal på plats under pågående arbete.',
		submittingServiceWork: 'Förbereder servicejobb...',
		uploadingData: 'Laddar upp data',
		serviceWorkSubmitted: 'Servicejobb är inskickad!',
		serviceWorkLogin: {
			title: 'Ansökan för servicejobb',
			info: 'Ansökan för servicejobb görs via adminpanelen, och kräver ett upprättarkonto. Logga in, eller skicka ett mejl till nedan address för att ansöka om ett upprättarkonto.',
			loginInfo: 'Här loggar du in till adminpanelen!',
			emails: 'Ansökan för upprättarkonto görs till: \n Westfield Mall of Scandinavia - work.wmos@urw.se \n Westfield Täby centrum - work.wtc@urw.se \n Nacka Forum - work.nacka@urw.se',
			subject: 'Ange ämne: Ansökan Upprättarkonto.',
		},
	},
	workPrep: {
		title: 'Arbetsberedning',
		introText:
			'Godkänd arbetsberedning ska kunna uppvisas av personal på plats under pågående arbete.',
		workSteps: 'Arbetsmoment (Kort beskrivning på vad som ska utföras)',
		relevantMall: 'Relevant centrum',
		createdByCompany: 'Upprättad av (företagsnamn)',
		createdByPersonName: 'Upprättad av (namn)',
		telephoneNumberToCreator: 'Telefonnummer till upprättare',
		emailToCreator: 'Mailadress till upprättare',
		status: 'Status',
		statusChangedBy: 'Status ändrad av',
		establishedDate: 'Skapad',
		event: 'Händelse',
		createdBy: 'Upprättare',
		workArea: 'Arbetsområde (Butik, galleriagång, teknikyta etc)',
		floor: 'Våningsplan',
		numPeople: 'Antal personer',
		storeIdOrName: 'Lokalnummer/Butiksnamn',
		startDate: 'Startdatum',
		endDate: 'Slutdatum',
		startingTime: 'Starttid',
		endingTime: 'Sluttid',
		date: 'Datum',
		signStatus: 'Signeringsstatus',
		signatureType: 'Signeringstyp',
		projectManager: 'Hyresgästkoordinator/ projektledare',
		management: 'Förvaltning',

		hotWork: 'Heta arbeten',
		hotWorkPermissionQuestion: 'Kräver arbetet heta arbeten intyg?',
		hotWorkPermissionComment:
			'Ansökan om brandlarmsavstängning & Heta arbeten sker separat efter att arbetsberedningen blivit godkänd. Via huvudmenyn väljer du ”Ansök om brandlarmsavstängning & Heta arbeten” och fyller i ditt ID-nummer som du får när arbetsberedningen blir godkänd.',
		hotWorkPermitManager: 'Tillståndsansvarig',

		fireAlarmWillBeShutOff: 'Avstängt brandlarm',
		fireAlarmWillBeShutOffQuestion:
			'Krävs det att brandlarmet skall stängas av?',
		fireAlarmWillBeShutOffComment:
			'Ansökan om brandlarmsavstängning & Heta arbeten sker separat efter att arbetsberedningen blivit godkänd. Via huvudmenyn väljer du ”Ansök om brandlarmsavstängning & Heta arbeten” och fyller i ditt ID-nummer som du får när arbetsberedningen blir godkänd.',
		duringWhatTimes: 'Under vilka tider och datum?',

		contactDriftHotWork:
			'Kontakta driften minst 48 timmar innan det heta arbetet ska ske.',
		contactDriftFireAlarm:
			'Kontakta driften minst 48 timmar innan brandlarmsavstängningen ska ske.',
		contactDriftEmail:
			'<1>Westfield Mall of Scandinavia: </1><2>mos@vimini.se</2><3 /><4>Täby Centrum: </4><5>tabycentrum@propertypartner.se</5><6 /><7>Nacka Forum: </7><8>nacka@alova.se</8><9> (Telefonnummer: 08 - 4080 89 40, Arbetstider: 07:00-17:00)</9>',
		contactDriftEmailShouldInclude: 'Mailet ska innehålla:',
		contactDriftEmailShouldIncludeHotWork:
			'<1>1. Rubrik i ämnesrad ”Unikt ID-nummer + heta arbeten”</1><2 /><3>2. Godkänd arbetsberedning i PDF-format</3><4 /><5>3. Förslag på datum och tid för det heta arbetet</5><6 /><7>4. Kontaktperson till ansvarig</7>',
		contactDriftEmailShouldIncludeFireAlarmWithLink:
			'<1>1. Rubrik i ämnesrad ”Unikt ID-nummer + Brandlarmsavstängning”</1><2 /><3>2. Ifylld blankett för avstängningen i PDF-format. </3><4>Här kan du ladda ner blanketten</4><5 /><6>3. Godkänd arbetsberedning i PDF-format</6>',
		contactDriftEmailShouldIncludeFireAlarm:
			'<1>1. Rubrik i ämnesrad ”Unikt ID-nummer + Brandlarmsavstängning”</1><2 /><3>2. Ifylld blankett för avstängningen i PDF-format. </3><4 /><5>3. Godkänd arbetsberedning i PDF-format</5>',
		contactDriftContactPerson:
			'Ansökan ska alltid ske med kontaktperson från URW i kopia',

		workWithWaterWillBeDoneQuestion:
			'Kommer arbeten på vattensystem att utföras?',
		workWithWaterCertificateComment: 'Bifoga intyg från Säker Vatten',
		chooseWaterCertificate: 'Välj intyg från Säker Vatten',
		safeWaterComment: 'Här kan du läsa mer om<1> säker vatten intyget</1>',

		ventilationWillBeShutOff: 'Avstängd ventilation',
		ventilationWillBeShutOffQuestion:
			'Krävs det att ventilationen skall stängs av?',
		ventilationWillBeShutOffComment1: 'Observera om du väljer ',
		ventilationWillBeShutOffComment2: 'JA ',
		ventilationWillBeShutOffComment3:
			'på denna behöver centrumets driftföretag undersöka om detta är möjligt, vilket kan vara förenad med en kostnad.',

		electricalWorkWillBeDoneQuestion: 'Kommer elarbeten att utföras?',
		electricalWorkWillBeDoneComment:
			'Här kan du hämta hem<1> intyget för elinstallatören från Elsäkerhetsverket</1>',
		electricalWorkCertificateComment: 'Bifoga intyg från elsäkerhetsverket',
		chooseElectricalWorkCertificate: 'Välj intyg från elsäkerhetsverket',

		sprinklerSystemWillBeShutOff: 'Avstängt sprinklersystem',
		sprinklerSystemWillBeShutOffQuestion:
			'Krävs det att sprinklersystem skall stängs av?',
		sprinklerSystemWillBeShutOffComment1: 'Observera om du väljer ',
		sprinklerSystemWillBeShutOffComment2: 'JA ',
		sprinklerSystemWillBeShutOffComment3:
			'på denna behöver centrumets sprinklerföretag undersöka om detta är möjligt, vilket kan vara förenad med en kostnad.',

		constructionWorkWillTakePlaceQuestion:
			'Kommer ett byggnadsarbete utföras?',
		constructionWorkWillTakePlaceComment:
			'Vad räknas som ett<1> byggnadsarbete</1>?<2 /><3>Här hittar du mer information kring en arbetsmiljöplan</3>',
		attachConstructionEnvironmentWorkPlanComment:
			'Bifoga egen arbetsmiljöplan',

		urwBaseU: 'BAS-U',
		urwBaseUExistsQuestion: 'Finns en BAS-U?',
		urwBaseUExistsComment:
			'Arbetsberedningen gäller tillsammans med URW´s riktlinjer för arbetsmiljö med tillhörande ordnings- och skyddsregler, dessa ska finnas den utförande tillhanda.<1 /><2>Här hittar du mer information kring BAS-U/P</2>',
		nameToURWBaseU: 'Namn till BAS-U',
		telephoneToURWBaseU: 'Telefon till BAS-U',
		emailToURWBaseU: 'Email till BAS-U',

		otherBaseU: 'Annan BAS-U',
		otherBaseUExistsQuestion: 'Finns en annan BAS-U?',
		nameToOtherBaseU: 'Namn till annan BAS-U',
		telephoneToOtherBaseU: 'Telefon till annan BAS-U',
		emailToOtherBaseU: 'Email till annan BAS-U',
		baseUCertificateComment: 'Bifoga BAS-U Certifikat',
		basePCertificateComment: 'Bifoga BAS-P Certifikat',
		chooseBaseUCertificate: 'Välj BAS-U Certifikat',
		chooseBasePCertificate: 'Välj BAS-P Certifikat',

		willTheWorkTakeLongerThan30DaysQuestion:
			'Kommer arbetet ta mer än 30 dagar och bestå av fler än 20 personer vid något tillfälle som sysselsätts samtidigt?',
		willTheWorkTakeLongerThan30DaysQuestionShort:
			'Kommer arbetet ta mer än 30 dagar och bestå av fler än 20 personer',
		willTheWorkTakeLongerThan30DaysComment:
			'Här hittar du mer information kring<1> förhandsanmälan om byggarbetsplats</1>',
		willTheWorkTakeLongerThan30DaysCertificateComment:
			'Bifoga förhandsanmälan om byggarbetsplatsen till Arbetsmiljöverket',

		followUpInfoBric: 'Uppföljning av arbetsmiljö sker via Infobric',
		onlyBiggerContracts:
			'Endast aktuellt i större entreprenader, stäm av med din beställare om detta krävs',
		registrationOfProject: 'Anmälan av projekt sker',

		attachScheduleForTheProjectComment: 'Bifoga tidplan för projektet',
		scheduleForTheProject: 'Tidplan för projektet',

		baseP: 'BAS-P',
		basePExistsQuestion: 'Finns en BAS-P?',
		basePExistsComment:
			'Arbetsberedningen gäller tillsammans med URW´s riktlinjer för arbetsmiljö med tillhörande ordnings- och skyddsregler, dessa ska finnas den utförande tillhanda.<1 /><2>Här hittar du mer information kring BAS-U/P</2>',
		nameToBaseP: 'Namn till BAS-P',
		telephoneToBaseP: 'Telefon till BAS-P',
		emailToBaseP: 'Email till BAS-P',

		projectManagerExistsQuestion:
			'Finns hyresgästkoordinator/projektledare hos URW?',
		nameToProjectManager: 'Namn',
		telephoneToProjectManager: 'Telefon',
		emailToProjectManager: 'Email',

		insurance: 'Försäkring',
		insuranceExistsQuestion: 'Försäkring finns och är betald.',
		insuranceCompanyNameQuestion: 'Ange försäkringsbolag',
		insuranceContractNumberQuestion: 'Ange avtalsnummer',
		insuranceCompanyNameComment:
			'Om ni inte vet namnet, kontakta oss snarast efter inskickad arbetsberedning när ni tagit reda på det.',

		certainRisks: {
			question: 'Vilka särskilda risker förekommer i entreprenaden?',
			comment:
				'"Särskilda risker" enl. AFS 1999:3 (2008:16 §12) och övriga arbetsmiljörisker.',
			fallRisk: 'Fallrisk (vid arbete på höjd högre än 2 m)',
			hazardousChemOrBioSubstances:
				'Hälsofarliga kemiska eller biologiska ämnen',
			heavyLifts: 'Tunga lyft av ... byggelement',
			passingVehicleTraffic: 'Passerande fordonstrafik',
			dust: 'Damm',
			vibrationsOrnoise: 'Vibrationer/buller',
			fireHazard: 'Brandfara',
			other: 'Annan',
		},

		areaWhereWorkWillBePerformed: {
			question: 'Område, var kommer arbetet att utföras?',
			restaurant: 'Restaurang',
			store: 'Butik',
			shoppingMallAisle: 'Galleriagång',
			garage: 'Garage',
			technicalSpace: 'Teknikutrymme',
			serviceCorridor: 'Servicekorridor',
			workOutsideFacade: 'Arbete utanför fasad',
			squareOrEntrance: 'Torg/Entré',
		},

		measureForWorkEnvironmentRisks: 'Åtgärd för arbetsmiljörisker',
		measureForWorkEnvironmentRisksComment:
			'Ange de arbetsmoment där arbetsmiljörisker enligt ovan föreligger och beskriv åtgärder för att minska risken att någon kommer till skada.',

		validation: {
			atLeastOnePerson: 'Måste vara minst en person',
		},

		infoBox1: {
			title: 'Tänk på följande när du bereder arbetsmomenten',
			bullet1: 'Utrymningsvägar får inte blockeras.',
			bullet2:
				'Utrustning, liftar och material får ej lämnas kvar på arbetsområdet efter dagens slut och städning ska ske dagligen.',
			bullet3:
				'Alla heta arbeten kräver tillstånd, påverkas sprinkler och brandlarm måste detta meddelas.',
			bullet4:
				'Samtlig personal som arbetar i lokalen är skyldig att uppvisa giltigt ID06 vid kontroll.',
			bullet5:
				'Brandtätning mellan brandceller ska återställas vid arbetsdagens slut.',
			bullet6:
				'Arbetsområdet ska täckas in för att minimera skada på egendom som följd av t.ex. dammspridning mm. där så erfordras med hänsyn till omkringliggande verksamhet.',
			bullet7:
				'S.k. Dust-Control <1>SKALL</1> finnas på arbetsytan vid damm- och rökalstrande arbetsmoment.',
			bullet8:
				'Åtgärder såsom avspärrningar ska vidtas för att skydda tredje man.',
			bullet9: 'Erforderlig skyddsutrustning ska användas.',
			bullet10:
				'Vid utlösning av skarpt brandlarm kommer kostnaden för utryckning av räddningstjänst + en administrativ kostnad om 5 000 kr för Unibail-Rodamco-Westfields hanteringen av insatsen. Kostnaden faktureras direkt till hyresgästen som orsakat utrymningen.',
			bullet11:
				'Vid ev. avvikelser på arbetsplats som upptäcks av Förvaltningen vid "Flygande Kontroll" har Förvaltningen rätt att avsluta pågående arbeten beroende på felart.',
		},

		infoBox2: {
			title: 'Att tänka på vid beskrivning av arbetsmoment',
			bullet1: 'Ventilationstekniska anpassningar.',
			bullet2:
				'Vibration/Buller; Hantering mot hyresgästers ev. interna larm, Arbeten sker efter att butiker är stängda.',
			bullet3:
				'Dokumentering; Egenkontroller och ev. avvikelserapporter.',
			bullet4: 'Samtlig personal ska bära ID06.',
			bullet5:
				'Sophantering. Var sker uppställning container, vems container nyttjas?',
		},

		describeWorkSteps: 'Beskriv arbetsmoment',
		describeWorkStepsComment:
			'Tänk på metod, material, utrustning, arbetsstyrka mm.',

		additionalResources: {
			title: 'Resurser utöver material',
			comment: 'Exempelvis maskiner, hjälpmedel och externa resurser.',
			scissorLift: 'Saxlift',
			skylift: 'Skylift',
			container: 'Kärl',
			typeApprovedLadder: 'Typgodkänd stege',
			otherAdditionalResource: 'Annat',
			attachLiftCardsForPersonalWorkingWithLifts:
				'Bifoga liftkort för samtlig personal som ska arbeta på lift',
			chooseLiftCards: 'Välj liftkort',
		},

		gdpr: 'Genom att fylla i och skicka in arbetsberedningen godkänner du att de personuppgifter som du tillhandahåller behandlas av fastighetsägaren och dess moderbolag för att administrera och hantera de arbeten som ingår i arbetsberedningen. Dina personuppgifter kommer att lagras till och med en månad efter avslutat arbete. Du har rätt att utöva dina rättigheter (t ex begära tillgång till eller rättelse av personuppgifterna) enligt gällande dataskyddslagstiftning genom att kontakta fastighetsägaren på dp.nordics@urw.com. Det åligger dig att tillse att ovanstående information kommer den fysiska person vars personuppgifter framgår av dokumentationen tillhanda.',

		warningText:
			'Vid utlöst brandlarm kommer kostnaden för utryckning av räddningstjänst + en administrativ kostnad om 5 000 kr för Unibail-Rodamco-Westfields hanteringen av insatsen. Kostnaden faktureras direkt till hyresgästen som orsakat utryckningen.<1 /><2 />Godkänd arbetsberedning ska kunna uppvisas på begäran av behörig personal på arbetsplats.<4 /><5 />Vid akuta händelser eller frågor gällande avstängning av brandlarm under arbetets gång om eller om brandlarmet utlösts inom arbetsområde, kontakta gruppledare för vakter omgående:<7 /><8 />Westfield Täby Centrum - 070 369 95 32<10 />Westfield Mall Of Scandinavia - 08-586 230 13<12 />Nacka Forum - 070 988 58 33<14 /><15 />Vid åtgärder på brandskyddet ska sms, efter arbetets slut, skickas till gruppledare för vakter.',

		submittingWorkPrep: 'Skickar in arbetsberedning...',
		workPrepSubmitted: 'Arbetsberedningen är inskickad!',
		pdfTooltip: 'Endast PDF-filer accepteras',
		onlyPDF: 'Se till att endast bifoga filer av formatet PDF',
		assentlyError:
			'Något gick fel med eSign-tjänsten. Se till att alla emails är korrekta och att alla PDF-filer är giltiga',
		uploadButton: 'Ladda upp PDF',
		pdfSizeLimit: 'Största tillåtna filstorlek är 15 Mb',
		continue: 'Fortsätt',
		back: 'Backa',
		sign: 'Signera',
		save: 'Spara ändringar',
		stepper: {
			creator: 'Upprättare',
			work: 'Arbete',
			requirements: 'Krav',
			sign: 'Signering',
		},
		submitted: 'Inskickad',
		notSubmitted: 'E-signeringen avbröts',
		submittedInfoText:
			'Arbetsberedningen är nu signerad av er, innan berört arbete får starta så behöver arbetet godkännas av centrumledningen. Beslut meddelas via email. För frågor gällande en arbetsberedning kontakta: \n \n Westfield Mall of Scandinavia: work.wmos@urw.se \n Westfield Täby Centrum: work.wtc@urw.se \n Nacka Centrum: work.nacka@urw.se',
		notSubmittedInfoText:
			'Signering av arbetsberedningen avbröts, en påminnelse skickas ut till den angivna emailadressen om en dag',
		toStart: 'Till startsida',
		urlError:
			'Något gick fel med eSign-tjänsten. Kontakta driften till relevant centrum',
		iHaveRead: 'Jag har läst ovan text',
		tryAgain: 'Tillbaka till signering',
		isLoggedInFileInfo:
			'Nedan finner du dina befintliga filer. Var god välj en fil från listan eller ladda upp en ny PDF.',
		noDocument: 'Inget dokument',
		fireAlarmAndSprinklerInfo: {
			title: 'Viktigt! Avstängt brandlarm och sprinklersystem kräver att ni meddelar sprinkleransvarig för centrumet.',
			nacka: 'Linda Gricjus, linda.gricjus@a-sprinkler.se',
			taby: 'Jakob, 0704252295, jakob@a-sprinkler.se',
			mos: 'Fredric Björklund, 070-425 22 80, fredric.bjorklund@a-sprinkler.se',
		},
		clientTitle: 'Beställare',
		clientName: 'Beställarens namn',
		clientTelephone: 'Beställarens telefonnummer',
		clientEmail: 'Beställarens Email',
		uploadingFiles: 'Laddar upp filer',
		workEnviromentResponsibility: {
			title: 'Jag bekräftar att jag, i egenskap av upprättare, är medveten om att',
			bullet1:
				'jag har arbetsmiljöansvar i egenskap av arbetsgivare för mina anställda,',
			bullet2:
				'beställaren (antingen butiken eller Unibail-Rodamco-Westfield) har arbetsmiljöansvar i egenskap av byggherre, och',
			bullet3:
				'Unibail-Rodamco-Westfield i egenskap av fastighetsägare endast har samordningsansvar av arbetsmiljöfrågor för centrumet enligt arbetsmiljölagen 3 kap. 7 d § och att detta inte fråntar byggherren, BAS-U, BAS-P eller arbetsgivare deras respektive arbetsmiljöansvar.',
		},
	},
	workPrepPDF: {
		hotWorkPermitManager: 'Heta arbeten (Tillståndsansvarig)',
		fireAlarmWillBeShutOffTimePeriod: 'Avstängt brandlarm (Tidsperiod)',
		switchedOffVentilation: 'Avstängd ventilation',
		switchedOffVentilationTimePeriod: 'Avstängd ventilation (Tidsperiod)',
		switchedOffSprinklerSystem: 'Avstängt sprinklersystem',
		switchedOffSprinklerSystemTimePeriod:
			'Avstängt sprinklersystem (Tidsperiod)',
		clientName: 'Beställarens namn',
		clientTelephone: 'Beställarens telefonnummer',
		clientEmail: 'Beställarens email',
		projectManagerExist: 'Hyresgästkoordinator/projektledare finns',
		projectManagerName: 'Namn till hyresgästkoordinator/projektledare',
		projectManagerPhoneNumber:
			'Telefonnummer till hyresgästkoordinator/projektledare',
		projectManagerEmailAddress:
			'Mailadress till hyresgästkoordinator/projektledare',
		urwBasUExist: 'BAS-U finns',
		urwBasUName: 'Namn till BAS-U',
		urwBasUPhoneNumber: 'Telefonnummer till BAS-U',
		urwBasUEmailAddress: 'Mailadress till BAS-U',
		basPExist: 'BAS-P finns',
		basPName: 'Namn till BAS-P',
		basPPhoneNumber: 'Telefonnummer till BAS-P',
		basPEmailAddress: 'Mailadress till BAS-P',
		insuranceExist: 'Försäkring finns',
		insuranceCompany: 'Försäkringsbolag',
		certainRisks: 'Särskilda risker',
		remediesForWorkEnvironmentRisks: 'Åtgärder för arbetsmiljörisker',
		additionalResources: 'Extra resurser',
		area: 'Område',
		descriptionOfWorkSteps: 'Beskrivning av arbetsmoment',
		errorOccured: 'En eller flera av PDF-filerna kunde inte sammanfogas',
	},
	adminPanel: {
		title: 'Adminpanel',
		workpreps: 'Arbetsberedningar',
		helperText:
			'Klicka på en rad för att se detaljer om en viss arbetsberedning.',
		all: 'Alla',
		archived: 'Arkiverade',
		notArchived: 'Oarkiverade',
		newWorkPrep: 'Ny arbetsberedning',
		search: 'Sök arbetsberedning',
		searchId: 'Sök ID',
		searchCompany: 'Sök företag',
		searchName: 'Sök upprättare',
		allMall: 'Alla',
		relevantMall: 'Relevant centrum',
		activeWorks: 'Aktiva arbeten',
	},
	fireAlarmPanel: {
		fireAlarms: 'Brandlarmsavstängningar & Heta arbeten',
		helperText: 'Klicka på en rad för att se detaljer om ett arbete.',
		type: 'Typ',
		newFireAlarm: 'Ansök om Brandlarmsavstängning & Heta Arbeten',
		search: 'Sök arbete',
	},
	serviceWorkPanel: {
		serviceWorks: 'Servicejobb',
		helperText: 'Klicka på en rad för att se detaljer om ett servicejobb.',
		newServiceWork: 'Nytt Servicejobb',
		search: 'Sök servicejobb',
	},
	fireAlarmDetails: {
		title: 'Brandlarmsavstängning & Heta arbeten',
		created: 'Skapad',
		updatingStatus: 'Uppdaterar status i databasen...',
		statusUpdated: 'Status uppdaterad!',
		fireAlarmOrHotWork: 'Brandlarmsavstängning eller Heta arbeten',
		fireAlarm: 'Brandlarmsavstängning',
		hotWork: 'Heta arbeten',
		workArea: 'Område/verksamhet/lokal som berörs av frånkopplingen',
		shutdownReason: 'Orsak till frånkoppling:',
		approve: 'Godkänn arbete',
		reject: 'Avslå arbete',
		remove: 'Ta bort arbete',
		openPDF: 'Öppna som PDF',
		relevantMall: 'Relevant centrum',
		startDate: 'Startdatum',
		startingTime: 'Starttid',
		endDate: 'Slutdatum',
		endingTime: 'Sluttid',
		createdByCompany: 'Upprättad av (företagsnamn)',
		createdByName: 'Upprättad av (namn)',
		phoneNumber: 'Telefonnummer till upprättare',
		email: 'Mailadress till upprättare',
		workPrepStore: 'Arbetsberedning: Lokalnummer/Butiksnamn',
		workPrepFloor: 'Arbetsberedning: Våningsplan',
		workPrepWorkArea: 'Arbetsberedning: Arbetsområde',
		drift: 'Driften',
		scrollMenu: {
			workPrep: 'Arbetsberedning',
			overview: 'Översikt',
			creator: 'Upprättare',
			signStatus: 'Signeringsstatus',
		},
		toWorkprep: 'Till arbetsberedning',
		edit: 'Gör ändringar i arbete',
		completionRequest: 'Begär komplettering av arbete',
	},
	serviceWorkDetails: {
		title: 'Servicejobb',
		created: 'Skapad',
		updatingStatus: 'Uppdaterar status i databasen...',
		statusUpdated: 'Status uppdaterad!',
		approve: 'Godkänn servicejobb',
		reject: 'Avslå servicejobb',
		remove: 'Ta bort servicejobb',
		openPDF: 'Öppna som PDF',
		relevantMall: 'Relevant centrum',
		startDate: 'Startdatum',
		startingTime: 'Starttid',
		endDate: 'Slutdatum',
		endingTime: 'Sluttid',
		createdByCompany: 'Upprättad av (företagsnamn)',
		createdByName: 'Upprättad av (namn)',
		phoneNumber: 'Telefonnummer till upprättare',
		email: 'Mailadress till upprättare',
		store: 'Lokalnummer/Butiksnamn',
		floor: 'Våningsplan',
		workArea: 'Arbetsområde',
		numPeople: 'Antal personer',
		workSteps: 'Arbetsmoment',
		scrollMenu: {
			overview: 'Översikt',
			creator: 'Upprättare',
			signStatus: 'Signeringsstatus',
		},
		makeChanges: 'Redigera Servicejobb',
		completeWorkPrepRequest: 'Begär komplettering',
	},
	fireAlarmPDF: {
		title: 'Brandlarmsavstängning & Heta arbeten',
	},
	workPrepDetails: {
		created: 'Skapad',
		updating: 'Uppdaterar status i databasen...',
		updated: 'Status uppdaterad!',
		newSignatureRequest: 'Begär nya signeringar manuellt',
		newSignatureRequested: 'Förfrågan skickades!',
		newSignatureRequestTooltip:
			'Använd endast vid manuell komplettering i adminpanelen',
		approveTooltip:
			'Du kan endast godkänna en arbetsberedning som signerats av alla inblandade',
		completeWorkPrepRequest: 'Begär komplettering av arbetsberedningen',
		completeWorkPrepRequestModal: {
			title: 'Kompletterings begäran',
			completionRequestMessageTitle: 'Meddelande',
			submitRequest: 'Skicka kompleteringgbegäran',
			closeModal: 'Stäng',
			sendingRequest: 'Skickar förfrågan...',
			requestSent: 'Kompletteringsbegäran skickad!',
		},
		commentSection: 'Kommentarer',
		commentWorkPrep: 'Kommentera',
		commentModal: {
			title: 'Kommentera arbetsberedning',
			messageHeaderTitle: 'Rubrik',
			messageTitle: 'Kommentar',
			submitRequest: 'Lägg till kommentar',
			addingComment: 'Lägger till kommentar...',
			closeModal: 'Stäng',
			requestSent: 'Kommentar tillagd!',
			commentCreator: 'Namn',
		},
		approve: 'Signera arbetsberedning som förvaltning',
		approveModal: {
			title: 'Godkänn arbete',
			messageInfo: 'Meddelande inkluderas i mejlutskicket',
			messageTitle: 'Meddelande',
			submitRequest: 'Godkänn arbete',
			closeModal: 'Stäng',
		},
		rejectModal: {
			title: 'Avslå arbete',
			messageInfo: 'Meddelande inkluderas i mejlutskicket',
			messageTitle: 'Meddelande',
			submitRequest: 'Avslå arbete',
			closeModal: 'Stäng',
		},
		deleteModal: {
			title: 'Ta bort arbete',
			helperText: 'Denna åtgärd kan INTE ångras',
			submitRequest: 'Ta bort arbete',
			closeModal: 'Stäng',
		},
		sendReminder: 'Skicka påminnelse för signering',
		reminderSent: 'Påminnelse skickad',
		noReminder: 'Ingen aktiv signering hittades',
		reminderSentError:
			'eSign-tjänsten gav ett fel, ingen påminnelse skickad',
		issuedBy: 'Utfärdats av',
		date: 'Datum',
		message: 'Meddelande',
		completionRequested: 'Begärd komplettering',
		lastEdited: 'Senaste komplettering',
		lastReminded: 'Senaste påminnelse',
		updates: 'Uppdateringar',
		Touch: 'Touch',
		Sms: 'Sms',
		ElectronicId: 'Mobil BankID',
		AdminPanel: 'Adminpanel',
		scrollMenu: {
			overview: 'Översikt',
			creator: 'Upprättare',
			requirements: 'Krav',
			safety: 'Säkerhet',
			signStatus: 'Signeringsstatus',
			comments: 'Kommentarer',
		},
		attachments: 'Övriga bilagor',
		newFireAlarm: 'Ansök om Brandlarmsavstängning & Heta arbeten',
		signProjectManager: 'Signera arbetsberedning som projektledare',
		signProjectManagerToolTip:
			'Upprättare måste singera innan projektledare kan signera',
	},
	editWorkPrep: {
		completeRequest: {
			sent: 'Komplettering inskickad',
		},
		workPrepUpdated: 'Arbetsberedningen har uppdaterats!',
		notAllRequiredFiles:
			'Se till att ladda upp nödvändiga filer, i PDF format',
		uploadedFiles: 'Uppladdade filer:',
		requestNewSignaturesTitle: 'Nya signeringar',
		requestNewSignatures: 'Begär nya signeringar i samband med ändring',
		warning: 'Var försiktigt, ändringar är ej reversibla!',
	},
	login: {
		userDoesNotExist: 'Användaren du angav är inte registrerad.',
		wrongPassword: 'Användaren finns, men lösenordet är fel.',
		wrongCredentials: 'Fel e-post eller lösenord.',
		somethingWentWrong: 'Något gick fel, försök igen.',
		login: 'Logga in',
		email: 'E-post',
		password: 'Lösenord',
		forgotPassword: 'Glömt lösenord?',
		forgotPasswordPage: {
			send: 'Skicka',
			sendingEmail: 'Skickar email...',
			emailSent: 'Ett mejl om lösenordsåterställning har skickats!',
		},
	},
	common: {
		lang: 'sv-SE',
		back: 'Tillbaka',
		cancel: 'Avbryt',
		notLoggedInMsg: 'Du måste <1>logga in</1> först!',
		erorOccured: 'Där uppstod ett fel!',
		yes: 'Ja',
		no: 'Nej',
		required: 'Obligatorisk',
		atLeastOne: 'Du måste välja minst ett alternativ',
		invalidEmailAddress: 'Ogiltig e-postadress',
		invalidDate: 'Datumet är felaktigt formaterat',
		invalidTime: 'Tiden är felaktigt formaterat',
		mustBeInteger: 'Måste vara ett heltal',
		passwordDoesNotMatch: 'Lösenorden matchar inte',
		seeErrorsAbove: 'Vänligen se över felmeddelanden ovan!',
		here: 'här',
		fileRequired: 'Filuppladdning krävs',
		electricalWorkCertificate: 'Intyg från elsäkerhetsverket',
		workWithWaterCertificate: 'Intyg från Säker Vatten',
		scheduleForTheProject: 'Tidplan för projektet',
		constructionEnvironmentWorkPlan: 'Arbetsmiljöplan',
		willTheWorkTakeLongerThan30DaysCertificate:
			'Förhandsanmälan om byggarbetsplatsen (Arbetsmiljöverket)',
		baseUCertificate: 'BAS-U Certifikat',
		basePCertificate: 'BAS-P Certifikat',
		attachedFiles: 'Bifogade filer',
		liftCardsForPersonalWorkingWithLifts:
			'Liftkort för personer som ska jobba med lift',
		waitPlease: 'Vänligen vänta...',
		beforeToday: 'Välj ett kommande datum',
		beforeStart: 'Välj ett datum efter startdatum',
		badRedirectUUID: 'Länken är inte längre giltig!',
		toStart: 'Till startsida',
	},
	adminMenu: {
		workPrep: 'Arbetsberedningar',
		awaitingApproval: 'Väntar på godkännande',
		all: 'Alla',
		fireShutdown: 'Brandlarmsavstängningar & Heta arbeten',
		serviceJobs: 'Servicejobb',
		makeAccount: 'Skapa användare',
		logOut: 'Logga ut',
		lang: 'English',
		account: 'Användare',
		accounts: 'Användare',
		handleAccount: 'Hantera användare',
		handleAccounts: 'Hantera användare',
		documentCollection: 'Dokumentsamling',
		editAccount: 'Användaruppgifter',
		active: 'Aktiva',
		finished: 'Avslutade',
	},
	workPrepStatusDisplay: {
		status: {
			approved: 'Godkänd',
			awaitingSigning: 'Väntar på signering',
			denied: 'Nekad',
			awaitingReview: 'Väntar på granskning',
		},
	},
	fileUrlSingle: {
		hide: 'Göm intyg',
		show: 'Visa intyg',
	},
	submitUserDocumentModal: {
		uploadDocument: 'Ladda upp dokument',
		descriptionText: 'Beskriv vad det innebär att ladda upp dokument',
		documentName: 'Namn på dokument',
		documentPerson: 'Namn på tillhörande person',
		documentType: 'Typ av dokument',
		selectDocument: 'Välj fil',
		electricalWorkCertificate: 'Elcertifikat',
		workWithWaterCertificate: 'Säker vatten',
		baseUCertrificate: 'Bas-U',
		basePCertrificate: 'Bas-P',
		liftCardsForPersonalWorkingWithLifts: 'Liftkort',
		openLegalText: 'villkor',
		agreeLegal: 'Jag har läst och godkänner dessa',
		legalTitle: 'Dokumenthantering',
		legalText:
			'På den här sidan ges du möjlighet att ladda upp och spara intyg, certifikat och annan liknande dokumentation för att styrka behörighet för utförande av arbeten i Unibail-Rodamco-Westfields anläggningar. Sådan dokumentation sparas i systemet i 12 månader och raderas automatiskt därefter om du inte väljer att förlänga lagringstiden. Du kan också när som helst radera uppladdade dokument. Syftet bakom möjligheten att lagra dokumenten är att underlätta vid återkommande arbeten och upprättandet av nya arbetsberedningar i denna portal. Dokumenten på den här sidan är synliga för de som är inloggade på användarkontot. Det åligger dig att tillse att ovanstående information kommer den fysiska person vars personuppgifter framgår av dokumentationen tillhanda.',
		cancel: 'Avbryt',
	},
	userDocuments: {
		documentCollection: 'Dokumentsamling',
		documentCollectionDescription:
			'På denna sida har du möjlighet att se uppladdade dokument, förnya dokument, ta bort dokument och ladda upp nya dokument.',
		uploadDocument: 'Ladda upp dokument',
		document: 'DOKUMENT',
		name: 'NAMN',
		type: 'TYP',
		expiryDate: 'UTGÅNGSDATUM',
		electricalWorkCertificate: 'ELCERTIFIKAT',
		workWithWaterCertificate: 'SÄKER VATTEN',
		basePCertificate: 'BAS-P',
		baseUCertificate: 'BAS-U',
		liftCardsForPersonalWorkingWithLifts: 'LIFTKORT',
		deleteUserDocumentModal: {
			confirmDeletion: 'Är du säker på att du vill radera dokumentet?',
			deletionExplainer:
				'Beskriv vad det innebär att radera ett dokument',
			delete: 'Radera',
			abort: 'Avbryt',
		},
		renewDocument: 'Förnya dokument',
		renewingDocument: 'Förnyar dokument...',
		documentRenewed: 'Dokument förnyat!',
		uploadingDocument: 'Laddar upp dokument...',
		documentUploaded: 'Dokument uppladdat!',
		deletingDocument: 'Tar bort dokument...',
		documentDeleted: 'Dokument borttaget',
	},
	userSettings: {
		handleAccount: 'Användaruppgifter',
		resetPassword: 'Återställ lösenord',
		name: 'Namn',
		email: 'E-post',
		role: 'Roll',
		password: 'Lösenord',
		save: 'Spara',
		cancel: 'Avbryt',
		edit: 'Redigera',
		stopEdit: 'Sluta redigera',
		delete: 'Ta bort användare',
		resetPasswordInfo:
			'Genom att klicka på knappen "Återställ lösenord" kommer ett mejl med återställningsinstruktioner skickas till e-posten kopplat till användaren.',
		deleteUser: {
			confirmDeletion: 'Är du säker på att du vill radera användaren?',
			helperText: 'Denna åtgärd kan INTE ångras',
			abort: 'Avbryt',
			delete: 'Radera',
		},
	},
	userPanel: {
		title: 'Hantera användare',
		helperText: 'Klicka på en rad för att se detaljer om en viss användare',
		name: 'Namn',
		email: 'E-post',
		role: 'Roll',
		editDelete: 'Redigera/Radera',
		newUser: 'Skapa ny användare',
		search: 'Sök användare',
		searchRole: 'Roll',
		noRole: 'Alla',
	},
	info: {
		newPortalTitle: 'Vi har en ny portal!',
		newPortal:
			'Om ni upplever problem, var god kontakta support@weknowit.se',
	},
	workHistory: {
		title: 'Arbetshistorik',
		helperText:
			'Nedan finns tabeller över tidigare arbeten utförda av upprättaren. Arbetena är grupperade utifrån arbetstyp och personuppgifter är borttagna.',
		answerYes: 'Ja',
		answerNo: 'Nej',
		workPrepTitle: 'Arbetsberedningar',
		fireAlarmTitle: 'Brandlarmsavstängningar & Heta arbeten',
		serviceWorkTitle: 'Servicejobb',
		creatorHistoryTitle: 'Upprättarens historik',
	},
}
