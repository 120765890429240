import { MenuItem } from '@mui/material'
import CustomSelectInput from './CustomSelectInput'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { GET_USER_DOCUMENTS } from '../../graphql/queries/userDocuments'
import { useQuery } from '@apollo/client'
import { useAuth } from '../../hooks/useAuth'

interface Props {
	translationLanguage: string
	formAttribute: string
	formValues: any
	multiple?: boolean
}

interface documentProps {
	documentFileName: string
	documentURLObj: string[]
}

const CustomSelectFile = ({
	translationLanguage,
	formAttribute,
	multiple = false,
}: Props) => {
	const { jwt } = useAuth()
	const { t } = useTranslation()
	const [userDocuments, setUserDocuments] = useState<documentProps[]>([])
	const { loading, error, data } = useQuery(GET_USER_DOCUMENTS, {
		variables: { jwt, type: formAttribute },
	})

	useEffect(() => {
		if (data) {
			const allUserDocumentsParsed = JSON.parse(data.getUserDocuments)

			setUserDocuments(allUserDocumentsParsed)
		}
	}, [data])

	const selectOptions = () => {
		const documentOptions = []
		if (!multiple) {
			documentOptions.push(
				<MenuItem value={''} key={'1'}>
					{t('workPrep.noDocument')}
				</MenuItem>
			)
		}

		userDocuments.map((document) => {
			documentOptions.push(
				<MenuItem
					value={document.documentURLObj[0]}
					key={document.documentFileName}
				>
					{document.documentFileName}
				</MenuItem>
			)
		})
		return documentOptions
	}

	return (
		<CustomSelectInput
			label={t(translationLanguage)}
			name={`selectedUserDocuments.${formAttribute}`}
			sx={{ width: '300px', marginTop: '20px' }}
			multiple={multiple}
		>
			{selectOptions()}
		</CustomSelectInput>
	)
}

export default CustomSelectFile
