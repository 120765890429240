import urw from '../../resources/urw.png'
import urwNoText from '../../resources/urwNoText.png'
import ChangeLanguageButton from './ChangeLanguageButton'
import BackButton from './BackButton'

interface Props {
	children: JSX.Element | JSX.Element[] | string
	backBtnPath: string
	noLogo?: boolean
	isCompleteRequest?: boolean
	disabled?: boolean
	cancel?: boolean
}

const PaperLooksContainer = ({
	children,
	isCompleteRequest = false,
	disabled = false,
	cancel,
	backBtnPath,
	noLogo,
}: Props) => {
	return (
		<div className="drop-shadow-lg pt-5 pb-2 px-7 my-[-1.5rem] mx-[-5.5%] md:mx-0 md:my-4 bg-white min-h-screen md:min-h-0">
			<div className="flex w-full justify-between mb-2">
				<BackButton
					backToPath={backBtnPath}
					disabled={disabled || isCompleteRequest}
					cancel={cancel}
				/>
				{!noLogo && (
					<>
						<img
							src={window.innerWidth < 600 ? urwNoText : urw}
							alt="Mall"
							className="h-[50px] mr-2"
						/>
						<ChangeLanguageButton />
					</>
				)}
			</div>

			{children}
		</div>
	)
}

export default PaperLooksContainer
