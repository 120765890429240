import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export function ScrollToError() {
	const formik = useFormikContext()
	const submitting = formik?.isSubmitting

	useEffect(() => {
		const element = document.querySelector('.Mui-error, [data-error]')
		;(element?.parentElement ?? element)?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		})
	}, [submitting])
	return null
}
