import { Box, Grid, Link, Typography } from '@mui/material'
import HomeLayout from '../components/common/HomeLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ServiceWorkLogin = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<HomeLayout backToPath="/">
			<Grid
				container
				width={'100%'}
				maxWidth={'500px'}
				direction="column"
				spacing={2}
			>
				<Grid item>
					<Typography component="h1" variant="h5" sx={{ mb: '10px' }}>
						{t('serviceWork.serviceWorkLogin.title')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography
						component="h1"
						variant="body2"
						sx={{ mb: '10px' }}
					>
						{t('serviceWork.serviceWorkLogin.info')}
					</Typography>
					<Link
						component={'button'}
						onClick={() => navigate('/sign-in')}
						variant="body2"
						sx={{ mb: '10px', fontWeight: 'bold' }}
						textAlign={'left'}
					>
						{t('serviceWork.serviceWorkLogin.loginInfo')}
					</Link>
					<Typography
						component="h1"
						variant="body2"
						sx={{ mb: '10px', whiteSpace: 'pre-line' }}
					>
						{t('serviceWork.serviceWorkLogin.emails')}
					</Typography>
					<Typography
						component="h1"
						variant="body2"
						sx={{ mb: '10px', fontWeight: 'bold' }}
					>
						{t('serviceWork.serviceWorkLogin.subject')}
					</Typography>
				</Grid>
			</Grid>
		</HomeLayout>
	)
}

export default ServiceWorkLogin
