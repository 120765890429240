import { Box, Grid, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import CustomDateInput from '../formik/CustomDateInput'
import CustomSelectInput from '../formik/CustomSelectInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'
import CustomTextInput from '../formik/CustomTextInput'
import CustomTimeInput from '../formik/CustomTimeInput'

const ServiceWorkInfo = () => {
	const { t } = useTranslation()

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.workSteps')}
					name="workSteps"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<CustomTextInput
					label={t('workPrep.workArea')}
					name="workArea"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomSelectInput
					label={t('workPrep.relevantMall')}
					name="relevantMall"
					sx={{ width: '100%' }}
				>
					<MenuItem value="Westfield Mall of Scandinavia">
						Westfield Mall of Scandinavia
					</MenuItem>
					<MenuItem value="Täby Centrum">
						Westfield Täby Centrum
					</MenuItem>
					<MenuItem value="Nacka Forum">Nacka Forum</MenuItem>
				</CustomSelectInput>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.numPeople')}
					name="numPeople"
					type="text"
					inputMode="numeric"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.floor')}
					name="floor"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.storeIdOrName')}
					name="storeIdOrName"
					type="text"
				/>
			</Grid>
			<CustomDivider />
			<Grid item xs={12} sm={4}>
				<CustomDateInput
					label={t('workPrep.startDate')}
					name="startDate"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomDateInput label={t('workPrep.endDate')} name="endDate" />
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.startingTime')}
					name="startingTime"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.endingTime')}
					name="endingTime"
				/>
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<CustomDivider />
		</Grid>
	)
}

export default ServiceWorkInfo
