import { Box, FormGroup, Grid, MenuItem, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import Bullet from '../common/Bullet'
import CustomDivider from '../common/CustomDivider'
import InfoText from '../common/InfoText'
import CustomTextInput from '../formik/CustomTextInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'
import CustomDateInput from '../formik/CustomDateInput'
import CustomTimeInput from '../formik/CustomTimeInput'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import CustomSelectInput from '../formik/CustomSelectInput'

const FireAlarmScope = () => {
	const formik = useFormikContext<any>()

	const { t } = useTranslation()

	const customHandleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.name === 'aroundTheClock' && event.target.checked) {
			formik.setFieldValue('weekdays', false)
			formik.setFieldValue('weekends', false)
			formik.setFieldValue('weekdayStartingTime', '')
			formik.setFieldValue('weekdayEndingTime', '')
			formik.setFieldValue('weekendStartingTime', '')
			formik.setFieldValue('weekendEndingTime', '')
		}
		if (
			(event.target.name === 'weekdays' ||
				event.target.name === 'weekends') &&
			event.target.checked
		) {
			formik.setFieldValue('aroundTheClock', false)
		}
		if (event.target.name === 'weekdays' && !event.target.checked) {
			formik.setFieldValue('weekdayStartingTime', '')
			formik.setFieldValue('weekdayEndingTime', '')
		}
		if (event.target.name === 'weekends' && !event.target.checked) {
			formik.setFieldValue('weekendStartingTime', '')
			formik.setFieldValue('weekendEndingTime', '')
		}
		formik.setFieldValue(event.target.name, event.target.checked)
	}

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12} sm={5}>
				<CustomSelectInput
					label={t('fireAlarmShutdown.form.fireAlarmOrHotWork')}
					name="fireAlarmOrHotWork"
					sx={{ width: '100%' }}
				>
					<MenuItem value="fireAlarm">
						{t('fireAlarmShutdown.form.fireAlarm')}
					</MenuItem>
					<MenuItem value="hotWork">
						{t('fireAlarmShutdown.form.hotWork')}
					</MenuItem>
				</CustomSelectInput>
			</Grid>
			<Grid item xs={12}>
				<CustomTextInput
					label={t('fireAlarmShutdown.form.workArea')}
					name="workArea"
					type="text"
				/>
			</Grid>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('fireAlarmShutdown.form.shutdownReason')}
					name="shutdownReason"
					type="text"
				/>
			</Grid>

			<Grid item xs={12} sm={4}>
				<CustomDateInput
					label={t('workPrep.startDate')}
					name="startDate"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomDateInput label={t('workPrep.endDate')} name="endDate" />
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<CustomDivider />
			<Grid item xs={12} sm={8}>
				<FormGroup>
					<Typography fontWeight={500} marginBottom={'10px'}>
						<Trans i18nKey="fireAlarmShutdown.form.times"></Trans>
					</Typography>
					{formik.errors.checkboxes &&
						(formik.touched.weekdays ||
							formik.touched.weekdays ||
							formik.touched.aroundTheClock) && (
							<Typography
								fontWeight={300}
								fontSize={14}
								marginBottom={'10px'}
								color={'error'}
							>
								{formik.errors.checkboxes}
							</Typography>
						)}
					<CustomCheckboxInput
						label={t('fireAlarmShutdown.form.weekdays')}
						name="weekdays"
						customHandleChange={customHandleCheckboxChange}
					/>
					{formik.values.weekdays && (
						<Grid container columnSpacing={4} marginTop={'10px'}>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.startingTime')}
									name="weekdayStartingTime"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.endingTime')}
									name="weekdayEndingTime"
								/>
							</Grid>
						</Grid>
					)}

					<CustomCheckboxInput
						label={t('fireAlarmShutdown.form.weekends')}
						name="weekends"
						customHandleChange={customHandleCheckboxChange}
					/>
					{formik.values.weekends && (
						<Grid container columnSpacing={4} marginTop={'10px'}>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.startingTime')}
									name="weekendStartingTime"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.endingTime')}
									name="weekendEndingTime"
								/>
							</Grid>
						</Grid>
					)}

					<CustomCheckboxInput
						label={t('fireAlarmShutdown.form.aroundTheClock')}
						name="aroundTheClock"
						customHandleChange={customHandleCheckboxChange}
					/>
					{formik.values.aroundTheClock && (
						<Typography
							fontWeight={300}
							fontSize={14}
							marginBottom={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.timesInfo"></Trans>
						</Typography>
					)}
				</FormGroup>
			</Grid>
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						my: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('fireAlarmShutdown.form.infoBox.title')}
					</Typography>
					<ul className="ml-4">
						<Bullet>
							<Typography fontWeight={300}>
								{t('fireAlarmShutdown.form.infoBox.bullet1')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('fireAlarmShutdown.form.infoBox.bullet3')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('fireAlarmShutdown.form.infoBox.bullet4')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('fireAlarmShutdown.form.infoBox.bullet5')}
							</Typography>
						</Bullet>
						<Bullet>
							<Typography fontWeight={300}>
								{t('fireAlarmShutdown.form.infoBox.bullet6')}
							</Typography>
						</Bullet>
					</ul>
				</InfoText>
			</Grid>
		</Grid>
	)
}

export default FireAlarmScope
