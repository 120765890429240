import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CustomMultiLineFieldInput from '../formik/CustomMultiLineFieldInput'

const style = {
	// eslint-disable-next-line
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 600,
	bgcolor: 'background.paper',
	border: '1px solid #555',
	borderRadius: 1,
	boxShadow: 24,
	p: 4,
}

const commonRow = {
	mt: 2,
	width: '100%',
}

const fullWidth = {
	width: '100%',
}

const commonRowTitle = {
	fontSize: 15,
	mt: 4,
	mb: 2,
}

type Props = {
	isOpen: boolean
	setIsOpen: (newIsOpenValue: boolean) => void
	submitStatus: (status: string, message: string) => void
	status: string
}

const ChangeStatusModal = ({
	isOpen,
	setIsOpen,
	submitStatus,
	status,
}: Props) => {
	const { t } = useTranslation()

	const handleClose = () => setIsOpen(false)

	const statusValidationSchema =
		status === 'Rejected'
			? Yup.object({
					statusMessage: Yup.string().required(t('common.required')),
			  })
			: Yup.object({})

	const initialFormikValues = {
		statusMessage: '',
	}

	const submitForm = async (values: any, { resetForm }: any) => {
		submitStatus(status, values.statusMessage)
		resetForm()
		handleClose()
	}

	const transKey =
		status === 'Approved'
			? 'workPrepDetails.approveModal.'
			: 'workPrepDetails.rejectModal.'
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<Box sx={style}>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
						>
							<Trans i18nKey={transKey + 'title'} />
						</Typography>
						<Formik
							initialValues={initialFormikValues}
							validationSchema={statusValidationSchema}
							onSubmit={submitForm}
						>
							{({ errors, values, handleSubmit }) => {
								return (
									<form
										onSubmit={(e) => {
											handleSubmit(e)
										}}
									>
										<Box sx={commonRow}>
											<Typography
												id="transition-modal-description"
												sx={commonRowTitle}
											>
												<Trans
													i18nKey={
														transKey + 'messageInfo'
													}
												/>
											</Typography>

											<CustomMultiLineFieldInput
												rows={5}
												label={t(
													transKey + 'messageTitle'
												)}
												name={'statusMessage'}
											/>
										</Box>
										<Box sx={commonRow}>
											<Button
												variant="contained"
												type="submit"
												fullWidth
												color={
													status == 'Approved'
														? 'success'
														: 'error'
												}
												sx={{ mt: '15px' }}
											>
												<Trans
													i18nKey={
														transKey +
														'submitRequest'
													}
												/>
											</Button>
											<Button
												variant="outlined"
												onClick={handleClose}
												fullWidth
												sx={{ mt: '15px' }}
											>
												<Trans
													i18nKey={
														transKey + 'closeModal'
													}
												/>
											</Button>
										</Box>
									</form>
								)
							}}
						</Formik>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default ChangeStatusModal
