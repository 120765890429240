import { GridColDef } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@apollo/client'
import {
	WORK_PREPS_BY_MALL,
	WORK_PREPS_BY_ACCOUNT,
} from '../../graphql/queries/workPreps'
import { WorkPrepAnswers } from '../../types'
import { useEffect, useState } from 'react'
import {
	Grid,
	Typography,
	Button,
	TextField,
	IconButton,
	MenuItem,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import CustomDataGrid from '../../components/admin/CustomDataGrid'
import ErrorOccurred from '../../components/common/ErrorOccurred'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import ClearIcon from '@mui/icons-material/Clear'
import { UPDATE_PREFERRED_MALL } from '../../graphql/mutations/users'
import { atom, useRecoilState } from 'recoil'
import { useAuth } from '../../hooks/useAuth'
import FilterCalender from '../../components/admin/FilterCalender'
import { dateComparator, normalizeDate } from '../../utils/helperFunctions'

export interface AdminPanelItem {
	id: string
	status: string
	establishedDate: string
	event: string
	relevantMall: string
	createdBy: string
	workArea: string
	floor: string
	storeIdOrName: string
	startDate: string
	endDate: string
	startingTime: string
	endingTime: string
	numPeople: string
	hotWork: string
	fireAlarmWillBeShutOff: string
	ventilationWillBeShutOff: string
	sprinklerSystemWillBeShutOff: string
	urwBaseU: string
	baseP: string
	insurance: string
}

const filterOptionState = atom({
	key: 'filterOptionState',
	default: 'active',
})

const AdminPanel = () => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const { role, jwt, userEmail, preferredMall, setPreferredMall } = useAuth()
	const [search, setSearch] = useState<string>('')
	const [filterMall, setFilterMall] = useState<string>(
		preferredMall ? preferredMall : ''
	)
	const [allWorkPreps, setAllWorkPreps] = useState<AdminPanelItem[]>([])
	const [updatePreferredMall] = useMutation(UPDATE_PREFERRED_MALL)
	const [filterOption, setFilterOption] = useRecoilState(filterOptionState)

	const [filterDate, setFilterDate] = useState<Date | null>(null)

	let mall = ''

	if (role === 'SuperAdmin' || role === 'Admin - Alla centrum') {
		mall = 'all'
	} else if (role!.split(' - ')[1]) {
		mall = role!.split(' - ')[1]
	}

	if (mall === 'MOS') {
		mall = 'Westfield Mall of Scandinavia'
	}

	const getOnlyOwnWorkPreps =
		role === 'Upprättare' || role === 'projectManager'

	const { loading, error, data } = getOnlyOwnWorkPreps
		? useQuery(WORK_PREPS_BY_ACCOUNT, {
				variables: { jwt },
				fetchPolicy: 'network-only',
		  })
		: useQuery(WORK_PREPS_BY_MALL, {
				variables: { mall, jwt },
				fetchPolicy: 'network-only',
		  })

	const newWorkPrep = () => {
		navigate('/work-preparation', { state: { user: userEmail } })
	}

	const handleUpdatedPreferredMall = (event: any) => {
		const mall = event.target.value
		setFilterMall(mall)
		updatePreferredMall({
			variables: {
				jwt,
				mall,
			},
		})
		setPreferredMall(mall)
	}

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 105,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'status',
			headerName: t('workPrep.status'),
			width: 125,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'establishedDate',
			headerName: t('workPrep.establishedDate'),
			width: 100,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'event',
			headerName: t('workPrep.event'),
			width: 150,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},

		{
			field: 'relevantMall',
			headerName: t('workPrep.relevantMall'),
			width: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'createdBy',
			headerName: t('workPrep.createdBy'),
			width: 220,
			editable: false,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'workArea',
			headerName: t('workPrep.workArea'),
			sortable: false,
			width: 140,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'floor',
			headerName: t('workPrep.floor'),
			sortable: false,
			width: 120,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'storeIdOrName',
			headerName: t('workPrep.storeIdOrName'),
			sortable: false,
			width: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startDate',
			headerName: t('workPrep.startDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'endDate',
			headerName: t('workPrep.endDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'startingTime',
			headerName: t('workPrep.startingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endingTime',
			headerName: t('workPrep.endingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'numPeople',
			headerName: t('workPrep.numPeople'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'hotWork',
			headerName: t('workPrep.hotWork'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'fireAlarmWillBeShutOff',
			headerName: t('workPrep.fireAlarmWillBeShutOff'),
			sortable: false,
			width: 175,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'ventilationWillBeShutOff',
			headerName: t('workPrep.ventilationWillBeShutOff'),
			sortable: false,
			width: 180,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'sprinklerSystemWillBeShutOff',
			headerName: t('workPrep.sprinklerSystemWillBeShutOff'),
			sortable: false,
			width: 220,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'urwBaseU',
			headerName: t('workPrep.urwBaseU'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'baseP',
			headerName: t('workPrep.baseP'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'insurance',
			headerName: t('workPrep.insurance'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
	]

	useEffect(() => {
		if (data) {
			const allWorkPrepsParsed = getOnlyOwnWorkPreps
				? JSON.parse(data.workPrepsByAccount)
				: JSON.parse(data.workPrepsByMall)

			let allWorkPrepsFiltered: WorkPrepAnswers[] =
				allWorkPrepsParsed.filter((workPrep: WorkPrepAnswers) => {
					return Object.keys(workPrep).length > 1
				})

			if (filterOption === 'waiting') {
				allWorkPrepsFiltered = allWorkPrepsFiltered.filter(
					(workPrep: WorkPrepAnswers) => {
						return workPrep.status === ''
					}
				)
			} else if (filterOption === 'finished') {
				allWorkPrepsFiltered = allWorkPrepsFiltered.filter(
					(workPrep: WorkPrepAnswers) => {
						const containsStatus = workPrep.status !== ''
						const currentDate = new Date()
						const expireDate = new Date(workPrep.endDate)
						if (currentDate > expireDate && containsStatus) {
							return true
						}
						return false
					}
				)
			} else {
				allWorkPrepsFiltered = allWorkPrepsFiltered.filter(
					(workPrep: WorkPrepAnswers) => {
						const containsStatus = workPrep.status !== ''
						const currentDate = new Date()
						const expireDate = new Date(workPrep.endDate)
						if (expireDate >= currentDate || !containsStatus) {
							return true
						}
						return false
					}
				)
			}

			allWorkPrepsFiltered = allWorkPrepsFiltered.filter(
				(workPrep: WorkPrepAnswers) => {
					if (
						workPrep.id.includes(search) ||
						workPrep.creatorOfWorkPrep.company
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						workPrep.creatorOfWorkPrep.name
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						workPrep.workArea
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						workPrep.storeIdOrName
							.toLowerCase()
							.includes(search.toLowerCase())
					) {
						return true
					}
					return false
				}
			)

			allWorkPrepsFiltered = allWorkPrepsFiltered.filter(
				(workPrep: WorkPrepAnswers) =>
					workPrep.relevantMall.includes(filterMall)
			)

			if (filterDate) {
				const filterDateNormalized = normalizeDate(filterDate)
				allWorkPrepsFiltered = allWorkPrepsFiltered.filter(
					(workPrep: WorkPrepAnswers) => {
						return (
							filterDateNormalized >=
								normalizeDate(workPrep.startDate) &&
							filterDateNormalized <=
								normalizeDate(workPrep.endDate)
						)
					}
				)
			}

			allWorkPrepsFiltered.sort((a, b) => {
				const aDate = new Date(a.establishedDate)
				const bDate = new Date(b.establishedDate)

				if (aDate < bDate) return 1
				else if (aDate > bDate) return -1
				else return 0
			})

			const adminPanelItems: AdminPanelItem[] = allWorkPrepsFiltered.map(
				(workPrep) => {
					const establishedDate = new Date(
						workPrep.establishedDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					const startDate = new Date(
						workPrep.startDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					const endDate = new Date(
						workPrep.endDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					return {
						id: workPrep.id || '',
						establishedDate,
						event: workPrep.workSteps,
						status: workPrep.status,
						relevantMall:
							workPrep.relevantMall == 'Täby Centrum'
								? 'Westfield Täby Centrum'
								: workPrep.relevantMall,
						createdBy: `${workPrep.creatorOfWorkPrep.company}, ${
							workPrep.creatorOfWorkPrep.name
						} (${
							(workPrep.creatorOfWorkPrep.email,
							workPrep.creatorOfWorkPrep.telephoneNumber)
						})`,
						workArea: workPrep.workArea,
						floor: workPrep.floor,
						storeIdOrName: workPrep.storeIdOrName,
						startDate,
						endDate,
						startingTime: workPrep.workingHours.startingTime,
						endingTime: workPrep.workingHours.endingTime,
						numPeople: workPrep.numPeople,
						hotWork: workPrep.hotWorkPermission.answer
							? t('common.yes')
							: t('common.no'),
						fireAlarmWillBeShutOff: workPrep.fireAlarmWillBeShutOff
							.answer
							? t('common.yes')
							: t('common.no'),
						ventilationWillBeShutOff: workPrep
							.ventilationWillBeShutOff.answer
							? t('common.yes')
							: t('common.no'),
						sprinklerSystemWillBeShutOff: workPrep
							.sprinklerSystemWillBeShutOff.answer
							? t('common.yes')
							: t('common.no'),
						urwBaseU: workPrep.constructionWorkWillTakePlace
							.urwBaseU.exists
							? `${workPrep.constructionWorkWillTakePlace.urwBaseU.name} (${workPrep.constructionWorkWillTakePlace.urwBaseU.email}, ${workPrep.constructionWorkWillTakePlace.urwBaseU.telephoneNumber})`
							: '',
						baseP: workPrep.constructionWorkWillTakePlace.baseP
							.exists
							? `${workPrep.constructionWorkWillTakePlace.baseP.name} (${workPrep.constructionWorkWillTakePlace.baseP.email}, ${workPrep.constructionWorkWillTakePlace.baseP.telephoneNumber})`
							: '',
						insurance: workPrep.insurance.exists
							? `${t('common.yes')}, ${
									workPrep.insurance.company
							  }`
							: t('common.no'),
					}
				}
			)

			setAllWorkPreps(adminPanelItems)
		}
	}, [
		loading,
		data,
		i18n.resolvedLanguage,
		filterOption,
		filterDate,
		search,
		filterMall,
	])

	if (loading) return <LoadingSpinner />

	if (error) return <ErrorOccurred />

	if (role === null) return <NotLoggedIn />

	return (
		<Grid
			container
			direction="column"
			spacing={2}
			paddingX={{ xs: 1, sm: 5 }}
		>
			<Grid item marginTop={'50px'}>
				<Typography variant="h5" fontWeight={'500'}>
					{t('adminPanel.workpreps')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>{t('adminPanel.helperText')}</Typography>
			</Grid>
			<Grid item>
				<Grid
					container
					justifyContent={'space-between'}
					columnGap={1}
					rowGap={1}
				>
					<Grid item>
						<Grid
							container
							direction={'row'}
							columnSpacing={1}
							rowSpacing={1}
							display={'flex'}
						>
							<Grid item>
								<TextField
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '250px',
										},
									}}
									id="search"
									label={t('adminPanel.search')}
									variant="outlined"
									value={search}
									onChange={(
										event: React.ChangeEvent<HTMLInputElement>
									) => {
										setSearch(event.target.value)
									}}
									InputProps={{
										endAdornment: (
											<IconButton
												sx={{
													visibility: search.length
														? 'visible'
														: 'hidden',
												}}
												onClick={() => setSearch('')}
											>
												<ClearIcon />
											</IconButton>
										),
									}}
								/>
							</Grid>
							{role === 'SuperAdmin' && (
								<Grid item>
									<TextField
										fullWidth
										sx={{
											minWidth: {
												xs: 'calc(100vw - 16px)',
												sm: '250px',
											},
										}}
										id="filterRole"
										label={t('adminPanel.relevantMall')}
										value={filterMall}
										select
										onChange={handleUpdatedPreferredMall}
									>
										<MenuItem value="">
											{t('adminPanel.allMall')}
										</MenuItem>
										<MenuItem value="Westfield Mall of Scandinavia">
											Westfield Mall of Scandinavia
										</MenuItem>
										<MenuItem value="Täby Centrum">
											Westfield Täby Centrum
										</MenuItem>
										<MenuItem value="Nacka Forum">
											Nacka Forum
										</MenuItem>
									</TextField>
								</Grid>
							)}
							<Grid item>
								<Grid
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '250px',
										},
									}}
								>
									<FilterCalender
										selectedDate={filterDate}
										setSelectedDate={setFilterDate}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						{role === 'Upprättare' ? (
							<Button
								sx={{
									height: '50px',
									minWidth: {
										xs: 'calc(100vw - 16px)',
										sm: '240px',
									},
								}}
								variant="contained"
								startIcon={<Add />}
								onClick={newWorkPrep}
							>
								{t('adminPanel.newWorkPrep')}
							</Button>
						) : null}
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Box height={750}>
					<CustomDataGrid
						type={'admin-panel'}
						data={allWorkPreps}
						columns={columns}
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export default AdminPanel
