import { Form, Formik } from 'formik'
import {
	Alert,
	Button,
	Snackbar,
	Stepper,
	Step,
	StepLabel,
	CircularProgress,
	Grid,
} from '@mui/material'
import * as Yup from 'yup'
import { useState } from 'react'
import { LocationState, ServiceWorkAnswers, SnackbarData } from '../types'
import { useMutation } from '@apollo/client'
import { SUBMIT_SERVICE_WORK } from '../graphql/mutations/workPreps'
import { useTranslation } from 'react-i18next'
import { pdf } from '@react-pdf/renderer'
import CreatorForm from '../components/forms/CreatorForm'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import SignForm from '../components/forms/SignForm'
import SignStatus from '../components/forms/signStatus'
import { useLocation, useParams } from 'react-router-dom'
import { ScrollToError } from '../components/formik/ScrollToError'
import ServiceWorkPDF from '../components/admin/pdf/ServiceWorkPDF'
import ServiceWorkInfo from '../components/forms/ServiceWorkInfo'
import NotLoggedIn from '../components/common/NotLoggedIn'
import PaperLooksContainer from '../components/common/PaperLooksContainer'

const ServiceWorkForm = () => {
	const { t } = useTranslation()

	const params = useParams()
	const location = useLocation()
	const state = location.state as LocationState

	const [submitServiceWork] = useMutation(SUBMIT_SERVICE_WORK)

	const [stepperStep, setStepperStep] = useState<number>(
		params.signStatus ? 3 : 0
	)

	const isCreatedLoggedIn = state && state.user ? true : false

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const isFirstStep = stepperStep === 0
	const isLastStep = stepperStep === 2

	function _renderStepContent(step: number) {
		switch (step) {
			case 0:
				return <CreatorForm />
			case 1:
				return <ServiceWorkInfo />
			case 2:
				return <SignForm />
			case 3:
				return (
					<SignStatus
						signStatus={params.signStatus}
						setSnackbarData={setSnackbarData}
					/>
				)
			default:
				return <div>Not Found</div>
		}
	}

	const handleSubmit = async (values: any, actions: any) => {
		if (isLastStep) {
			await serviceWorkSubmit(values, actions.resetForm)
		} else {
			setStepperStep(stepperStep + 1)
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
			actions.setTouched({})
		}
		actions.setSubmitting(false)
	}

	const backButtonClicked = () => {
		setStepperStep(stepperStep - 1)
	}

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const today = new Date()
	today.setHours(0, 0, 0, 0)

	const workPrepValidationSchema = [
		Yup.object({
			createdByCompany: Yup.string().required(t('common.required')),
			createdByPersonName: Yup.string().required(t('common.required')),
			telephoneNumberToCreator: Yup.string().required(
				t('common.required')
			),
			emailToCreator: Yup.string()
				.email(t('common.invalidEmailAddress'))
				.required(t('common.required')),
			iHaveRead: Yup.bool().oneOf([true], t('common.required')),
		}),
		Yup.object({
			workSteps: Yup.string().required(t('common.required')),
			relevantMall: Yup.string().required(t('common.required')),

			workArea: Yup.string().required(t('common.required')),
			floor: Yup.string().required(t('common.required')),
			storeIdOrName: Yup.string().required(t('common.required')),
			startDate: Yup.date()
				.typeError(t('common.invalidDate'))
				.required(t('common.required'))
				.min(today, t('common.beforeToday')),
			endDate: Yup.date()
				.typeError(t('common.invalidDate'))
				.required(t('common.required'))
				.min(Yup.ref('startDate'), t('common.beforeStart')),
			startingTime: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
			endingTime: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
			numPeople: Yup.number()
				.min(1, t('workPrep.validation.atLeastOnePerson'))
				.integer(t('common.mustBeInteger'))
				.typeError(t('common.mustBeInteger'))
				.required(t('common.required')),
		}),
	]

	const currentValidationSchema = workPrepValidationSchema[stepperStep]

	const initialFormikValues = {
		workSteps: '',
		relevantMall: '',
		createdByCompany: '',
		createdByPersonName: '',
		telephoneNumberToCreator: '',
		emailToCreator: '',
		workArea: '',
		floor: '',
		numPeople: '',
		storeIdOrName: '',
		startDate: '',
		endDate: '',
		startingTime: '', // TODO: Parse:a detta rätt vid submit
		endingTime: '',

		iHaveRead: false,
	}

	const serviceWorkSubmit = async (values: any, resetForm: any) => {
		let startingTimeHours = `${values.startingTime.getHours()}`,
			startingTimeMinutes = `${values.startingTime.getMinutes()}`,
			endingTimeHours = `${values.endingTime.getHours()}`,
			endingTimeMinutes = `${values.endingTime.getMinutes()}`

		if (parseInt(startingTimeHours) < 10) {
			startingTimeHours = `0${startingTimeHours}`
		}

		if (parseInt(startingTimeMinutes) < 10) {
			startingTimeMinutes = `0${startingTimeMinutes}`
		}

		if (parseInt(endingTimeHours) < 10) {
			endingTimeHours = `0${endingTimeHours}`
		}

		if (parseInt(endingTimeMinutes) < 10) {
			endingTimeMinutes = `0${endingTimeMinutes}`
		}

		const serviceWorkAnswers: ServiceWorkAnswers = {
			id: '',
			status: '',
			statusChangedBy: '',
			statusChangedOn: '',
			workSteps: values.workSteps,
			relevantMall: values.relevantMall,
			creatorOfWorkPrep: {
				company: values.createdByCompany,
				name: values.createdByPersonName,
				telephoneNumber: values.telephoneNumberToCreator,
				email: values.emailToCreator,
				signStatus: '',
				signStatusChangedOn: '',
				signatureType: '',
				caseId: '',
			},
			establishedDate: new Date(),
			workArea: values.workArea,
			floor: values.floor,
			storeIdOrName: values.storeIdOrName,
			startDate: values.startDate!,
			endDate: values.endDate!,
			workingHours: {
				startingTime: `${startingTimeHours}:${startingTimeMinutes}`,
				endingTime: `${endingTimeHours}:${endingTimeMinutes}`,
			},
			numPeople: values.numPeople.toString(),
			isCreatedLoggedIn: {
				answer: isCreatedLoggedIn,
				email: isCreatedLoggedIn ? state.user! : '',
			},
		}

		setSnackbarData({
			open: true,
			severity: 'info',
			message: t('serviceWork.submittingServiceWork'),
		})

		const serviceWorkBlob = await pdf(
			<ServiceWorkPDF
				currentServiceWork={serviceWorkAnswers}
				customLang="sv"
			/>
		).toBlob()

		const serviceWorkPDF = new File([serviceWorkBlob], 'filename.pdf')

		setSnackbarData({
			open: true,
			severity: 'info',
			message: t('serviceWork.uploadingData'),
		})

		const res = await submitServiceWork({
			variables: {
				serviceWorkAnswers: JSON.stringify(serviceWorkAnswers),
				serviceWorkPDF,
			},
		})

		if (res.data.submitServiceWork === 'Assently error') {
			setSnackbarData({
				message: t('workPrep.assentlyError'),
				severity: 'error',
				open: true,
			})
		} else {
			setSnackbarData({
				message: t('serviceWork.serviceWorkSubmitted'),
				severity: 'success',
				open: true,
			})
			resetForm()
			try {
				const redirectUrl = new URL(res.data.submitServiceWork)
				window.location.replace(redirectUrl)
			} catch {
				setSnackbarData({
					message: t('workPrep.urlError'),
					severity: 'error',
					open: true,
				})
			}
		}
	}

	const steps = [
		t('workPrep.stepper.creator'),
		t('workPrep.stepper.work'),
		t('workPrep.stepper.sign'),
	]

	//Used to indicate on the stepper that the signature is not completed
	const stepperShouldNotDisplayDone =
		params.signStatus != null && params.signStatus != 'completed'

	if (!params.signStatus && !isCreatedLoggedIn) {
		return <NotLoggedIn />
	}

	return (
		<>
			<PaperLooksContainer backBtnPath="/" disabled={!isFirstStep} cancel>
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
				>
					<Grid item>
						<h1>
							{t('serviceWork.title') +
								': ' +
								(state?.user ? state.user : '')}
						</h1>

						<p className="italic mb-9">
							{t('serviceWork.introText')}
						</p>
					</Grid>

					<Grid item marginBottom={'50px'} marginTop={'30px'}>
						<Stepper
							activeStep={
								stepperShouldNotDisplayDone ? 2 : stepperStep
							}
							alternativeLabel
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
					</Grid>

					<Formik
						initialValues={initialFormikValues}
						onSubmit={handleSubmit}
						validationSchema={currentValidationSchema}
					>
						{({ isSubmitting }) => (
							<Form id={'1'}>
								<ScrollToError />
								{_renderStepContent(stepperStep)}
								{stepperStep < 3 && (
									<Grid
										container
										justifyContent="space-between"
										alignItems="center"
										marginTop={'20px'}
									>
										<Grid item>
											<Button
												variant="outlined"
												onClick={backButtonClicked}
												disabled={stepperStep === 0}
												startIcon={<ArrowBack />}
												sx={{ width: 150, height: 50 }}
											>
												{t('workPrep.back')}
											</Button>
										</Grid>

										<Grid item>
											<Button
												disabled={isSubmitting}
												type="submit"
												variant="contained"
												endIcon={
													isSubmitting ? (
														<CircularProgress
															size={20}
														/>
													) : (
														<ArrowForward />
													)
												}
												sx={{ width: 150, height: 50 }}
											>
												{isLastStep
													? t('workPrep.sign')
													: t('workPrep.continue')}
											</Button>
										</Grid>
									</Grid>
								)}
							</Form>
						)}
					</Formik>
				</Grid>
			</PaperLooksContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{/* Show loading animation on text if form is being uploaded */}
					{snackbarData.message === t('serviceWork.uploadingData') ? (
						<span className="loading-dots">
							{snackbarData.message}
						</span>
					) : (
						snackbarData.message
					)}
				</Alert>
			</Snackbar>
		</>
	)
}

export default ServiceWorkForm
