import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import InfoText from '../common/InfoText'
import Bullet from '../common/Bullet'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'

const SignForm = () => {
	const { t } = useTranslation()

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#ef4444',
						backgroundColor: '#fef2f2',
						mb: '10px',
					}}
				>
					{t('workPrep.gdpr')}
				</InfoText>
			</Grid>
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#ef4444',
						backgroundColor: '#fef2f2',
					}}
				>
					<Trans i18nKey="workPrep.warningText">
						In the event of a triggered fire alarm, the cost of
						calling out the emergency services + an administrative
						fee cost of SEK 5,000 for Unibail-Rodamco-Westfield´s
						handling of the effort. The cost is invoiced directly to
						the tenant who caused the call.
						<br />
						<br />
						Approved work preparation must be able to be shown at
						the request of authorized personnel at the workplace.
						<br />
						<br />
						In the event of urgent events or questions regarding
						switching off the fire alarm during the course of the
						work or if the fire alarm is triggered in the work area,
						contact the team leader for guards immediately:
						<br />
						<br />
						Westfield Täby Centrum - 070 369 95 32
						<br />
						Westfield Mall Of Scandinavia - 08-586 230 13
						<br />
						Nacka Forum - 070 988 58 33
						<br />
						<br />
						In the case of fire protection measures, text messages
						must be sent to group leaders for guards after the end
						of the work.
					</Trans>
				</InfoText>
			</Grid>

			<Grid item>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						mt: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('workPrep.workEnviromentResponsibility.title')}
					</Typography>

					<Typography fontWeight={300}>
						1. {'    '}
						{t('workPrep.workEnviromentResponsibility.bullet1')}
					</Typography>

					<Typography fontWeight={300}>
						2.{'    '}
						{t('workPrep.workEnviromentResponsibility.bullet2')}
					</Typography>

					<Typography fontWeight={300}>
						3.{'    '}
						{t('workPrep.workEnviromentResponsibility.bullet3')}
					</Typography>
				</InfoText>
			</Grid>
			<Grid item>
				<CustomCheckboxInput
					label={t('workPrep.iHaveRead')}
					name="iHaveReadConfirm"
				/>
			</Grid>
			<CustomDivider />
		</Grid>
	)
}

export default SignForm
