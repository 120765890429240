import { ArrowBack } from '@mui/icons-material'
import { Button, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
	backToPath: string
	sx?: SxProps
	variant?: 'text' | 'outlined' | 'contained'
	disabled?: boolean
	cancel?: boolean
}

const BackButton = ({ backToPath, sx, variant, disabled, cancel }: Props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<Button
			startIcon={<ArrowBack />}
			onClick={() => navigate(backToPath)}
			sx={sx}
			variant={variant}
			disabled={disabled}
		>
			{cancel ? t('common.cancel') : t('common.back')}
		</Button>
	)
}

export default BackButton
