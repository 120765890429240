import { Grid, Typography } from '@mui/material'
import { FireAlarmAnswers } from '../../types'
import { useTranslation } from 'react-i18next'
import { PrettyDateWithTime } from '../common/PrettyDate'

interface Props {
	fireAlarm: FireAlarmAnswers
	className?: string
}

const headerText = {
	fontWeight: 'medium',
	fontSize: 14,
}
const infoText = {
	fontSize: 14,
	color: 'gray',
}

const SignStatusListFireAlarm = ({ fireAlarm, className = '' }: Props) => {
	const { t } = useTranslation()

	return (
		<Grid container paddingLeft={'20px'} marginY={2} columnSpacing={10}>
			<Grid item width={'200px'} marginBottom={'50px'}>
				<Grid container direction={'column'} rowSpacing={1}>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.createdBy')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.createdByPersonName}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.status')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.signStatus || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.date')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.signStatusChangedOn ? (
								<PrettyDateWithTime
									dateString={fireAlarm.signStatusChangedOn}
								/>
							) : (
								'-'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.signatureType')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.signatureType
								? t(
										'workPrepDetails.' +
											fireAlarm.signatureType
								  )
								: '-'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item width={'200px'} marginBottom={'50px'}>
				<Grid container direction={'column'} rowSpacing={1}>
					<Grid item>
						<Typography sx={headerText}>
							{t('fireAlarmDetails.drift')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.statusChangedBy || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.status')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.status || '-'}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.date')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.statusChangedOn ? (
								<PrettyDateWithTime
									dateString={fireAlarm.statusChangedOn}
								/>
							) : (
								'-'
							)}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={headerText}>
							{t('workPrep.signatureType')}
						</Typography>
						<Typography sx={infoText}>
							{fireAlarm.status
								? t('workPrepDetails.AdminPanel')
								: '-'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SignStatusListFireAlarm
