interface Props {
	title: string
	value: string | JSX.Element
	className?: string
}

const FieldDisplay = ({ title, value, className = '' }: Props) => {
	return (
		<div className={'flex flex-col flex-[1_1_200px] ' + className}>
			<label>{title}</label>
			<div className="font-light">{value}</div>
		</div>
	)
}

export default FieldDisplay
