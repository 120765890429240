import { Button, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'

interface Props {
	sx?: SxProps
	variant?: 'text' | 'outlined' | 'contained'
	icon?: boolean
}

const ChangeLanguageButton = ({ sx, variant, icon }: Props) => {
	const { i18n } = useTranslation()

	const availableLanguages = {
		sv: { nativeName: 'Svenska' },
		en: { nativeName: 'English' },
	}

	const getOtherLanguage = () => {
		const resolvedLanguage = i18n.resolvedLanguage as 'sv' | 'en'

		return resolvedLanguage === 'sv' ? 'en' : 'sv'
	}

	const [otherLanguage, setOtherLanguage] = useState<'sv' | 'en'>('en')

	useEffect(() => {
		const newOtherLanguage = getOtherLanguage()
		setOtherLanguage(newOtherLanguage)
	}, [i18n.resolvedLanguage])

	return (
		<Button
			type="submit"
			onClick={() => i18n.changeLanguage(otherLanguage)}
			sx={sx}
			variant={variant}
			startIcon={icon ? <LanguageIcon /> : null}
		>
			{availableLanguages[otherLanguage].nativeName}
		</Button>
	)
}

export default ChangeLanguageButton
