import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthProvider'

export const useAuth = () => {
	const authContext = useContext(AuthContext)
	if (!authContext) {
		throw new Error('Auth could not be reached')
	}
	return authContext
}
