import {
	Box,
	Button,
	FormGroup,
	Grid,
	IconButton,
	MenuItem,
	Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import CustomTextInput from '../formik/CustomTextInput'
import CustomDateInput from '../formik/CustomDateInput'
import CustomTimeInput from '../formik/CustomTimeInput'
import CustomSelectInput from '../formik/CustomSelectInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

const EditFireAlarmForm = () => {
	const formik = useFormikContext<any>()

	const [numberOfContactPersons, setNumberOfContactPersons] =
		useState<number>(formik.values.numberOfContactPersons)
	formik.values.numberOfContactPersons = numberOfContactPersons

	const customHandleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.name === 'aroundTheClock' && event.target.checked) {
			formik.setFieldValue('weekdays', false)
			formik.setFieldValue('weekends', false)
			formik.setFieldValue('weekdayStartingTime', '')
			formik.setFieldValue('weekdayEndingTime', '')
			formik.setFieldValue('weekendStartingTime', '')
			formik.setFieldValue('weekendEndingTime', '')
		}
		if (
			(event.target.name === 'weekdays' ||
				event.target.name === 'weekends') &&
			event.target.checked
		) {
			formik.setFieldValue('aroundTheClock', false)
		}
		if (event.target.name === 'weekdays' && !event.target.checked) {
			formik.setFieldValue('weekdayStartingTime', '')
			formik.setFieldValue('weekdayEndingTime', '')
		}
		if (event.target.name === 'weekends' && !event.target.checked) {
			formik.setFieldValue('weekendStartingTime', '')
			formik.setFieldValue('weekendEndingTime', '')
		}
		formik.setFieldValue(event.target.name, event.target.checked)
	}

	const { t } = useTranslation()

	console.log(formik.errors)

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12} sm={5}>
				<CustomSelectInput
					label={t('fireAlarmShutdown.form.fireAlarmOrHotWork')}
					name="fireAlarmOrHotWork"
					sx={{ width: '100%' }}
				>
					<MenuItem value="fireAlarm">
						{t('fireAlarmShutdown.form.fireAlarm')}
					</MenuItem>
					<MenuItem value="hotWork">
						{t('fireAlarmShutdown.form.hotWork')}
					</MenuItem>
				</CustomSelectInput>
			</Grid>
			<Grid item xs={12}>
				<CustomTextInput
					label={t('fireAlarmShutdown.form.workArea')}
					name="workArea"
					type="text"
				/>
			</Grid>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('fireAlarmShutdown.form.shutdownReason')}
					name="shutdownReason"
					type="text"
				/>
			</Grid>

			<Grid item xs={12} sm={4}>
				<CustomDateInput
					label={t('workPrep.startDate')}
					name="startDate"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomDateInput label={t('workPrep.endDate')} name="endDate" />
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<CustomDivider />
			<Grid item xs={12} sm={8}>
				<FormGroup>
					<Typography fontWeight={500} marginBottom={'10px'}>
						<Trans i18nKey="fireAlarmShutdown.form.times"></Trans>
					</Typography>
					{formik.errors.checkboxes &&
						(formik.touched.weekdays ||
							formik.touched.weekdays ||
							formik.touched.aroundTheClock) && (
							<Typography
								fontWeight={300}
								fontSize={14}
								marginBottom={'10px'}
								color={'error'}
							>
								{formik.errors.checkboxes}
							</Typography>
						)}
					<CustomCheckboxInput
						label={t('fireAlarmShutdown.form.weekdays')}
						name="weekdays"
						customHandleChange={customHandleCheckboxChange}
					/>
					{formik.values.weekdays && (
						<Grid container columnSpacing={4} marginTop={'10px'}>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.startingTime')}
									name="weekdayStartingTime"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.endingTime')}
									name="weekdayEndingTime"
								/>
							</Grid>
						</Grid>
					)}

					<CustomCheckboxInput
						label={t('fireAlarmShutdown.form.weekends')}
						name="weekends"
						customHandleChange={customHandleCheckboxChange}
					/>
					{formik.values.weekends && (
						<Grid container columnSpacing={4} marginTop={'10px'}>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.startingTime')}
									name="weekendStartingTime"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<CustomTimeInput
									label={t('workPrep.endingTime')}
									name="weekendEndingTime"
								/>
							</Grid>
						</Grid>
					)}

					<CustomCheckboxInput
						label={t('fireAlarmShutdown.form.aroundTheClock')}
						name="aroundTheClock"
						customHandleChange={customHandleCheckboxChange}
					/>
					{formik.values.aroundTheClock && (
						<Typography
							fontWeight={300}
							fontSize={14}
							marginBottom={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.timesInfo"></Trans>
						</Typography>
					)}
				</FormGroup>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<Typography fontWeight={500} marginBottom={'10px'}>
					<Trans i18nKey="fireAlarmShutdown.form.creator"></Trans>
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByCompany')}
					name="createdByCompany"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByPersonName')}
					name="createdByPersonName"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.telephoneNumberToCreator')}
					name="telephoneNumberToCreator"
					type="text"
					inputMode="tel"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.emailToCreator')}
					name="emailToCreator"
					type="text"
					inputMode="email"
				/>
			</Grid>
			{numberOfContactPersons > 0 && (
				<>
					<Grid item xs={12} display={'flex'}>
						<Typography
							fontWeight={500}
							marginBottom={'10px'}
							marginTop={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.contactPersonOnSite"></Trans>
						</Typography>
						<IconButton
							onClick={() =>
								setNumberOfContactPersons(
									numberOfContactPersons - 1
								)
							}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.companyApplicant')}
							name="companyContactPerson1"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.nameApplicant')}
							name="nameContactPerson1"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t(
								'fireAlarmShutdown.form.phonenumberApplicant'
							)}
							name="numberContactPerson1"
							type="text"
							inputMode="tel"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.emailApplicant')}
							name="emailContactPerson1"
							type="text"
							inputMode="email"
						/>
					</Grid>
				</>
			)}
			{numberOfContactPersons > 1 && (
				<>
					<Grid item xs={12} display={'flex'}>
						<Typography
							fontWeight={500}
							marginBottom={'10px'}
							marginTop={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.contactPersonOnSite"></Trans>
						</Typography>
						<IconButton
							onClick={() =>
								setNumberOfContactPersons(
									numberOfContactPersons - 1
								)
							}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.companyApplicant')}
							name="companyContactPerson2"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.nameApplicant')}
							name="nameContactPerson2"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t(
								'fireAlarmShutdown.form.phonenumberApplicant'
							)}
							name="numberContactPerson2"
							type="text"
							inputMode="tel"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.emailApplicant')}
							name="emailContactPerson2"
							type="text"
							inputMode="email"
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12} maxWidth={{ xs: '100%', sm: '400px' }}>
				<Button
					fullWidth
					sx={{ height: '50px' }}
					disabled={numberOfContactPersons == 2}
					onClick={() =>
						setNumberOfContactPersons(numberOfContactPersons + 1)
					}
					variant="contained"
				>
					{t('fireAlarmShutdown.form.addContactPerson')}
				</Button>
			</Grid>
			<CustomDivider />
		</Grid>
	)
}

export default EditFireAlarmForm
