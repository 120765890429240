import { Box, Grid } from '@mui/material'
import findIdImg from '../resources/findId.png'
import HomeLayout from '../components/common/HomeLayout'

const FireAlarmFindId = () => {
	return (
		<HomeLayout backToPath="/fire-alarm-id">
			<Grid
				container
				width={'100%'}
				maxWidth={'500px'}
				direction="column"
				spacing={2}
			>
				<Grid
					container
					direction="row"
					justifyContent="end"
					alignItems="center"
				>
					<Box
						paddingX={5}
						paddingY={5}
						sx={{ objectFit: 'contain' }}
						height={'70vh'}
						component="img"
						alt="Westfield"
						src={findIdImg}
					/>
				</Grid>
			</Grid>
		</HomeLayout>
	)
}

export default FireAlarmFindId
