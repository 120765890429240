import { useTranslation } from 'react-i18next'

const ErrorOccurred = () => {
	const { t } = useTranslation()
	return (
		<div className="w-full  h-full flex justify-center pt-32">
			<p>{t('common.erorOccured')}</p>
		</div>
	)
}

export default ErrorOccurred
