import HomeLayout from '../../components/common/HomeLayout'
import Button from '@mui/material/Button'

import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, CircularProgress, Grid, Snackbar } from '@mui/material'
import { useAuth } from '../../hooks/useAuth'
import { SnackbarData } from '../../types'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import CustomTextInput from '../../components/formik/CustomTextInput'

interface FormValues {
	email: string
}

const ForgotPass = () => {
	const { t, i18n } = useTranslation()
	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const lang = i18n.resolvedLanguage as 'sv' | 'en'

	const { forgotPassword } = useAuth()

	const initFormValues = {
		email: '',
	}

	const formValidation = Yup.object({
		email: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.required(t('common.required')),
	})

	const handleSubmit = async (values: FormValues) => {
		setSnackbarData({
			message: t('login.forgotPasswordPage.sendingEmail'),
			severity: 'info',
			open: true,
		})
		await forgotPassword({ email: values.email })
		setSnackbarData({
			message: t('login.forgotPasswordPage.emailSent'),
			severity: 'success',
			open: true,
		})
	}

	return (
		<HomeLayout>
			<Grid
				container
				width={'100%'}
				maxWidth={'500px'}
				direction={'column'}
				spacing={2}
			>
				<Grid item>
					<Typography component="h1" variant="h5">
						{
							{ sv: 'Glömt lösenord?', en: 'Forgot password?' }[
								lang
							]
						}
					</Typography>
				</Grid>
				<Grid item>
					<Formik
						initialValues={initFormValues}
						validationSchema={formValidation}
						onSubmit={async (values) => {
							await handleSubmit(values)
						}}
					>
						{({ isSubmitting }) => (
							<Form>
								<CustomTextInput
									label={t('login.email')}
									name="email"
								/>

								<Button
									disabled={isSubmitting}
									type="submit"
									variant="contained"
									sx={{ width: '100%', height: '50px' }}
									endIcon={
										isSubmitting && (
											<CircularProgress size={10} />
										)
									}
								>
									{t('login.forgotPasswordPage.send')}
								</Button>
							</Form>
						)}
					</Formik>
				</Grid>
				<Grid item width={'100%'} height={'50px'} marginTop={'20px'}>
					<Snackbar
						open={snackbarData.open}
						onClose={closeSnackbarHandler}
						key={'bottom center'}
						anchorOrigin={{
							horizontal: 'center',
							vertical: 'bottom',
						}}
						sx={{
							position: 'relative',
							width: '100%',
						}}
					>
						<Alert
							onClose={closeSnackbarHandler}
							severity={snackbarData.severity}
							sx={{
								width: '100%',
							}}
						>
							{snackbarData.message}
						</Alert>
					</Snackbar>
				</Grid>
			</Grid>
		</HomeLayout>
	)
}

export default ForgotPass
