import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button, Box, Grid, Backdrop, Modal, Fade } from '@mui/material'
import UploadDocumentForm from '../forms/UploadDocumentForm'

const style = {
	// eslint-disable-next-line
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 600,
	bgcolor: 'background.paper',
	border: '1px solid #555',
	borderRadius: 1,
	boxShadow: 24,
	p: 4,
}

type Props = {
	isOpen: boolean
	setIsOpen: (newIsOpenValue: boolean) => void
	setSnackbarData: any
	submit: any
}

const SubmitUserDocumentModal = ({
	isOpen,
	setIsOpen,
	setSnackbarData,
	submit,
}: Props) => {
	const { t } = useTranslation()

	const handleClose = () => setIsOpen(false)

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<Box sx={style}>
						<Grid
							container
							paddingX={{ xs: 1, sm: 5 }}
							paddingY={5}
							direction={'column'}
						>
							<Formik
								initialValues={{
									fileName: '',
									documentPersonName: '',
									documentType: '',
									userDocument: null,
									iHaveRead: false,
								}}
								validationSchema={Yup.object({
									fileName: Yup.string().required(
										t('common.required')
									),
									documentPersonName: Yup.string().required(
										t('common.required')
									),
									documentType: Yup.string().required(
										t('common.required')
									),
									userDocument: Yup.mixed().required(
										t('common.fileRequired')
									),
									iHaveRead: Yup.bool().oneOf(
										[true],
										t('common.required')
									),
								})}
								onSubmit={(values, { setSubmitting }) => {
									setIsOpen(false)
									setSubmitting(false)
									submit(values)
								}}
							>
								<Form>
									<UploadDocumentForm
										setSnackbarData={setSnackbarData}
									></UploadDocumentForm>
									<Button
										type="submit"
										variant="contained"
										sx={{
											height: '50px',
											width: '100%',
											mt: 2,
										}}
									>
										{t(
											'submitUserDocumentModal.uploadDocument'
										)}
									</Button>
									<Button
										variant="outlined"
										sx={{
											height: '50px',
											width: '100%',
											mt: 2,
										}}
										onClick={() => setIsOpen(false)}
									>
										{t('submitUserDocumentModal.cancel')}
									</Button>
								</Form>
							</Formik>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default SubmitUserDocumentModal
