import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import { Grid, ListItemIcon, Typography } from '@mui/material'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import urw from '../../resources/urw.png'
import urwMini from '../../resources/westfield.png'
import ChangeLanguageButton from '../common/ChangeLanguageButton'
import { roleHasAccessTo } from '../../utils/helperFunctions'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
import PersonIcon from '@mui/icons-material/Person'
import { useAuth } from '../../hooks/useAuth'

/**
 * Component primary based on MUI Drawer sample-code with modifications to fit theme.
 * Recoil is used to handle state when changing between pages
 */

const drawerState = atom({
	key: 'drawerState',
	default: true,
})

const filterOptionState = atom({
	key: 'filterOptionState',
	default: 'active',
})

const drawerWidth = 305

const openedMixin = (theme: Theme): CSSObject => ({
	width: '100vw',
	[theme.breakpoints.up('sm')]: {
		width: drawerWidth,
	},
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: '0px',
	[theme.breakpoints.up('sm')]: {
		width: '70px',
	},
})

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: '100vw',
	[theme.breakpoints.up('sm')]: {
		width: drawerWidth,
	},
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	'& .MuiPaper-root': {
		border: 'none', // Remove the border
		boxShadow: '0px 4px 42px 5px rgba(30, 51, 71, 0.07)',
	},
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}))

const StyledIconButton = styled(IconButton)({
	borderRadius: '4px',
	'&:hover': {
		borderRadius: '4px',
		color: '#3F3F3F',
	},
})

const OpenMenuMobileButton = styled(IconButton)({
	borderRadius: '30px',
	backgroundColor: 'white',
	border: '1px solid',
	borderColor: 'primary.main',
})

const StyledListItemButton = styled(ListItemButton)({
	minHeight: 48,
	color: '#ACACAC',
	backgroundColor: 'transparent',
	':hover': {
		'&.Mui-selected': {
			backgroundColor: 'transparent',
		},
	},
	'&.Mui-selected': {
		backgroundColor: 'transparent',
		color: '#3F3F3F',
	},
})

const SmallStyledListItemButton = styled(ListItemButton)({
	minHeight: 28,
	color: '#ACACAC',
	backgroundColor: 'transparent',
	':hover': {
		'&.Mui-selected': {
			backgroundColor: 'transparent',
		},
	},
	'&.Mui-selected': {
		backgroundColor: 'transparent',
		color: '#3F3F3F',
	},
})

interface Props {
	children: JSX.Element
}

function AdminMenu({ children }: Props) {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { logout, role, userEmail } = useAuth()

	const [open, setOpen] = useRecoilState(drawerState)
	const [filterOption, setFilterOption] = useRecoilState(filterOptionState)

	const handleDrawerState = () => {
		setOpen(!open)
	}

	const [selectedIndex, setSelectedIndex] = React.useState(2)

	const location = useLocation()

	React.useEffect(() => {
		switch (true) {
			case location.pathname.includes('/admin-panel'):
				if (filterOption === 'waiting') {
					setSelectedIndex(2)
				} else if (filterOption === 'finished') {
					setSelectedIndex(3)
				} else {
					setSelectedIndex(1)
				}
				break
			case location.pathname.includes('/create-user'):
				setSelectedIndex(7)
				break
			case location.pathname.includes('/user-documents'):
				setSelectedIndex(7)
				break
			case location.pathname.includes('/fire-alarm-panel'):
				setSelectedIndex(4)
				break
			case location.pathname.includes('/service-work-panel'):
				setSelectedIndex(5)
				break
			case location.pathname.includes('/user-panel'):
				setSelectedIndex(6)
				break
			default:
				setSelectedIndex(1)
				break
		}
	}, [location])

	const startFinishedWorkPreps = async () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		await setFilterOption('finished')
		navigate('/admin-panel', { state: { filter: 'finished' } })
	}

	const startAwaitingApprovalWorkPreps = async () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		await setFilterOption('waiting')
		navigate('/admin-panel', { state: { filter: 'waiting' } })
	}

	const startActiveWorkPreps = async () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		await setFilterOption('active')
		navigate('/admin-panel')
	}

	const startFireAlarms = () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		navigate('/fire-alarm-panel')
	}

	const startServiceWorks = () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		navigate('/service-work-panel')
	}

	const startCreateUser = () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		navigate('/create-user')
	}

	const startDocumentCollection = () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		navigate('/user-documents')
	}

	const startHandleUser = () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		navigate(`/user-panel/${userEmail}`)
	}

	const startUserPanel = () => {
		if (window.innerWidth < 600) {
			setOpen(false)
		}
		navigate('/user-panel')
	}

	const logOutUser = async () => {
		await logout()
		navigate('/')
	}

	const menuIcon = {
		minWidth: '0px',
		marginRight: '10px',
	}

	// Rendering of unique menu options:
	const handleAccounts = (
		<>
			<ListItem
				key={t('adminMenu.makeAccount')}
				disablePadding
				sx={{ display: 'block' }}
			>
				<StyledListItemButton
					sx={{
						px: open ? 4 : 3,
					}}
					selected={selectedIndex >= 6}
					onClick={startUserPanel}
					disabled={!roleHasAccessTo(role).includes('users')}
				>
					<ListItemIcon sx={menuIcon}>
						<PersonIcon />
					</ListItemIcon>
					{open && (
						<ListItemText
							primary={
								<Typography fontWeight={500}>
									{t('adminMenu.accounts')}
								</Typography>
							}
						/>
					)}
				</StyledListItemButton>
			</ListItem>
			{open && (
				<Collapse in={selectedIndex >= 6} timeout={0} unmountOnExit>
					<List
						disablePadding
						sx={{
							borderLeft: '2px solid #ACACAC',
							marginLeft: 8,
						}}
					>
						<ListItem
							key={t('adminMenu.handleAccounts')}
							disablePadding
							sx={{ display: 'block' }}
						>
							<SmallStyledListItemButton
								sx={{
									py: 0,
									px: 1,
								}}
								selected={selectedIndex === 6}
								onClick={startUserPanel}
							>
								<ListItemText
									primary={
										<Typography fontSize={'15px'}>
											{t('adminMenu.handleAccounts')}
										</Typography>
									}
								/>
							</SmallStyledListItemButton>
						</ListItem>
						<ListItem
							key={t('adminMenu.makeAccount')}
							disablePadding
							sx={{ display: 'block' }}
						>
							<SmallStyledListItemButton
								sx={{
									py: 0,
									px: 1,
									mb: 2,
								}}
								selected={selectedIndex === 7}
								onClick={startCreateUser}
							>
								<ListItemText
									primary={
										<Typography fontSize={'15px'}>
											{t('adminMenu.makeAccount')}
										</Typography>
									}
								/>
							</SmallStyledListItemButton>
						</ListItem>
					</List>
				</Collapse>
			)}
		</>
	)

	const handleAccount = (
		<>
			<ListItem
				key={t('adminMenu.handleAccount')}
				disablePadding
				sx={{ display: 'block' }}
			>
				<StyledListItemButton
					sx={{
						px: open ? 4 : 3,
					}}
					selected={selectedIndex >= 6}
					onClick={startDocumentCollection}
				>
					<ListItemIcon sx={menuIcon}>
						<PersonIcon />
					</ListItemIcon>
					{open && (
						<ListItemText
							primary={
								<Typography fontWeight={500}>
									{t('adminMenu.account')}
								</Typography>
							}
						/>
					)}
				</StyledListItemButton>
			</ListItem>
			{open && (
				<Collapse in={selectedIndex >= 6} timeout={0} unmountOnExit>
					<List
						disablePadding
						sx={{
							borderLeft: '2px solid #ACACAC',
							marginLeft: 8,
						}}
					>
						<ListItem
							key={t('adminMenu.documentCollection')}
							disablePadding
							sx={{ display: 'block' }}
						>
							<SmallStyledListItemButton
								sx={{
									py: 0,
									px: 1,
								}}
								selected={selectedIndex === 7}
								onClick={startDocumentCollection}
							>
								<ListItemText
									primary={
										<Typography fontSize={'15px'}>
											{t('adminMenu.documentCollection')}
										</Typography>
									}
								/>
							</SmallStyledListItemButton>
						</ListItem>
						<ListItem
							key={t('adminMenu.editAccount')}
							disablePadding
							sx={{ display: 'block' }}
						>
							<SmallStyledListItemButton
								sx={{
									py: 0,
									px: 1,
									mb: 2,
								}}
								selected={selectedIndex === 6}
								onClick={startHandleUser}
							>
								<ListItemText
									primary={
										<Typography fontSize={'15px'}>
											{t('adminMenu.editAccount')}
										</Typography>
									}
								/>
							</SmallStyledListItemButton>
						</ListItem>
					</List>
				</Collapse>
			)}
		</>
	)

	return (
		<>
			<Box
				position={'fixed'}
				marginTop={'10px'}
				marginLeft={'10px'}
				zIndex={1000}
			>
				<OpenMenuMobileButton
					onClick={handleDrawerState}
					disableTouchRipple
				>
					{open ? (
						<KeyboardDoubleArrowLeftOutlinedIcon />
					) : (
						<KeyboardDoubleArrowRightOutlinedIcon />
					)}
				</OpenMenuMobileButton>
			</Box>

			<Box display={'flex'}>
				<CssBaseline />
				<Drawer variant="permanent" open={open}>
					<DrawerHeader>
						<StyledIconButton
							onClick={handleDrawerState}
							disableTouchRipple
						>
							{open ? (
								<KeyboardDoubleArrowLeftOutlinedIcon />
							) : (
								<KeyboardDoubleArrowRightOutlinedIcon />
							)}
						</StyledIconButton>
					</DrawerHeader>
					<Box>
						<Box
							component="img"
							sx={{
								px: open ? 8 : 2,
								pt: 7,
							}}
							width={open ? '1000px' : '70px'}
							src={open ? urw : urwMini}
							alt="Unibail-Rodamco-Westfield logo"
						/>
					</Box>
					<Box sx={{ whiteSpace: 'normal' }}>
						<List sx={{ pt: 10 }}>
							<ListItem
								key={t('adminMenu.workPrep')}
								disablePadding
								sx={{ display: 'block' }}
							>
								<StyledListItemButton
									sx={{
										px: open ? 4 : 3,
									}}
									selected={selectedIndex <= 3}
									onClick={startActiveWorkPreps}
									disabled={
										!roleHasAccessTo(role).includes(
											'workPreps'
										)
									}
								>
									<ListItemIcon sx={menuIcon}>
										<AssignmentIcon />
									</ListItemIcon>
									{open && (
										<ListItemText
											primary={
												<Typography fontWeight={500}>
													{t('adminMenu.workPrep')}
												</Typography>
											}
										/>
									)}
								</StyledListItemButton>
							</ListItem>
							{open && (
								<Collapse
									in={selectedIndex <= 3}
									timeout={0}
									unmountOnExit
								>
									<List
										disablePadding
										sx={{
											borderLeft: '2px solid #ACACAC',
											marginLeft: 8,
										}}
									>
										<ListItem
											key={t('adminMenu.active')}
											disablePadding
											sx={{ display: 'block' }}
										>
											<SmallStyledListItemButton
												sx={{
													py: 0,
													px: 1,
												}}
												selected={selectedIndex === 1}
												onClick={startActiveWorkPreps}
											>
												<ListItemText
													primary={
														<Typography
															fontSize={'15px'}
														>
															{t(
																'adminMenu.active'
															)}
														</Typography>
													}
												/>
											</SmallStyledListItemButton>
										</ListItem>
										<ListItem
											key={t(
												'adminMenu.awaitingApproval'
											)}
											disablePadding
											sx={{ display: 'block' }}
										>
											<SmallStyledListItemButton
												sx={{
													py: 0,
													px: 1,
												}}
												selected={selectedIndex === 2}
												onClick={
													startAwaitingApprovalWorkPreps
												}
											>
												<ListItemText
													primary={
														<Typography
															fontSize={'15px'}
														>
															{t(
																'adminMenu.awaitingApproval'
															)}
														</Typography>
													}
												/>
											</SmallStyledListItemButton>
										</ListItem>
										<ListItem
											key={t('adminMenu.finished')}
											disablePadding
											sx={{ display: 'block' }}
										>
											<SmallStyledListItemButton
												sx={{
													py: 0,
													px: 1,
												}}
												selected={selectedIndex === 3}
												onClick={startFinishedWorkPreps}
											>
												<ListItemText
													primary={
														<Typography
															fontSize={'15px'}
														>
															{t(
																'adminMenu.finished'
															)}
														</Typography>
													}
												/>
											</SmallStyledListItemButton>
										</ListItem>
									</List>
								</Collapse>
							)}

							<ListItem
								key={t('adminMenu.fireShutdown')}
								disablePadding
								sx={{ display: 'block' }}
							>
								<StyledListItemButton
									sx={{
										px: open ? 4 : 3,
									}}
									selected={selectedIndex === 4}
									onClick={startFireAlarms}
									disabled={
										!roleHasAccessTo(role).includes(
											'fireAlarms'
										)
									}
								>
									<ListItemIcon sx={menuIcon}>
										<LocalFireDepartmentOutlinedIcon />
									</ListItemIcon>
									{open && (
										<ListItemText
											primary={
												<Typography fontWeight={500}>
													{t(
														'adminMenu.fireShutdown'
													)}
												</Typography>
											}
										/>
									)}
								</StyledListItemButton>
							</ListItem>
							<ListItem
								key={t('adminMenu.serviceJobs')}
								disablePadding
								sx={{ display: 'block' }}
							>
								<StyledListItemButton
									sx={{
										px: open ? 4 : 3,
									}}
									selected={selectedIndex === 5}
									onClick={startServiceWorks}
									disabled={
										!roleHasAccessTo(role).includes(
											'serviceWorks'
										)
									}
								>
									<ListItemIcon sx={menuIcon}>
										<AssignmentOutlinedIcon />
									</ListItemIcon>
									{open && (
										<ListItemText
											primary={
												<Typography fontWeight={500}>
													{t('adminMenu.serviceJobs')}
												</Typography>
											}
										/>
									)}
								</StyledListItemButton>
							</ListItem>
							{!role?.length //Render only after role is set
								? null
								: role === 'Upprättare'
								? handleAccount
								: handleAccounts}
						</List>
						<List
							sx={{
								pb: 0,
								display: 'flex',
								flexDirection: 'row',
								position: 'absolute',
								bottom: 0,
								marginLeft: 'auto',
								marginRight: 'auto',
								left: 0,
								right: 0,
								textAlign: 'center',
							}}
						>
							<ListItem
								key={t('adminMenu.logOut')}
								disablePadding
								sx={{ display: 'block' }}
							>
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open
											? 'initial'
											: 'center',
										px: 4,
										py: 3,
									}}
									onClick={logOutUser}
								>
									<ListItemIcon sx={{ minWidth: '25px' }}>
										<LogoutIcon sx={{ width: '20px' }} />
									</ListItemIcon>
									{open && (
										<ListItemText
											primary={
												<Typography
													fontSize={'14px'}
													fontWeight={500}
												>
													{t('adminMenu.logOut')}
												</Typography>
											}
										/>
									)}
								</ListItemButton>
							</ListItem>
							{open && (
								<ListItem
									key={t('adminMenu.lang')}
									disablePadding
									sx={{ display: 'block' }}
								>
									<ChangeLanguageButton
										variant="text"
										icon
										sx={{
											minHeight: 48,
											justifyContent: 'right',
											color: '#3F3F3F',
											px: 4,
											py: 3,
											textTransform: 'capitalize',
											textAlign: 'right',
											height: '100%',
											width: '100%',
											backgroundColor: 'transparent',
											':hover': {
												backgroundColor:
													'rgba(0, 0, 0, 0.04)',
												borderRadius: 0,
											},
										}}
									/>
								</ListItem>
							)}
						</List>
					</Box>
				</Drawer>
				<Box
					component={Grid}
					container
					display={{ xs: open ? 'none' : 'flex', sm: 'flex' }}
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: '100vh' }}
				>
					{children}
				</Box>
			</Box>
		</>
	)
}

export default AdminMenu
