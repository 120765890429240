import { Grid, Typography } from '@mui/material'
import {
	FireAlarmAnswers,
	ServiceWorkAnswers,
	WorkPrepAnswers,
} from '../../types'
import { PrettyDateWithTime } from '../common/PrettyDate'

interface Props {
	workPrep: WorkPrepAnswers | ServiceWorkAnswers | FireAlarmAnswers
	type: 'workPrep' | 'serviceWork' | 'fireAlarm'
	t: any
}

const headerText = {
	fontWeight: 'medium',
	fontSize: 14,
}
const infoText = {
	fontSize: 14,
	color: 'gray',
}

const Updates = ({ workPrep, type, t }: Props) => {
	let lastEditedOn
	let lastEditedBy
	if (type == 'serviceWork' || type == 'fireAlarm') {
		workPrep = workPrep as ServiceWorkAnswers | FireAlarmAnswers
		lastEditedOn = workPrep.lastEdited?.lastEditedOn
		lastEditedBy = workPrep.lastEdited?.lastEditedBy
	}
	if (type == 'workPrep') {
		workPrep = workPrep as WorkPrepAnswers
		lastEditedOn = workPrep.lastEditedOn
		lastEditedBy = workPrep.lastEditedBy
	}

	return (
		<Grid container paddingLeft={'20px'} marginY={2} columnSpacing={10}>
			{workPrep.completionRequest && (
				<Grid item width={'300px'} marginBottom={'50px'}>
					<Grid container direction={'column'} rowSpacing={1}>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.completionRequested')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.issuedBy')}
							</Typography>
							<Typography sx={infoText}>
								{
									workPrep.completionRequest
										?.completionRequestSentBy
								}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.date')}
							</Typography>
							<Typography sx={infoText}>
								{
									<PrettyDateWithTime
										dateString={
											workPrep.completionRequest
												.completionRequestSentOn
										}
									/>
								}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.message')}
							</Typography>
							<Typography sx={infoText} whiteSpace={'pre-line'}>
								{workPrep.completionRequest
									.completionRequestMessage
									? workPrep.completionRequest
											.completionRequestMessage
									: '-'}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
			{lastEditedOn && lastEditedBy && (
				<Grid item width={'300px'} marginBottom={'50px'}>
					<Grid container direction={'column'} rowSpacing={1}>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.lastEdited')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.issuedBy')}
							</Typography>
							<Typography sx={infoText}>
								{lastEditedBy}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.date')}
							</Typography>
							<Typography sx={infoText}>
								{
									<PrettyDateWithTime
										dateString={
											lastEditedOn ? lastEditedOn : 'null'
										}
									/>
								}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
			{workPrep.lastReminded && (
				<Grid item width={'300px'} marginBottom={'50px'}>
					<Grid container direction={'column'} rowSpacing={1}>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.lastReminded')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.issuedBy')}
							</Typography>
							<Typography sx={infoText}>
								{workPrep.lastReminded.lastRemindedBy}
							</Typography>
						</Grid>
						<Grid item>
							<Typography sx={headerText}>
								{t('workPrepDetails.date')}
							</Typography>
							<Typography sx={infoText}>
								{
									<PrettyDateWithTime
										dateString={
											workPrep.lastReminded.lastRemindedOn
										}
									/>
								}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

export default Updates
