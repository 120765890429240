import { useEffect, useState } from 'react'
import {
	ContactPersonsOnSite,
	FireAlarmAnswers,
	LocationState,
	SnackbarData,
} from '../../types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { FIRE_ALARM_BY_ID } from '../../graphql/queries/workPreps'
import {
	Alert,
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { ScrollToError } from '../../components/formik/ScrollToError'
import ArrowForward from '@mui/icons-material/ArrowForward'
import * as Yup from 'yup'
import { pdf } from '@react-pdf/renderer'
import InfoText from '../../components/common/InfoText'
import CustomDivider from '../../components/common/CustomDivider'
import PaperLooksContainer from '../../components/common/PaperLooksContainer'
import { deepCopyObject } from '../../utils/helperFunctions'
import FireAlarmPDF from '../../components/admin/pdf/FireAlarmPDF'
import EditFireAlarmForm from '../../components/forms/EditFireAlarmForm'
import { UPDATE_FIRE_ALARM } from '../../graphql/mutations/workPreps'
import ErrorOccurred from '../../components/common/ErrorOccurred'
import { useAuth } from '../../hooks/useAuth'

const EditFireAlarm = () => {
	const [fireAlarm, setFireAlarm] = useState<FireAlarmAnswers | null>(null)
	const { userEmail, jwt, loadingRole } = useAuth()
	const location = useLocation()
	const state = location.state as LocationState

	const [updateFireAlarm] = useMutation(UPDATE_FIRE_ALARM)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const isCompleteRequest = (state && state.isFromCompleteRequest) || false

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const params = useParams()

	const { loading, data } = useQuery(FIRE_ALARM_BY_ID, {
		variables: { fireAlarmId: params.fireAlarmId, jwt },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		window.scrollTo(0, 0) //Scroll to top
		if (data) {
			const fireAlarm: FireAlarmAnswers = JSON.parse(data.fireAlarmById)
			setFireAlarm(deepCopyObject(fireAlarm))
		}
	}, [data])

	if (loading || loadingRole) return <LoadingSpinner />

	if (!fireAlarm) return <ErrorOccurred />

	const editFireAlarmValidationSchema = Yup.object({
		fireAlarmOrHotWork: Yup.string().required(t('common.required')),
		workArea: Yup.string().required(t('common.required')),
		shutdownReason: Yup.string().required(t('common.required')),
		startDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required')),
		endDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required'))
			.min(Yup.ref('startDate'), t('common.beforeStart')),
		weekdays: Yup.boolean(),
		weekends: Yup.boolean(),
		aroundTheClock: Yup.boolean(),
		weekdayStartingTime: Yup.date().when('weekdays', {
			is: true,
			then: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
		}),
		weekdayEndingTime: Yup.date().when('weekdays', {
			is: true,
			then: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
		}),
		weekendStartingTime: Yup.date().when('weekends', {
			is: true,
			then: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
		}),
		weekendEndingTime: Yup.date().when('weekends', {
			is: true,
			then: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
		}),
		createdByCompany: Yup.string().required(t('common.required')),
		createdByPersonName: Yup.string().required(t('common.required')),
		telephoneNumberToCreator: Yup.string().required(t('common.required')),
		emailToCreator: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.required(t('common.required')),
	}).test('at-least-one', '', (values) => {
		const { weekdays, weekends, aroundTheClock } = values
		if (!weekdays && !weekends && !aroundTheClock) {
			return new Yup.ValidationError(
				t('common.atLeastOne'),
				null,
				'checkboxes'
			)
		}
		return true
	})

	const weekdayStartingTime = fireAlarm.hours.weekdayStartingTime
		? new Date(
				1,
				1,
				1,
				Number(fireAlarm.hours.weekdayStartingTime.split(':')[0]),
				Number(fireAlarm.hours.weekdayStartingTime.split(':')[1])
		  )
		: ''
	const weekdayEndingTime = fireAlarm.hours.weekdayEndingTime
		? new Date(
				1,
				1,
				1,
				Number(fireAlarm.hours.weekdayEndingTime.split(':')[0]),
				Number(fireAlarm.hours.weekdayEndingTime.split(':')[1])
		  )
		: ''
	const weekendStartingTime = fireAlarm.hours.weekendStartingTime
		? new Date(
				1,
				1,
				1,
				Number(fireAlarm.hours.weekendStartingTime.split(':')[0]),
				Number(fireAlarm.hours.weekendStartingTime.split(':')[1])
		  )
		: ''
	const weekendEndingTime = fireAlarm.hours.weekendEndingTime
		? new Date(
				1,
				1,
				1,
				Number(fireAlarm.hours.weekendEndingTime.split(':')[0]),
				Number(fireAlarm.hours.weekendEndingTime.split(':')[1])
		  )
		: ''

	const initialFormikValues = {
		fireAlarmOrHotWork: fireAlarm.fireAlarmOrHotWork,
		workArea: fireAlarm.workArea,
		shutdownReason: fireAlarm.shutdownReason,
		startDate: fireAlarm.startDate,
		endDate: fireAlarm.endDate,
		weekdayStartingTime: weekdayStartingTime,
		weekdayEndingTime: weekdayEndingTime,
		weekendStartingTime: weekendStartingTime,
		weekendEndingTime: weekendEndingTime,
		weekdays: fireAlarm.weekdays,
		weekends: fireAlarm.weekends,
		aroundTheClock: fireAlarm.aroundTheClock,
		createdByCompany: fireAlarm.createdByCompany,
		createdByPersonName: fireAlarm.createdByPersonName,
		telephoneNumberToCreator: fireAlarm.telephoneNumberToCreator,
		emailToCreator: fireAlarm.emailToCreator,
		nameContactPerson1: fireAlarm.contactPersonsOnSite.contactPerson1?.name,
		emailContactPerson1:
			fireAlarm.contactPersonsOnSite.contactPerson1?.email,
		companyContactPerson1:
			fireAlarm.contactPersonsOnSite.contactPerson1?.company,
		numberContactPerson1:
			fireAlarm.contactPersonsOnSite.contactPerson1?.number,
		nameContactPerson2: fireAlarm.contactPersonsOnSite.contactPerson2?.name,
		emailContactPerson2:
			fireAlarm.contactPersonsOnSite.contactPerson2?.email,
		companyContactPerson2:
			fireAlarm.contactPersonsOnSite.contactPerson2?.company,
		numberContactPerson2:
			fireAlarm.contactPersonsOnSite.contactPerson2?.number,
		numberOfContactPersons: Object.keys(fireAlarm.contactPersonsOnSite)
			.length,
	}

	const parseTime = (values: any) => {
		const timeTypes = [
			'weekdayStartingTime',
			'weekdayEndingTime',
			'weekendStartingTime',
			'weekendEndingTime',
		]
		const parsedTimes: any = {}
		for (const type of timeTypes) {
			if (values[type]) {
				let hours = `${values[type].getHours()}`
				let minutes = `${values[type].getMinutes()}`
				if (parseInt(hours) < 10) {
					hours = `0${hours}`
				}
				if (parseInt(minutes) < 10) {
					minutes = `0${minutes}`
				}
				parsedTimes[type] = `${hours}:${minutes}`
			} else {
				parsedTimes[type] = ''
			}
		}
		return parsedTimes
	}

	const handleSubmit = async (values: any, actions: any) => {
		const workingHours = parseTime(values)

		const contactPersonsOnSite: ContactPersonsOnSite = {}

		if (values.numberOfContactPersons > 0) {
			contactPersonsOnSite['contactPerson1'] = {
				name: values.nameContactPerson1,
				email: values.emailContactPerson1,
				number: values.numberContactPerson1,
				company: values.companyContactPerson1,
			}
		}
		if (values.numberOfContactPersons > 1) {
			contactPersonsOnSite['contactPerson2'] = {
				name: values.nameContactPerson2,
				email: values.emailContactPerson2,
				number: values.numberContactPerson2,
				company: values.companyContactPerson2,
			}
		}

		const fireAlarmAnswers: FireAlarmAnswers = {
			fireAlarmOrHotWork: values.fireAlarmOrHotWork,
			workArea: values.workArea,
			shutdownReason: values.shutdownReason,
			id: fireAlarm.id,
			caseId: fireAlarm.caseId,
			workPrepId: fireAlarm.id,
			workPrepInfo: {
				floor: fireAlarm.workPrepInfo.floor,
				storeIdOrName: fireAlarm.workPrepInfo.storeIdOrName,
				workArea: fireAlarm.workPrepInfo.workArea,
			},
			status: '',
			relevantMall: fireAlarm.relevantMall,
			startDate: values.startDate,
			endDate: values.endDate,
			establishedDate: fireAlarm.establishedDate,
			hours: workingHours,
			weekdays: values.weekdays,
			weekends: values.weekends,
			aroundTheClock: values.aroundTheClock,
			createdByCompany: values.createdByCompany,
			createdByPersonName: values.createdByPersonName,
			telephoneNumberToCreator: values.telephoneNumberToCreator,
			emailToCreator: values.emailToCreator,
			signStatus: '',
			signStatusChangedOn: '',
			signatureType: '',
			statusChangedBy: '',
			statusChangedOn: '',
			contactPersonsOnSite: contactPersonsOnSite,
			isCreatedLoggedIn: fireAlarm.isCreatedLoggedIn,
			lastEdited: {
				lastEditedOn: new Date().toString(),
				lastEditedBy: isCompleteRequest
					? fireAlarm.emailToCreator
					: userEmail
					? userEmail
					: '',
			},
		}

		setSnackbarData({
			open: true,
			severity: 'info',
			message: t('fireAlarmShutdown.edit.updating'),
		})

		const fireAlarmBlob = await pdf(
			<FireAlarmPDF fireAlarmAnswers={fireAlarmAnswers} customLang="sv" />
		).toBlob()

		const fireAlarmPDF = new File([fireAlarmBlob], 'filename.pdf')

		const res = await updateFireAlarm({
			variables: {
				fireAlarmAnswers: JSON.stringify(fireAlarmAnswers),
				fireAlarmPDF,
				isCompleteRequest,
				jwt,
			},
		})

		if (res.data.updateFireAlarm === 'Assently error') {
			setSnackbarData({
				message: t('workPrep.assentlyError'),
				severity: 'error',
				open: true,
			})
		} else {
			setSnackbarData({
				message: t('fireAlarmShutdown.edit.updated'),
				severity: 'success',
				open: true,
			})
			// if change is made by admin, signature notification is sent instead.
			if (res.data.updateFireAlarm === 'Notification sent') {
				navigate(`/fire-alarm-panel/${params.fireAlarmId}`)
			} else {
				try {
					const redirectUrl = new URL(res.data.updateFireAlarm)
					window.location.replace(redirectUrl)
				} catch {
					setSnackbarData({
						message: t('workPrep.urlError'),
						severity: 'error',
						open: true,
					})
				}
			}
		}
	}

	return (
		<>
			<PaperLooksContainer
				isCompleteRequest={isCompleteRequest}
				backBtnPath={`/fire-alarm-panel/${params.fireAlarmId}`}
			>
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
				>
					<Grid item>
						<h1>
							{t('fireAlarmShutdown.form.title') +
								': ' +
								params.fireAlarmId}
						</h1>
					</Grid>
					<CustomDivider />
					<Formik
						initialValues={initialFormikValues}
						validationSchema={editFireAlarmValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting }) => (
							<Form>
								<ScrollToError />
								<EditFireAlarmForm />
								<Grid item>
									<InfoText
										sx={{
											borderColor: '#ef4444',
											backgroundColor: '#fef2f2',
											marginBottom: '10px',
										}}
									>
										<Typography>
											{t('editWorkPrep.warning')}
										</Typography>
									</InfoText>
								</Grid>
								<Grid
									item
									display={'flex'}
									justifyContent={'right'}
								>
									<Button
										disabled={isSubmitting}
										type="submit"
										variant="contained"
										endIcon={
											isSubmitting ? (
												<CircularProgress size={20} />
											) : (
												<ArrowForward />
											)
										}
										sx={{ height: 50 }}
									>
										{t('workPrep.save')}
									</Button>
								</Grid>
							</Form>
						)}
					</Formik>
				</Grid>
			</PaperLooksContainer>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default EditFireAlarm
