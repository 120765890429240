import HomeLayout from '../components/common/HomeLayout'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../hooks/useAuth'

const Home = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { currentUser } = useAuth()

	useEffect(() => {
		if (currentUser) {
			navigate('/admin-panel')
		}
	}, [currentUser])

	const startWorkPrepHandler = () => {
		navigate('/work-preparation')
	}

	const fireAlarmHandler = () => {
		navigate('/fire-alarm-id')
	}

	const signInPage = () => {
		navigate('/sign-in')
	}

	const serviceWorkHandler = () => {
		navigate('/service-work-login')
	}

	return (
		<HomeLayout>
			<Grid
				container
				width={'100%'}
				maxWidth={'500px'}
				direction="column"
				spacing={2}
			>
				<Grid item>
					<Typography fontSize={{ xs: 25, sm: 32 }}>
						{t('homePage.welcomeTitle1')}
					</Typography>
					<Typography fontSize={{ xs: 25, sm: 32 }}>
						{t('homePage.welcomeTitle2')}
					</Typography>
					<Typography fontSize={{ xs: 25, sm: 32 }}>
						{t('homePage.welcomeTitle3')}
					</Typography>
				</Grid>
				<Grid item>
					<Button
						onClick={startWorkPrepHandler}
						variant="contained"
						sx={{
							width: '100%',
							height: '50px',
						}}
					>
						{t('homePage.fillOutWorkPrepBtnText')}
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={fireAlarmHandler}
						variant="contained"
						sx={{
							width: '100%',
							minHeight: '50px',
						}}
					>
						{t('homePage.fillOutFireAlarmShutdownBtnText')}
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={serviceWorkHandler}
						variant="contained"
						sx={{
							width: '100%',
							minHeight: '50px',
						}}
					>
						{t('homePage.fillOutServiceWorkBtnText')}
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="outlined"
						onClick={signInPage}
						sx={{
							width: '100%',
							height: '50px',
						}}
					>
						{t('homePage.loginBtnText')}
					</Button>
				</Grid>
			</Grid>
		</HomeLayout>
	)
}

export default Home
