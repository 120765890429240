import {
	Select,
	FormControl,
	FormHelperText,
	InputLabel,
	SxProps,
} from '@mui/material'
import { useField } from 'formik'

interface Props {
	label: string
	name: string
	children: JSX.Element | JSX.Element[]
	sx?: SxProps
	multiple?: boolean
	disabled?: boolean
}

const CustomSelectInput = ({
	label,
	name,
	children,
	sx,
	multiple = false,
	disabled = false,
}: Props) => {
	const [field, meta] = useField({ name })

	return (
		<FormControl error={meta.touched && Boolean(meta.error)} sx={sx}>
			<InputLabel>{label}</InputLabel>

			<Select
				label={label}
				name={name}
				value={field.value}
				onChange={field.onChange}
				onBlur={field.onBlur}
				children={children}
				error={meta.touched && Boolean(meta.error)}
				multiple={multiple}
				disabled={disabled}
			/>
			<FormHelperText>
				{(meta.touched && meta.error) || ' '}
			</FormHelperText>
		</FormControl>
	)
}

export default CustomSelectInput
