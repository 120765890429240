import { useQuery, useMutation } from '@apollo/client'
import {
	Alert,
	AlertProps,
	Button,
	Grid,
	Snackbar,
	SnackbarProps,
	Typography,
} from '@mui/material'
import AddCommentIcon from '@mui/icons-material/AddComment'
import DeleteIcon from '@mui/icons-material/Delete'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import Divider from '@mui/material/Divider'
import { useNavigate, useParams } from 'react-router-dom'
import FieldDisplay from '../../components/admin/FieldDisplay'
import PaperLooksContainer from '../../components/common/PaperLooksContainer'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { FIRE_ALARM_BY_ID } from '../../graphql/queries/workPreps'
import {
	UPDATE_FIRE_ALARM_STATUS,
	DELETE_FIRE_ALARM,
	SEND_REMINDER_FIRE_ALARM,
	ADD_COMMENT_FIRE_ALARM,
} from '../../graphql/mutations/workPreps'
import { FireAlarmAnswers, commentForm } from '../../types'
import Layout from '../../components/common/Layout'
import { useState } from 'react'
import { apolloClient } from '../..'
import { pdf } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { isAuthorized } from '../../utils/helperFunctions'
import AdminMenu from '../../components/admin/AdminMenu'
import Box from '@mui/material/Box'
import WorkPrepStatusDisplay from '../../components/common/WorkPrepStatusDisplay'
import SignStatusListFireAlarm from '../../components/admin/SignStatusListFireAlarm'
import FireAlarmPDF from '../../components/admin/pdf/FireAlarmPDF'
import ChangeStatusModal from '../../components/admin/ChangeStatusModal'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import DeleteWorkModal from '../../components/admin/DeleteWorkModal'
import CommentSection from '../../components/admin/CommentSection'
import CommentModal from '../../components/admin/CommentModal'
import SettingsIcon from '@mui/icons-material/Settings'
import { Repeat } from '@mui/icons-material'
import RequestCompletionModal from '../../components/admin/RequestCompletionModal'
import Updates from '../../components/admin/Updates'
import { useAuth } from '../../hooks/useAuth'
import { PrettyDate } from '../../components/common/PrettyDate'
import RestoreIcon from '@mui/icons-material/Restore'

const FireAlarmDetails = () => {
	const [snackbarOptions, setSnackbarOptions] = useState<
		SnackbarProps & AlertProps
	>({
		open: false,
		severity: 'success',
		autoHideDuration: null,
		anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
		message: '',
	})
	const [requestCompletionModalIsOpen, setRequestCompletionModalIsOpen] =
		useState<boolean>(false)
	const [commentModalIsOpen, setCommentModalIsOpen] = useState<boolean>(false)
	const [approveModalIsOpen, setApproveModalIsOpen] = useState<boolean>(false)
	const [rejectModalIsOpen, setRejectModalIsOpen] = useState<boolean>(false)
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
	const { t, i18n } = useTranslation()
	const lang = i18n.resolvedLanguage as 'sv' | 'en'

	const navigate = useNavigate()

	const params = useParams()

	const { userEmail, role, jwt } = useAuth()

	const [deleteFireAlarm] = useMutation(DELETE_FIRE_ALARM)

	const hideSnackBar = () => {
		setSnackbarOptions({
			...snackbarOptions,
			open: false,
		})
	}

	const navigateToWorkprep = () => {
		if (params.fireAlarmId) {
			const workPrepId = params.fireAlarmId.substring(
				0,
				params.fireAlarmId.lastIndexOf('-')
			)
			navigate(`/admin-panel/${workPrepId}`)
		}
	}

	const { loading, data } = useQuery(FIRE_ALARM_BY_ID, {
		variables: { fireAlarmId: params.fireAlarmId, jwt },
	})

	const submitComment = async (values: any) => {
		const comment: commentForm = {
			commentHeading: values.commentHeading,
			commentMessage: values.commentMessage,
			commentAddedBy: userEmail ? userEmail : '-',
			commentAddedOn: new Date().toString(),
			commentCreator: values.commentCreator,
			id: params.fireAlarmId!,
		}
		const commentJsonString = JSON.stringify(comment)

		setSnackbarOptions({
			message: t('workPrepDetails.commentModal.addingComment'),
			severity: 'info',
			open: true,
		})

		await apolloClient.mutate({
			mutation: ADD_COMMENT_FIRE_ALARM,
			variables: {
				comment: commentJsonString,
				jwt,
			},
			refetchQueries: [
				{
					query: FIRE_ALARM_BY_ID,
					variables: {
						fireAlarmId: params.fireAlarmId,
						jwt,
					},
				},
			],
		})
		setSnackbarOptions({
			message: t('workPrepDetails.commentModal.requestSent'),
			severity: 'success',
			open: true,
		})
	}

	const sendSignatureReminder = async () => {
		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'info',
			message: t('fireAlarmDetails.updatingStatus'),
		})

		const res = await apolloClient.mutate({
			mutation: SEND_REMINDER_FIRE_ALARM,
			variables: {
				fireAlarmId: currentFireAlarm.id,
				jwt,
			},
		})

		if (res.data.sendReminderFireAlarm === 'Assently error') {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'error',
				message: t('workPrepDetails.reminderSentError'),
				autoHideDuration: 5000,
			})
		} else {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'success',
				message: t('workPrepDetails.reminderSent'),
				autoHideDuration: 5000,
			})
		}
	}

	const updateFireAlarmStatus = async (status: string, message: string) => {
		const date = new Date().toString()

		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'info',
			message: t('fireAlarmDetails.updatingStatus'),
		})

		const tempCurrentFireAlarm = { ...currentFireAlarm }

		tempCurrentFireAlarm.status = status
		tempCurrentFireAlarm.statusChangedOn = date

		const fireAlarmBlob = await pdf(
			<FireAlarmPDF
				fireAlarmAnswers={tempCurrentFireAlarm}
				customLang={lang}
			/>
		).toBlob()

		const fireAlarmPDF = new File([fireAlarmBlob], 'filename.pdf')

		console.log(currentFireAlarm)

		await apolloClient.mutate({
			mutation: UPDATE_FIRE_ALARM_STATUS,
			variables: {
				fireAlarmId: currentFireAlarm.id,
				userEmail,
				newStatus: status,
				newStatusChangedOn: date,
				message: message,
				fireAlarmPDF,
				jwt,
			},
			refetchQueries: [
				{
					query: FIRE_ALARM_BY_ID,
					variables: {
						fireAlarmId: currentFireAlarm.id,
						userEmail: userEmail,
						jwt,
					},
				},
			],
		})

		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'success',
			message: t('fireAlarmDetails.statusUpdated'),
			autoHideDuration: 5000,
		})
	}

	const handleDelete = async () => {
		deleteFireAlarm({
			variables: {
				fireAlarmId: currentFireAlarm.id,
				jwt,
			},
		})
		navigate('/fire-alarm-panel')
	}

	if (loading && !data)
		return (
			<AdminMenu>
				<LoadingSpinner />
			</AdminMenu>
		)

	const currentFireAlarm: FireAlarmAnswers = JSON.parse(data.fireAlarmById)

	if (
		!isAuthorized(role, currentFireAlarm?.relevantMall) &&
		role !== 'Upprättare' &&
		role !== 'projectManager'
	) {
		return <NotLoggedIn />
	}

	const scrollToOverview = () => {
		const element = document.getElementById('WorkPrepOverview')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToCreator = () => {
		const element = document.getElementById('WorkPrepCreator')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToWorkPrep = () => {
		const element = document.getElementById('WorkPrepInfo')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToSignStatus = () => {
		const element = document.getElementById('WorkPrepSignStatus')
		element?.scrollIntoView({ behavior: 'smooth' })
	}

	const allActions = [
		'pdf',
		'remind',
		'changeStatus',
		'remove',
		'edit',
		'completion',
	]

	const roleActions = {
		SuperAdmin: allActions,
		'Admin - Alla centrum': allActions,
		'Admin - Westfield Mall of Scandinavia': allActions,
		'Admin - Nacka Forum': allActions,
		'Admin - Täby Centrum': allActions,
		'Observer - MOS': ['pdf'],
		'Observer - Nacka Forum': ['pdf'],
		'Observer - Täby Centrum': ['pdf'],
		'Drift - Westfield Mall of Scandinavia': allActions,
		'Drift - Nacka Forum': allActions,
		'Drift - Täby Centrum': allActions,
		Upprättare: ['pdf', 'remove', 'edit'],
		projectManager: ['pdf'],
	}

	return (
		<>
			<AdminMenu>
				<Layout>
					<PaperLooksContainer noLogo backBtnPath="/fire-alarm-panel">
						<Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									mt: 8,
									flexWrap: 'wrap',
								}}
							>
								<Typography
									variant="h5"
									component="h2"
									sx={{
										fontWeight: 500,
										mt: 1,
										alignItems: 'center',
									}}
								>
									{t('fireAlarmDetails.title') + ' ID: '}
									{currentFireAlarm.id}
								</Typography>
								<WorkPrepStatusDisplay
									status={
										currentFireAlarm.signStatus
											? currentFireAlarm.status
											: 'AwaitingSigning'
									}
								></WorkPrepStatusDisplay>
							</Box>
							<RequestCompletionModal
								isOpen={requestCompletionModalIsOpen}
								setIsOpen={setRequestCompletionModalIsOpen}
								setSnackbarData={setSnackbarOptions}
								workPrepId={currentFireAlarm.id}
								type="fireAlarm"
							/>
							<CommentModal
								isOpen={commentModalIsOpen}
								setIsOpen={setCommentModalIsOpen}
								submitComment={submitComment}
							/>
							<ChangeStatusModal
								isOpen={approveModalIsOpen}
								setIsOpen={setApproveModalIsOpen}
								submitStatus={updateFireAlarmStatus}
								status={'Approved'}
							/>
							<ChangeStatusModal
								isOpen={rejectModalIsOpen}
								setIsOpen={setRejectModalIsOpen}
								submitStatus={updateFireAlarmStatus}
								status={'Rejected'}
							/>
							<DeleteWorkModal
								isOpen={deleteModalIsOpen}
								setIsOpen={setDeleteModalIsOpen}
								submit={handleDelete}
							/>
							<Box className="flex justify-end">
								<Box className="flex flex-col gap-2">
									<Button
										sx={{
											minWidth: {
												xs: 'calc(100vw - 56px)',
												sm: '240px',
											},
										}}
										variant="contained"
										onClick={navigateToWorkprep}
									>
										{t('fireAlarmDetails.toWorkprep')}
									</Button>
									<Button
										variant="contained"
										sx={{ gap: 1 }}
										onClick={() =>
											navigate(
												`../admin-panel/work-history/${currentFireAlarm.createdByCompany}`
											)
										}
									>
										<RestoreIcon />
										{t('workHistory.creatorHistoryTitle')}
									</Button>
								</Box>
							</Box>
							<Box
								sx={{
									borderBottom: 2,
									borderColor: 'divider',
									mt: 1,
									mb: 4,
									display: 'flex',
									flexWrap: 'wrap',
								}}
							>
								<Button onClick={scrollToOverview}>
									{t('fireAlarmDetails.scrollMenu.overview')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToWorkPrep}>
									{t('fireAlarmDetails.scrollMenu.workPrep')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToCreator}>
									{t('fireAlarmDetails.scrollMenu.creator')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToSignStatus}>
									{t(
										'fireAlarmDetails.scrollMenu.signStatus'
									)}
								</Button>
							</Box>
							<Grid
								id="WorkPrepOverview"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={12}>
									<FieldDisplay
										title={t('fireAlarmDetails.created')}
										value={
											<PrettyDate
												dateString={
													currentFireAlarm.establishedDate
												}
											/>
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.fireAlarmOrHotWork'
										)}
										value={
											currentFireAlarm.fireAlarmOrHotWork ==
											'fireAlarm'
												? t(
														'fireAlarmDetails.fireAlarm'
												  )
												: currentFireAlarm.fireAlarmOrHotWork ==
												  'hotWork'
												? t('fireAlarmDetails.hotWork')
												: '-'
										}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={8}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.shutdownReason'
										)}
										value={currentFireAlarm.shutdownReason}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.relevantMall'
										)}
										value={
											currentFireAlarm.relevantMall ==
											'Täby Centrum'
												? 'Westfield Täby Centrum'
												: currentFireAlarm.relevantMall
										}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t('fireAlarmDetails.workArea')}
										value={currentFireAlarm.workArea}
									/>{' '}
								</Grid>
							</Grid>

							<Divider sx={{ my: 4 }} />

							<Grid
								id="WorkPrepInfo"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.workPrepFloor'
										)}
										value={
											currentFireAlarm.workPrepInfo.floor
										}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.workPrepStore'
										)}
										value={
											currentFireAlarm.workPrepInfo
												.storeIdOrName
										}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.workPrepWorkArea'
										)}
										value={
											currentFireAlarm.workPrepInfo
												.workArea
										}
									/>{' '}
								</Grid>
							</Grid>

							<Divider sx={{ my: 4 }} />
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t('fireAlarmDetails.startDate')}
										value={
											<PrettyDate
												dateString={
													currentFireAlarm.startDate
												}
											/>
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t('fireAlarmDetails.endDate')}
										value={
											<PrettyDate
												dateString={
													currentFireAlarm.endDate
												}
											/>
										}
									/>
								</Grid>
							</Grid>
							{/* TODO remove startingTime and edingTime since we use seperate times for weekdays and weekends*/}
							{currentFireAlarm.hours.startingTime &&
								currentFireAlarm.hours.endingTime && (
									<Grid container spacing={4} sx={{ mb: 4 }}>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={4}
										>
											<FieldDisplay
												title={t(
													'fireAlarmDetails.startingTime'
												)}
												value={
													currentFireAlarm.hours
														.startingTime
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={4}
										>
											<FieldDisplay
												title={t(
													'fireAlarmDetails.endingTime'
												)}
												value={
													currentFireAlarm.hours
														.endingTime
												}
											/>
										</Grid>
									</Grid>
								)}
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t(
											'fireAlarmShutdown.form.weekdays'
										)}
										value={
											currentFireAlarm.weekdays
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								{currentFireAlarm.hours.weekdayStartingTime &&
									currentFireAlarm.hours
										.weekdayEndingTime && (
										<>
											<Grid
												item
												sx={{ minWidth: '250px' }}
												xs={4}
											>
												<FieldDisplay
													title={t(
														'fireAlarmDetails.startingTime'
													)}
													value={
														currentFireAlarm.hours
															.weekdayStartingTime
													}
												/>
											</Grid>
											<Grid
												item
												sx={{ minWidth: '250px' }}
												xs={4}
											>
												<FieldDisplay
													title={t(
														'fireAlarmDetails.endingTime'
													)}
													value={
														currentFireAlarm.hours
															.weekdayEndingTime
													}
												/>
											</Grid>
										</>
									)}
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t(
											'fireAlarmShutdown.form.weekends'
										)}
										value={
											currentFireAlarm.weekends
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								{currentFireAlarm.hours.weekendStartingTime &&
									currentFireAlarm.hours
										.weekendEndingTime && (
										<>
											<Grid
												item
												sx={{ minWidth: '250px' }}
												xs={4}
											>
												<FieldDisplay
													title={t(
														'fireAlarmDetails.startingTime'
													)}
													value={
														currentFireAlarm.hours
															.weekendStartingTime
													}
												/>
											</Grid>
											<Grid
												item
												sx={{ minWidth: '250px' }}
												xs={4}
											>
												<FieldDisplay
													title={t(
														'fireAlarmDetails.endingTime'
													)}
													value={
														currentFireAlarm.hours
															.weekendEndingTime
													}
												/>
											</Grid>
										</>
									)}
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={4}>
									<FieldDisplay
										title={t(
											'fireAlarmShutdown.form.aroundTheClock'
										)}
										value={
											currentFireAlarm.aroundTheClock
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Grid
								id="WorkPrepCreator"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.createdByCompany'
										)}
										value={
											currentFireAlarm.createdByCompany
												? currentFireAlarm.createdByCompany
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.createdByName'
										)}
										value={
											currentFireAlarm.createdByPersonName
												? currentFireAlarm.createdByPersonName
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'fireAlarmDetails.phoneNumber'
										)}
										value={
											currentFireAlarm.telephoneNumberToCreator
												? currentFireAlarm.telephoneNumberToCreator
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('fireAlarmDetails.email')}
										value={
											currentFireAlarm.emailToCreator
												? currentFireAlarm.emailToCreator
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							{currentFireAlarm.contactPersonsOnSite
								.contactPerson1 && (
								<>
									<Grid
										id="ContactPersonOnSite1"
										container
										spacing={4}
										sx={{ mb: 4 }}
									>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.companyApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson1.company
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.nameApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson1.name
												}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={4} sx={{ mb: 4 }}>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.phonenumberApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson1.number
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.emailApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson1.email
												}
											/>
										</Grid>
									</Grid>
									<Divider sx={{ my: 4 }} />
								</>
							)}
							{currentFireAlarm.contactPersonsOnSite
								.contactPerson2 && (
								<>
									<Grid
										id="ContactPersonOnSite2"
										container
										spacing={4}
										sx={{ mb: 4 }}
									>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.companyApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson2.company
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.nameApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson2.name
												}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={4} sx={{ mb: 4 }}>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.phonenumberApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson2.number
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'fireAlarmShutdown.form.emailApplicant'
												)}
												value={
													currentFireAlarm
														.contactPersonsOnSite
														.contactPerson2.email
												}
											/>
										</Grid>
									</Grid>
									<Divider sx={{ my: 4 }} />
								</>
							)}

							<Box id="WorkPrepSignStatus">
								<FieldDisplay
									title={t('workPrep.signStatus')}
									value={
										<SignStatusListFireAlarm
											fireAlarm={currentFireAlarm}
										/>
									}
								/>
							</Box>
							<Divider sx={{ my: 4 }} />

							{(currentFireAlarm.lastEdited ||
								currentFireAlarm.completionRequest ||
								currentFireAlarm.lastReminded) && (
								<>
									<div
										className={
											'flex flex-wrap gap-x-7 md:gap-x-10 gap-y-4'
										}
									>
										<FieldDisplay
											title={t('workPrepDetails.updates')}
											value={
												<Updates
													workPrep={currentFireAlarm}
													type="fireAlarm"
													t={t}
												/>
											}
										/>
									</div>
									<Divider sx={{ my: 4 }} />
								</>
							)}

							<div id="WorkPrepComments">
								<FieldDisplay
									title={t('workPrepDetails.commentSection')}
									value={
										<CommentSection
											data={currentFireAlarm}
											isFireAlarm
										/>
									}
								/>
								<div
									style={{ paddingLeft: 16, paddingTop: 10 }}
								>
									<Button
										startIcon={<AddCommentIcon />}
										variant="outlined"
										onClick={() =>
											setCommentModalIsOpen(
												!commentModalIsOpen
											)
										}
									>
										{t('workPrepDetails.commentWorkPrep')}
									</Button>
								</div>
							</div>
							<Divider sx={{ my: 4 }} />

							{roleActions[
								role as keyof typeof roleActions
							].includes('pdf') && (
								<Button
									fullWidth={true}
									startIcon={<FileOpenIcon />}
									variant="contained"
									onClick={() =>
										navigate(
											`/fire-alarm-panel/${currentFireAlarm.id}/pdf`
										)
									}
									sx={{ mt: 2 }}
								>
									{t('fireAlarmDetails.openPDF')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('edit') && (
								<Button
									fullWidth={true}
									startIcon={<SettingsIcon />}
									variant="contained"
									onClick={() =>
										navigate(
											`/fire-alarm-edit/${currentFireAlarm.id}`
										)
									}
									sx={{ mt: 2 }}
								>
									{t('fireAlarmDetails.edit')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('remind') && (
								<Button
									fullWidth={true}
									startIcon={<NotificationsActiveIcon />}
									disabled={
										currentFireAlarm.signStatus == 'Signed'
									}
									variant="contained"
									onClick={() => sendSignatureReminder()}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.sendReminder')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('completion') && (
								<Button
									fullWidth={true}
									startIcon={<Repeat />}
									variant="contained"
									onClick={() =>
										setRequestCompletionModalIsOpen(
											!requestCompletionModalIsOpen
										)
									}
									sx={{ mt: 2 }}
								>
									{t('fireAlarmDetails.completionRequest')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('changeStatus') && (
								<Button
									fullWidth={true}
									disabled={
										!currentFireAlarm.signStatus ||
										currentFireAlarm.status === 'Approved'
									}
									startIcon={<CheckCircleIcon />}
									variant="contained"
									color="success"
									onClick={() =>
										setApproveModalIsOpen(
											!approveModalIsOpen
										)
									}
									sx={{ mt: 2 }}
								>
									{t('fireAlarmDetails.approve')}
								</Button>
							)}

							{roleActions[
								role as keyof typeof roleActions
							].includes('changeStatus') && (
								<Button
									fullWidth={true}
									disabled={
										currentFireAlarm.status === 'Rejected'
									}
									startIcon={<ThumbDownIcon />}
									variant="contained"
									color="error"
									onClick={() =>
										setRejectModalIsOpen(!rejectModalIsOpen)
									}
									sx={{ mt: 2 }}
								>
									{t('fireAlarmDetails.reject')}
								</Button>
							)}

							{roleActions[
								role as keyof typeof roleActions
							].includes('remove') && (
								<Button
									fullWidth={true}
									startIcon={<DeleteIcon />}
									variant="outlined"
									color="error"
									onClick={() =>
										setDeleteModalIsOpen(!deleteModalIsOpen)
									}
									sx={{ mt: 2 }}
								>
									{t('fireAlarmDetails.remove')}
								</Button>
							)}
						</Box>
					</PaperLooksContainer>
				</Layout>
			</AdminMenu>
			<Snackbar
				open={snackbarOptions.open}
				autoHideDuration={snackbarOptions.autoHideDuration}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				sx={{ position: 'fixed' }}
			>
				<Alert
					onClose={hideSnackBar}
					severity={snackbarOptions.severity}
					sx={{ width: '100%' }}
				>
					{snackbarOptions.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default FireAlarmDetails
