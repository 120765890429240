import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from './components/common/Layout'
import Home from './pages/Home'
import WorkPrep from './pages/WorkPrep'
import AdminPanel from './pages/admin/AdminPanel'
import Redirect from './pages/Redirect'
import SignIn from './pages/authentication/SignIn'
import CreateUser from './pages/authentication/CreateUser'
import EditWorkPrep from './pages/admin/EditWorkPrep'
import WorkPrepDetails from './pages/admin/WorkPrepDetails'
import WorkPrepPDF from './pages/admin/WorkPrepPDF'
import ForgotPass from './pages/authentication/ForgotPass'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import UserDocuments from './pages/UserDocuments'
import FireAlarmIdCheck from './pages/FireAlarmIdCheck'
import FireAlarmForm from './pages/FireAlarmForm'
import FireAlarmDetails from './pages/admin/FireAlarmDetails'
import OpenFireAlarmPDF from './pages/admin/OpenFireAlarmPDF'
import FireAlarmFindId from './pages/FireAlarmFindId'
import ServiceWorkForm from './pages/ServiceWorkForm'
import ServiceWorkDetails from './pages/admin/ServiceWorkDetails'
import OpenServiceWorkPDF from './pages/admin/OpenServiceWorkPDF'
import AdminMenu from './components/admin/AdminMenu'
import EditServiceWork from './pages/admin/EditServiceWork'
import UsersPanel from './pages/admin/UsersPanel'
import UserDetails from './pages/UserDetails'
import RoleWrapper from './pages/admin/RoleWrapper'
import ServiceWorkPanel from './pages/admin/ServiceWorkPanel'
import FireAlarmPanel from './pages/admin/FireAlarmPanel'
import EditEndDateWorkPrep from './pages/EditEndDateWorkPrep'
import EditFireAlarm from './pages/admin/EditFireAlarm'
import ServiceWorkLogin from './pages/ServiceWorkLogin'
import WorkHistory from './pages/admin/WorkHistory'

const theme = createTheme({
	palette: {
		primary: {
			main: '#444444',
		},
	},
})

function App() {
	const { i18n } = useTranslation()

	const lang = i18n.resolvedLanguage as 'sv' | 'en'

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route
						path="/work-preparation"
						element={
							<Layout>
								<WorkPrep />
							</Layout>
						}
					/>
					<Route
						path="/work-preparation/:signStatus"
						element={
							<Layout>
								<WorkPrep />
							</Layout>
						}
					/>
					<Route
						path="/fire-alarm-id"
						element={<FireAlarmIdCheck />}
					/>
					<Route
						path="/fire-alarm-find-id"
						element={<FireAlarmFindId />}
					/>
					<Route
						path="/fire-alarm"
						element={
							<Layout>
								<FireAlarmForm />
							</Layout>
						}
					/>
					<Route
						path="/fire-alarm-edit/:fireAlarmId"
						element={
							<Layout>
								<EditFireAlarm />
							</Layout>
						}
					/>
					<Route
						path="/service-work"
						element={
							<Layout>
								<ServiceWorkForm />
							</Layout>
						}
					/>
					<Route
						path="/service-work-login"
						element={<ServiceWorkLogin />}
					/>
					<Route
						path="/service-work-edit/:serviceWorkId"
						element={
							<Layout>
								<EditServiceWork />
							</Layout>
						}
					/>
					<Route
						path="/service-work/:signStatus"
						element={
							<Layout>
								<ServiceWorkForm />
							</Layout>
						}
					/>
					<Route
						path="/fire-alarm/:signStatus"
						element={
							<Layout>
								<FireAlarmForm />
							</Layout>
						}
					/>
					<Route
						path="/redirect/:redirectUUID"
						element={<Redirect />}
					/>
					<Route
						path="/admin-panel"
						element={
							<AdminMenu>
								<RoleWrapper>
									<AdminPanel />
								</RoleWrapper>
							</AdminMenu>
						}
					/>
					<Route
						path="/admin-panel/work-history/:company"
						element={
							<AdminMenu>
								<RoleWrapper>
									<WorkHistory />
								</RoleWrapper>
							</AdminMenu>
						}
					/>{' '}
					{/* Add the new route */}
					<Route
						path="/fire-alarm-panel"
						element={
							<AdminMenu>
								<RoleWrapper>
									<FireAlarmPanel />
								</RoleWrapper>
							</AdminMenu>
						}
					/>
					<Route
						path="/service-work-panel"
						element={
							<AdminMenu>
								<RoleWrapper>
									<ServiceWorkPanel />
								</RoleWrapper>
							</AdminMenu>
						}
					/>
					<Route
						path="/service-work-panel/:serviceWorkId"
						element={
							<RoleWrapper>
								<ServiceWorkDetails />
							</RoleWrapper>
						}
					/>
					<Route
						path="/fire-alarm-panel/:fireAlarmId"
						element={
							<RoleWrapper>
								<FireAlarmDetails />
							</RoleWrapper>
						}
					/>
					<Route
						path="/fire-alarm-panel/:fireAlarmId/pdf"
						element={<OpenFireAlarmPDF customLang={lang} />}
					/>
					<Route
						path="/service-work-panel/:serviceWorkId/pdf"
						element={<OpenServiceWorkPDF customLang={lang} />}
					/>
					<Route
						path="/admin-panel/:workPrepId"
						element={
							<RoleWrapper>
								<WorkPrepDetails />
							</RoleWrapper>
						}
					/>
					<Route
						path="/admin-panel/editWorkPrep/:workPrepId"
						element={
							<Layout>
								<EditWorkPrep />
							</Layout>
						}
					/>
					<Route
						path="/updateEndDate/:workPrepId"
						element={
							<Layout>
								<EditEndDateWorkPrep />
							</Layout>
						}
					/>
					<Route
						path="/admin-panel/:workPrepId/pdf"
						element={<WorkPrepPDF customLang={lang} />}
					/>
					<Route path="/sign-in" element={<SignIn />} />
					<Route path="/create-user" element={<CreateUser />} />
					<Route path="/forgot-password" element={<ForgotPass />} />
					<Route path="/user-documents" element={<UserDocuments />} />
					<Route
						path="/user-panel"
						element={
							<AdminMenu>
								<RoleWrapper>
									<UsersPanel />
								</RoleWrapper>
							</AdminMenu>
						}
					/>
					<Route
						path="/user-panel/:user"
						element={
							<AdminMenu>
								<RoleWrapper>
									<UserDetails />
								</RoleWrapper>
							</AdminMenu>
						}
					/>
				</Routes>
			</Router>
		</ThemeProvider>
	)
}

export default App
