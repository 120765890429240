import { useTranslation } from 'react-i18next'
import {
	Button,
	Typography,
	Box,
	Grid,
	Backdrop,
	Modal,
	Fade,
} from '@mui/material'

const style = {
	// eslint-disable-next-line
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 605,
	bgcolor: 'background.paper',
	border: '1px solid #555',
	borderRadius: 1,
	boxShadow: 24,
	p: 2,
}

type Props = {
	isOpen: boolean
	setIsOpen: (newIsOpenValue: boolean) => void
	idToDelete: any
	submit: any
}

const DeleteUserDocumentModal = ({
	isOpen,
	setIsOpen,
	idToDelete,
	submit,
}: Props) => {
	const { t } = useTranslation()

	const handleClose = () => setIsOpen(false)

	const handleDelete = () => {
		setIsOpen(false)
		submit(idToDelete)
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<Box sx={style}>
						<Grid
							container
							paddingX={5}
							paddingY={5}
							direction={'column'}
						>
							<Typography
								variant={'h5'}
								textAlign="center"
								component={'h2'}
								sx={{ fontWeight: 500 }}
							>
								{t(
									'userDocuments.deleteUserDocumentModal.confirmDeletion'
								)}
							</Typography>
							<Box
								display="flex"
								sx={{
									flexDirection: 'row',
									justifyContent: 'center',
									gap: 1,
									mt: 4,
								}}
							>
								<Button
									variant="outlined"
									onClick={handleClose}
								>
									{t(
										'userDocuments.deleteUserDocumentModal.abort'
									)}
								</Button>
								<Button
									variant="contained"
									sx={{ background: '#902F2F' }}
									onClick={handleDelete}
								>
									{t(
										'userDocuments.deleteUserDocumentModal.delete'
									)}
								</Button>
							</Box>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default DeleteUserDocumentModal
