import {
	FormControlLabel,
	Grid,
	Switch,
	SxProps,
	Typography,
} from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

interface Props {
	label: string
	name: string
	comment?: JSX.Element | JSX.Element[]
	sx?: SxProps
}

const CustomSwitchInput = ({ label, name, comment, sx }: Props) => {
	const { t } = useTranslation()

	const [field] = useField({ name, type: 'checkbox' })

	return (
		<Box sx={{ ...sx, display: 'flex', flexDirection: 'column' }}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography fontWeight={500}>{label}</Typography>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={<Switch {...field} checked={field.value} />}
						label={
							<Typography fontWeight={300}>
								{field.value ? t('common.yes') : t('common.no')}
							</Typography>
						}
					/>
				</Grid>
			</Grid>

			{comment && <Box>{comment}</Box>}
		</Box>
	)
}

export default CustomSwitchInput
