import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import CustomDataGrid from '../../components/admin/CustomDataGrid'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { useQuery } from '@apollo/client'
import { dateComparator, normalizeDate } from '../../utils/helperFunctions'
import { GridColDef } from '@mui/x-data-grid'
import { GET_WORK_HISTORY_BY_COMPANY } from '../../graphql/queries/workPreps'
import { extractDate, extractTime } from '../../utils/helperFunctions'
import { AdminPanelItem } from '../../pages/admin/AdminPanel'
import { FireAlarmItem } from '../../pages/admin/FireAlarmPanel'
import { ServiceWorkItem } from '../../pages/admin/ServiceWorkPanel'

const WorkHistory = () => {
	const { t } = useTranslation()
	const { company } = useParams<{ company?: string }>()
	const { role, jwt } = useAuth()
	const [workPrepHistory, setWorkPrepHistory] = useState<AdminPanelItem[]>([])
	const [fireAlarmHistory, setFireAlarmHistory] = useState<FireAlarmItem[]>(
		[]
	)
	const [serviceWorkHistory, setServiceWorkHistory] = useState<
		ServiceWorkItem[]
	>([])

	const { loading, error, data } = useQuery(GET_WORK_HISTORY_BY_COMPANY, {
		variables: { company, jwt },
	})

	useEffect(() => {
		if (data) {
			console.log(JSON.parse(data.workHistoryByCompany))

			const history = JSON.parse(data.workHistoryByCompany)

			// Extract the workPrep data
			const workPreps = history.workPreps.map((workPrep: any) => {
				const {
					id,
					status,
					establishedDate,
					workSteps,
					relevantMall,
					creatorOfWorkPrep,
					workArea,
					floor,
					storeIdOrName,
					startDate,
					endDate,
					numPeople,
					hotWorkPermission,
					constructionWorkWillTakePlace,
					fireAlarmWillBeShutOff,
					ventilationWillBeShutOff,
					sprinklerSystemWillBeShutOff,
					insurance,
				} = workPrep

				return {
					id,
					status,
					establishedDate: extractDate(establishedDate),
					event: workSteps,
					relevantMall,
					createdBy: creatorOfWorkPrep,
					workArea,
					floor,
					storeIdOrName,
					startDate: extractDate(startDate),
					endDate: extractDate(endDate),
					startingTime: extractTime(startDate),
					endingTime: extractTime(endDate),
					numPeople,
					hotWork: hotWorkPermission.answer
						? t('workHistory.answerYes')
						: t('workHistory.answerNo'),
					fireAlarmWillBeShutOff: fireAlarmWillBeShutOff.answer
						? t('workHistory.answerYes')
						: t('workHistory.answerNo'),
					ventilationWillBeShutOff: ventilationWillBeShutOff.answer
						? t('workHistory.answerYes')
						: t('workHistory.answerNo'),
					sprinklerSystemWillBeShutOff:
						sprinklerSystemWillBeShutOff.answer
							? t('workHistory.answerYes')
							: t('workHistory.answerNo'),
					baseP: constructionWorkWillTakePlace.baseP
						? t('workHistory.answerYes')
						: t('workHistory.answerNo'),
					urwBaseU: constructionWorkWillTakePlace.urwBaseU
						? t('workHistory.answerYes')
						: t('workHistory.answerNo'),
					insurance: insurance.company ? insurance.company : '',
				}
			})

			setWorkPrepHistory(workPreps)

			// Extract the fireAlarm data
			const fireAlarms = history.fireAlarms.map((fireAlarm: any) => {
				const {
					id,
					status,
					establishedDate,
					fireAlarmOrHotWork,
					relevantMall,
					createdByCompany,
					workArea,
					startDate,
					endDate,
					startingTime,
					endingTime,
				} = fireAlarm

				return {
					id,
					fireAlarmOrHotWork:
						fireAlarmOrHotWork == 'fireAlarm'
							? t('fireAlarmDetails.fireAlarm')
							: fireAlarmOrHotWork == 'hotWork'
							? t('fireAlarmDetails.hotWork')
							: '-',
					status,
					establishedDate: extractDate(establishedDate),
					relevantMall,
					createdBy: createdByCompany,
					workArea,
					startDate: extractDate(startDate),
					endDate: extractDate(endDate),
					startingTime: extractTime(startDate),
					endingTime: extractTime(endDate),
				}
			})

			setFireAlarmHistory(fireAlarms)

			// Extract the serviceWork data
			const serviceWorks = history.serviceWorks.map(
				(serviceWork: any) => {
					const {
						id,
						status,
						establishedDate,
						workSteps,
						relevantMall,
						creatorOfWorkPrep,
						workArea,
						floor,
						storeIdOrName,
						startDate,
						endDate,
						numPeople,
						hotWorkPermission,
						constructionWorkWillTakePlace,
						fireAlarmWillBeShutOff,
						ventilationWillBeShutOff,
						sprinklerSystemWillBeShutOff,
						insurance,
					} = serviceWork

					return {
						id,
						status,
						establishedDate: extractDate(establishedDate),
						event: workSteps,
						relevantMall,
						createdBy: creatorOfWorkPrep,
						workArea,
						floor,
						storeIdOrName,
						startDate: extractDate(startDate),
						endDate: extractDate(endDate),
						startingTime: extractTime(startDate),
						endingTime: extractTime(endDate),
					}
				}
			)

			setServiceWorkHistory(serviceWorks)
		}
	}, [data])

	if (loading) return <LoadingSpinner />
	if (error) return <Typography>{t('errorOccurred')}</Typography>

	if (!role) return <NotLoggedIn />

	const workPrepColumns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 105,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'status',
			headerName: t('workPrep.status'),
			width: 125,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'establishedDate',
			headerName: t('workPrep.establishedDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'event',
			headerName: t('workPrep.event'),
			width: 150,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},

		{
			field: 'relevantMall',
			headerName: t('workPrep.relevantMall'),
			width: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'createdBy',
			headerName: t('workPrep.createdBy'),
			width: 150,
			editable: false,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'workArea',
			headerName: t('workPrep.workArea'),
			sortable: false,
			width: 140,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'floor',
			headerName: t('workPrep.floor'),
			sortable: false,
			width: 120,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'storeIdOrName',
			headerName: t('workPrep.storeIdOrName'),
			sortable: false,
			width: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startDate',
			headerName: t('workPrep.startDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'endDate',
			headerName: t('workPrep.endDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'startingTime',
			headerName: t('workPrep.startingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endingTime',
			headerName: t('workPrep.endingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'numPeople',
			headerName: t('workPrep.numPeople'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'hotWork',
			headerName: t('workPrep.hotWork'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'fireAlarmWillBeShutOff',
			headerName: t('workPrep.fireAlarmWillBeShutOff'),
			sortable: false,
			width: 175,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'ventilationWillBeShutOff',
			headerName: t('workPrep.ventilationWillBeShutOff'),
			sortable: false,
			width: 180,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'sprinklerSystemWillBeShutOff',
			headerName: t('workPrep.sprinklerSystemWillBeShutOff'),
			sortable: false,
			width: 220,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'urwBaseU',
			headerName: t('workPrep.urwBaseU'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'baseP',
			headerName: t('workPrep.baseP'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'insurance',
			headerName: t('workPrep.insurance'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
	]

	const fireAlarmColumns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 105,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'status',
			headerName: t('workPrep.status'),
			width: 125,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
		},
		{
			field: 'fireAlarmOrHotWork',
			headerName: t('fireAlarmPanel.type'),
			width: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'establishedDate',
			headerName: t('workPrep.establishedDate'),
			width: 100,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'relevantMall',
			headerName: t('workPrep.relevantMall'),
			width: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'createdBy',
			headerName: t('workPrep.createdBy'),
			width: 220,
			editable: false,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
		},
		{
			field: 'workArea',
			headerName: t('workPrep.workArea'),
			sortable: false,
			width: 140,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startDate',
			headerName: t('workPrep.startDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'endDate',
			headerName: t('workPrep.endDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'startingTime',
			headerName: t('workPrep.startingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endingTime',
			headerName: t('workPrep.endingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
	]

	const serviceWorkColumns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 105,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'status',
			headerName: t('workPrep.status'),
			width: 125,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'establishedDate',
			headerName: t('workPrep.establishedDate'),
			width: 100,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'relevantMall',
			headerName: t('workPrep.relevantMall'),
			width: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'createdBy',
			headerName: t('workPrep.createdBy'),
			width: 220,
			editable: false,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'workArea',
			headerName: t('workPrep.workArea'),
			sortable: false,
			width: 140,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startDate',
			headerName: t('workPrep.startDate'),
			sortComparator: dateComparator,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endDate',
			headerName: t('workPrep.endDate'),
			sortComparator: dateComparator,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startingTime',
			headerName: t('workPrep.startingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endingTime',
			headerName: t('workPrep.endingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
	]

	return (
		<Grid
			container
			direction="column"
			spacing={2}
			paddingX={{ xs: 1, sm: 5 }}
		>
			<Grid item marginTop={'50px'}>
				<Typography variant="h5" fontWeight={'500'}>
					{`${t('workHistory.title')} - ${company}`}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>{t('workHistory.helperText')}</Typography>
			</Grid>
			{/* Work prep table */}
			<Grid item marginTop={'50px'}>
				<Typography
					variant="h5"
					fontWeight={'500'}
					marginBottom={'10px'}
				>
					{t('workHistory.workPrepTitle')}
				</Typography>
				<Box>
					<CustomDataGrid
						type={'work-history'}
						data={workPrepHistory}
						columns={workPrepColumns}
						disableClick
					/>
				</Box>
			</Grid>
			{/* Fire alarm table */}
			<Grid item>
				<Typography
					variant="h5"
					fontWeight={'500'}
					marginBottom={'10px'}
				>
					{t('workHistory.fireAlarmTitle')}
				</Typography>
				<Box>
					<CustomDataGrid
						type={'work-history'}
						data={fireAlarmHistory}
						columns={fireAlarmColumns}
						disableClick
					/>
				</Box>
			</Grid>
			{/* Service work table */}
			<Grid item>
				<Typography
					variant="h5"
					fontWeight={'500'}
					marginBottom={'10px'}
				>
					{t('workHistory.serviceWorkTitle')}
				</Typography>
				<Box>
					<CustomDataGrid
						type={'work-history'}
						data={serviceWorkHistory}
						columns={serviceWorkColumns}
						disableClick
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export default WorkHistory
