import { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FieldDisplay from './FieldDisplay'

interface Props {
	fileURL: string[] | string
	title: string
	value: string
}

function FileUrlSingle({ fileURL, title, value }: Props) {
	const { t } = useTranslation()

	const [open, setOpen] = useState<boolean>(false)

	const updateOpenState = () => {
		setOpen(!open)
	}

	let inFile: string = fileURL.toString()

	if (['doc', 'docx'].includes(inFile.split('.').pop()!)) {
		//If doc or docx, iframe cant handle it on its own
		inFile = `https://docs.google.com/gview?url=${inFile}&embedded=true`
	}

	const fileArray: Array<string> = inFile.split(',')

	return (
		<Grid item xs={open ? 12 : 3} sx={{ minWidth: '250px' }}>
			<FieldDisplay title={title} value={value} />
			<Button variant="outlined" onClick={updateOpenState}>
				{open ? t('fileUrlSingle.hide') : t('fileUrlSingle.show')}
			</Button>
			{open
				? fileArray.map((file) => (
						<iframe
							key={file}
							src={file}
							style={{ width: '100%', aspectRatio: '1/1' }}
						></iframe>
				  ))
				: null}
		</Grid>
	)
}

export default FileUrlSingle
