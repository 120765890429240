import { Chip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
	name: string | null
	handleDelete: () => void
}

const UploadFileFieldName = ({ name, handleDelete }: Props) => {
	if (!name) {
		return null
	}

	return (
		<div>
			<Chip
				label={name}
				onDelete={handleDelete}
				deleteIcon={<DeleteIcon />}
				variant="outlined"
				sx={{ marginBottom: '5px' }}
			/>
		</div>
	)
}

export default UploadFileFieldName
