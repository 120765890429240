import { useMutation } from '@apollo/client'
import {
	SUBMIT_USER_DOCUMENT,
	DELETE_USER_DOCUMENT,
	UPDATE_USER_DOCUMENT_EXPIRY_DATE,
} from '../graphql/mutations/userDocuments'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button, Typography, Grid, Snackbar, Alert } from '@mui/material'
import { Add } from '@mui/icons-material'
import SubmitUserDocumentModal from '../components/common/SubmitUserDocumentModal'
import AdminMenu from '../components/admin/AdminMenu'
import UserDocumentsDataGrid from '../components/common/UserDocumentsDataGrid'
import DeleteUserDocumentModal from '../components/common/DeleteUserDocumentModal'
import { useAuth } from '../hooks/useAuth'
import { SnackbarData } from '../types'

const UserDocuments = () => {
	const { t } = useTranslation()
	const { jwt } = useAuth()

	const [submitDocumentIsOpen, setSubmitDocumentIsOpen] = useState(false)
	const [deleteDocumentIsOpen, setDeleteDocumentIsOpen] = useState(false)
	const [updateKey, setUpdateKey] = useState(0)
	const [idOfRow, setIdOfRow] = useState<string>('')

	const [submitUserDocument] = useMutation(SUBMIT_USER_DOCUMENT)
	const [deleteUserDocument] = useMutation(DELETE_USER_DOCUMENT)
	const [updateUserDocumentExpiryDate] = useMutation(
		UPDATE_USER_DOCUMENT_EXPIRY_DATE
	)

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})
	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const handleUpdate = () => {
		setUpdateKey(updateKey + 1)
	}

	const userDocumentSubmit = async (values: any) => {
		setSnackbarData({
			open: true,
			severity: 'info',
			message: t('userDocuments.uploadingDocument'),
		})
		const res = await submitUserDocument({
			variables: {
				documentFileName: values.fileName,
				documentType: values.documentType,
				documentPersonName: values.documentPersonName,
				userDocument: values.userDocument,
				jwt,
			},
		})
		setSnackbarData({
			message: t('userDocuments.documentUploaded'),
			severity: 'success',
			open: true,
		})
		handleUpdate()
	}

	const userDocumentDelete = async () => {
		setSnackbarData({
			message: t('userDocuments.deletingDocument'),
			severity: 'info',
			open: true,
		})
		const res = await deleteUserDocument({
			variables: {
				documentID: idOfRow,
			},
		})
		setSnackbarData({
			message: t('userDocuments.documentDeleted'),
			severity: 'success',
			open: true,
		})
		handleUpdate()
	}

	const userDocumentUpdate = async (idToBeUpdated: string) => {
		setSnackbarData({
			message: t('userDocuments.renewingDocument'),
			severity: 'info',
			open: true,
		})
		const res = await updateUserDocumentExpiryDate({
			variables: {
				documentID: idToBeUpdated,
			},
		})
		setSnackbarData({
			message: t('userDocuments.documentRenewed'),
			severity: 'success',
			open: true,
		})
		handleUpdate()
	}

	return (
		<>
			<AdminMenu>
				<Grid
					container
					direction="column"
					spacing={2}
					paddingX={{ xs: 1, sm: 5 }}
				>
					<Grid item marginTop={'50px'}>
						<Typography variant="h5" fontWeight={'500'}>
							{t('userDocuments.documentCollection')}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							justifyContent={'space-between'}
							columnGap={1}
							rowGap={1}
						>
							<Grid item flex={1}>
								<Typography minWidth={'300px'}>
									{t(
										'userDocuments.documentCollectionDescription'
									)}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									sx={{
										height: '50px',
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '220px',
										},
									}}
									variant="contained"
									startIcon={<Add />}
									onClick={() =>
										setSubmitDocumentIsOpen(
											!submitDocumentIsOpen
										)
									}
								>
									{t('userDocuments.uploadDocument')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container>
							<Grid item xs={12} height={750}>
								<UserDocumentsDataGrid
									updateKey={updateKey}
									setIdOfRow={setIdOfRow}
									setDeleteDocumentIsOpen={
										setDeleteDocumentIsOpen
									}
									userDocumentUpdate={userDocumentUpdate}
								></UserDocumentsDataGrid>
							</Grid>
						</Grid>
					</Grid>

					<SubmitUserDocumentModal
						isOpen={submitDocumentIsOpen}
						setIsOpen={setSubmitDocumentIsOpen}
						submit={userDocumentSubmit}
						setSnackbarData={setSnackbarData}
					></SubmitUserDocumentModal>
					<DeleteUserDocumentModal
						isOpen={deleteDocumentIsOpen}
						setIsOpen={setDeleteDocumentIsOpen}
						idToDelete={idOfRow}
						submit={userDocumentDelete}
					></DeleteUserDocumentModal>
				</Grid>
			</AdminMenu>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default UserDocuments
