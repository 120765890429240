export const zeroOrNumber = (inputNum: string) => {
	let numberToReturn: number = parseInt(inputNum)

	if (isNaN(numberToReturn)) {
		numberToReturn = 0
	}

	return numberToReturn
}

export const normalizeDate = (date: string | Date) => {
	return new Date(date).setHours(0, 0, 0, 0) // Set time to 00:00:00
}

export const dateComparator = (v1: any, v2: any) => {
	const date1 = new Date(v1)
	const date2 = new Date(v2)
	if (date1 < date2) return 1
	else if (date1 > date2) return -1
	else return 0
}

export const deepCopyObject: (obj: any) => any = (obj) => {
	return JSON.parse(JSON.stringify(obj))
}

export const getMallFromRole = (role: string | undefined) => {
	let mall =
		role === 'SuperAdmin' || role === 'Admin - Alla centrum'
			? 'all'
			: role?.split(' - ')[1]

	if (mall === 'MOS') {
		mall = 'Westfield Mall of Scandinavia'
	}
	return mall
}

const isSuperAdmin = (role: string | undefined) => {
	if (!role) {
		return false
	}
	const superAccess = role === 'SuperAdmin' || role === 'Admin - Alla centrum'

	return superAccess
}

export const isAuthorized = (
	role: string | undefined,
	mall: 'Mall of Scandinavia' | 'Nacka Forum' | 'Täby Centrum' | undefined
) => {
	if (isSuperAdmin(role)) {
		return true
	}

	const roleExist = role !== undefined

	const roleBelongsToMall = getMallFromRole(role) === mall

	return roleExist && roleBelongsToMall
}

export const allRoles = [
	'SuperAdmin',
	'Admin - Alla centrum',
	'Admin - Westfield Mall of Scandinavia',
	'Admin - Nacka Forum',
	'Admin - Täby Centrum',
	'Observer - MOS',
	'Observer - Nacka Forum',
	'Observer - Täby Centrum',
	'Drift - Westfield Mall of Scandinavia',
	'Drift - Nacka Forum',
	'Drift - Täby Centrum',
	'Upprättare',
	'projectManager',
]

export const displayRole = (role: string) => {
	const displayObj = {
		SuperAdmin: 'Superadmin',
		'Admin - Alla centrum': 'Admin - Alla centrum',
		'Admin - Westfield Mall of Scandinavia':
			'Admin - Westfield Mall of Scandinavia',
		'Admin - Nacka Forum': 'Admin - Nacka Forum',
		'Admin - Täby Centrum': 'Admin - Westfield Täby Centrum',
		'Observer - MOS': 'Vakt - Westfield Mall of Scandinavia',
		'Observer - Nacka Forum': 'Vakt - Nacka Forum',
		'Observer - Täby Centrum': 'Vakt - Westfield Täby Centrum',
		'Drift - Westfield Mall of Scandinavia':
			'Drift - Westfield Mall of Scandinavia',
		'Drift - Nacka Forum': 'Drift - Nacka Forum',
		'Drift - Täby Centrum': 'Drift - Westfield Täby Centrum',
		Upprättare: 'Upprättare av arbetsberedningar',
		projectManager: 'Projektledare',
	}

	if (role in displayObj) return displayObj[role as keyof typeof displayObj]

	return ''
}

//returns list over the roles which the input role is authorized over
export const roleIsAuthorizedOver = (role: string) => {
	const authorizedOver = {
		SuperAdmin: allRoles,
		'Admin - Alla centrum': allRoles.filter((e) => e != 'SuperAdmin'),
		'Admin - Westfield Mall of Scandinavia': [
			'Admin - Westfield Mall of Scandinavia',
			'Observer - MOS',
			'Drift - Westfield Mall of Scandinavia',
			'Upprättare',
			'projectManager',
		],
		'Admin - Nacka Forum': [
			'Admin - Nacka Forum',
			'Observer - Nacka Forum',
			'Drift - Nacka Forum',
			'Upprättare',
			'projectManager',
		],
		'Admin - Täby Centrum': [
			'Admin - Täby Centrum',
			'Observer - Täby Centrum',
			'Drift - Täby Centrum',
			'Upprättare',
			'projectManager',
		],
		'Observer - MOS': [],
		'Observer - Nacka Forum': [],
		'Observer - Täby Centrum': [],
		'Drift - Westfield Mall of Scandinavia': [],
		'Drift - Nacka Forum': [],
		'Drift - Täby Centrum': [],
		Upprättare: ['Upprättare'],
		projectManager: [],
	}

	return authorizedOver[role as keyof typeof authorizedOver]
}

export const roleHasAccessTo = (role: string | undefined) => {
	if (!role) return []

	const accessObj = {
		SuperAdmin: ['workPreps', 'fireAlarms', 'serviceWorks', 'users'],
		'Admin - Alla centrum': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Westfield Mall of Scandinavia': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Nacka Forum': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Täby Centrum': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Observer - MOS': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Observer - Nacka Forum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Observer - Täby Centrum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Drift - Westfield Mall of Scandinavia': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Drift - Nacka Forum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Drift - Täby Centrum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		Upprättare: ['workPreps', 'fireAlarms', 'serviceWorks', 'users'],
		projectManager: ['workPreps', 'fireAlarms'],
	}

	if (role in accessObj) return accessObj[role as keyof typeof accessObj]

	return []
}

export const extractDate = (dateString: string): string => {
	const date = new Date(dateString)
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0')
	return `${year}-${month}-${day}`
}

export const extractTime = (dateString: string): string => {
	const date = new Date(dateString)
	const hours = String(date.getHours()).padStart(2, '0')
	const minutes = String(date.getMinutes()).padStart(2, '0')
	return `${hours}:${minutes}`
}
