import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {
	Button,
	Typography,
	Grid,
	Divider,
	CircularProgress,
	MenuItem,
	Box,
	Alert,
	Snackbar,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import CustomTextInput from '../components/formik/CustomTextInput'
import { useMutation, useQuery } from '@apollo/client'
import {
	CHANGE_USER_INFORMATION,
	DELETE_USER,
} from '../graphql/mutations/users'
import { GET_USER_INFORMATION } from '../graphql/queries/users'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingSpinner from '../components/common/LoadingSpinner'
import ErrorOccurred from '../components/common/ErrorOccurred'
import { UserItem } from './admin/UsersPanel'
import BackButton from '../components/common/BackButton'
import CustomSelectInput from '../components/formik/CustomSelectInput'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteUserModal from '../components/admin/DeleteUserModal'
import { displayRole, roleIsAuthorizedOver } from '../utils/helperFunctions'
import { SnackbarData } from '../types'
import NotLoggedIn from '../components/common/NotLoggedIn'
import { useAuth } from '../hooks/useAuth'

const UserDetails = () => {
	const { t } = useTranslation()
	const params = useParams()
	const navigate = useNavigate()
	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: '',
		severity: 'success',
		open: false,
	})
	const [deleteUserIsOpen, setDeleteUserIsOpen] = useState<boolean>(false)
	const [userInfo, setUserInfo] = useState<UserItem>({
		email: '',
		name: '',
		role: '',
	})
	const [passwordResetSent, setPasswordResetSent] = useState<boolean>(false)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [changeUserInformation] = useMutation(CHANGE_USER_INFORMATION)
	const [deleteUser] = useMutation(DELETE_USER)
	const { forgotPassword, jwt, role } = useAuth()

	const resetPassword = async () => {
		setSnackbarData({
			message: t('login.forgotPasswordPage.sendingEmail'),
			severity: 'info',
			open: true,
		})
		//TODO handle error
		if (params.user) {
			await forgotPassword({ email: params.user })
		}
		setPasswordResetSent(true)
		setSnackbarData({
			message: t('login.forgotPasswordPage.emailSent'),
			severity: 'success',
			open: true,
		})
	}

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const { loading, error, data, refetch } = useQuery(GET_USER_INFORMATION, {
		variables: { user: params.user, jwt },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (data) {
			setUserInfo(JSON.parse(data.getUserInformation))
		}
	}, [data, loading])

	const handleSubmit = async (values: any, actions: any) => {
		const res = await changeUserInformation({
			variables: {
				user: values.email,
				name: values.name,
				role: values.role,
				jwt: jwt,
			},
		})
		actions.setSubmitting(false)
		setIsEditing(false)
		refetch()
	}

	const handleDeleteUser = async (user: string) => {
		//Only deletes user in database. Firebase Auth doesnt let you delete user who is not currently not logged in
		const res = await deleteUser({
			variables: {
				user: user,
				jwt: jwt,
			},
		})
		navigate('/user-panel')
	}

	if (!role) return <NotLoggedIn />

	const getMenuItems = () => {
		const authorizedOver = roleIsAuthorizedOver(role)
		const menuItems = authorizedOver.map((role) => (
			<MenuItem key={role} value={role}>
				{displayRole(role)}
			</MenuItem>
		))
		return menuItems
	}

	if (loading) return <LoadingSpinner />

	if (userInfo.name.length === 0) return <ErrorOccurred />

	return (
		<Grid container marginX={5} marginTop={'50px'} paddingBottom={'200px'}>
			{role !== 'Upprättare' && (
				<Grid item xs={12} marginBottom={'10px'}>
					<BackButton backToPath="/user-panel" />
				</Grid>
			)}
			<Grid item>
				<Formik
					initialValues={userInfo}
					onSubmit={handleSubmit}
					validationSchema={Yup.object({
						name: Yup.string().required(t('common.required')),
						role: Yup.string().required(t('common.required')),
					})}
				>
					{({ isSubmitting }) => (
						<Form id={'userDetails'}>
							<Grid container columnSpacing={4} rowSpacing={2}>
								<Grid item xs={12}>
									<Grid
										container
										justifyContent={'space-between'}
										columnGap={1}
									>
										<Grid item>
											<Typography
												variant={'h5'}
												component={'h2'}
												sx={{ fontWeight: 500, mb: 3 }}
											>
												{userInfo.email}
											</Typography>
										</Grid>
										<Grid item>
											{isEditing ? (
												<Box
													width={'361px'}
													display={'flex'}
													justifyContent={'flex-end'}
												>
													<Button
														variant="outlined"
														startIcon={
															<ClearIcon />
														}
														sx={{
															my: 2,
															marginX: 1,
														}}
														onClick={() =>
															setIsEditing(false)
														}
													>
														{t(
															'userSettings.cancel'
														)}
													</Button>
													<Button
														disabled={isSubmitting}
														type="submit"
														variant="contained"
														startIcon={<SaveIcon />}
														sx={{
															my: 2,
															marginX: 1,
															width: '100px',
														}}
													>
														{isSubmitting ? (
															<CircularProgress
																size={20}
															/>
														) : (
															t(
																'userSettings.save'
															)
														)}
													</Button>
												</Box>
											) : (
												<Box
													width={'361px'}
													display={'flex'}
													justifyContent={'flex-end'}
												>
													<Button
														variant="contained"
														startIcon={<Edit />}
														sx={{
															my: 2,
															marginX: 1,
														}}
														onClick={() =>
															setIsEditing(true)
														}
													>
														{t('userSettings.edit')}
													</Button>
													{role !== 'Upprättare' && (
														<Button
															variant="contained"
															color="error"
															startIcon={
																<DeleteIcon />
															}
															sx={{
																my: 2,
																marginX: 1,
															}}
															onClick={() =>
																setDeleteUserIsOpen(
																	true
																)
															}
														>
															{t(
																'userSettings.delete'
															)}
														</Button>
													)}
												</Box>
											)}
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Typography fontWeight={600}>
										{t('userSettings.name')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{isEditing ? (
										<CustomTextInput
											label={t('userSettings.name')}
											name="name"
											type="text"
											sx={{
												width: '100%',
												maxWidth: '400px',
												height: '50px',
											}}
										/>
									) : (
										<Typography>{userInfo.name}</Typography>
									)}
								</Grid>

								<Grid item xs={12}>
									<Typography fontWeight={600}>
										{t('userSettings.role')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{isEditing ? (
										<CustomSelectInput
											label={t('createUser.form.role')}
											name="role"
											sx={{
												width: '100%',
												maxWidth: '400px',
												height: '50px',
											}}
											disabled={role === 'Upprättare'}
										>
											{getMenuItems()}
										</CustomSelectInput>
									) : (
										<Typography>
											{displayRole(userInfo.role)}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Typography fontWeight={600}>
										{t('userSettings.password')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{isEditing ? (
										<>
											<Alert
												severity="warning"
												sx={{
													width: '100%',
													maxWidth: '400px',
												}}
											>
												{t(
													'userSettings.resetPasswordInfo'
												)}
											</Alert>
											<Button
												onClick={resetPassword}
												disabled={passwordResetSent}
												variant="contained"
												sx={{
													width: '100%',
													maxWidth: '400px',
													height: '50px',
													marginTop: '10px',
												}}
											>
												{t(
													'userSettings.resetPassword'
												)}
											</Button>
										</>
									) : (
										<Typography>
											{'●●●●●●●●●●●●●●●●'}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</Grid>
			<Snackbar
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{
						width: '100%',
					}}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
			<DeleteUserModal
				isOpen={deleteUserIsOpen}
				setIsOpen={setDeleteUserIsOpen}
				user={userInfo.email}
				submit={handleDeleteUser}
			></DeleteUserModal>
		</Grid>
	)
}

export default UserDetails
