import { PDFViewer } from '@react-pdf/renderer'
import { ServiceWorkAnswers } from '../../types'
import { Button } from '@mui/material'
import { SERVICE_WORK_BY_ID } from '../../graphql/queries/workPreps'
import { useNavigate, useParams } from 'react-router-dom'
import mosIcon from '../../resources/mos.png'
import nackaForumIcon from '../../resources/nackaForum.png'
import tabyCentrumIcon from '../../resources/tabyCentrum.png'
import { useQuery } from '@apollo/client'
import ServiceWorkPDF from '../../components/admin/pdf/ServiceWorkPDF'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import { useAuth } from '../../hooks/useAuth'

export const mallToImg = {
	'Nacka Forum': nackaForumIcon,
	'Mall of Scandinavia': mosIcon,
	'Täby Centrum': tabyCentrumIcon,
	'Westfield Mall of Scandinavia': mosIcon,
}
interface Props {
	customLang: 'sv' | 'en'
}

const OpenServiceWorkPDF = ({ customLang }: Props) => {
	const navigate = useNavigate()
	const params = useParams()

	const { role, jwt } = useAuth()

	const { loading, data } = useQuery(SERVICE_WORK_BY_ID, {
		variables: { serviceWorkId: params.serviceWorkId, jwt },
	})

	if (loading && !data) return <LoadingSpinner />

	const currentServiceWork: ServiceWorkAnswers = JSON.parse(
		data.serviceWorkById
	)

	if (role === null) return <NotLoggedIn />

	return (
		<div className="flex flex-col h-screen">
			<Button
				onClick={() =>
					navigate(`/service-work-panel/${currentServiceWork.id}`)
				}
				style={{ height: '35px', borderRadius: 0 }}
				variant="contained"
			>
				{{ sv: 'Tillbaka', en: 'Back' }[customLang]}
			</Button>

			<div className="h-[calc(100%-35px)]">
				<PDFViewer style={{ flex: 1, width: '100%', height: '100%' }}>
					<ServiceWorkPDF
						currentServiceWork={currentServiceWork}
						customLang={customLang}
					/>
				</PDFViewer>
			</div>
		</div>
	)
}

export default OpenServiceWorkPDF
