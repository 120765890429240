import { gql } from '@apollo/client'

export const GET_USERS = gql`
	query getUsers($jwt: String!) {
		getUsers(jwt: $jwt)
	}
`

export const GET_USER_INFORMATION = gql`
	query getUserInformation($user: String!, $jwt: String!) {
		getUserInformation(user: $user, jwt: $jwt)
	}
`
