import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

const NotLoggedIn = () => {
	return (
		<div className="text-center">
			<p>
				<Trans i18nKey="common.notLoggedInMsg">
					You have to <Link to="/sign-in">sign in</Link> first!
				</Trans>
			</p>
		</div>
	)
}

export default NotLoggedIn
