import { Box, SxProps, Typography, FormHelperText } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { useState, SetStateAction } from 'react'
import FileInput from '../admin/FileInput'
import UploadFileFieldName from '../common/UploadFileFieldName'
import { SnackbarData } from '../../types'

interface Props {
	sx?: SxProps
	titleWeight?: number
	titleSize?: number
	translationLanguage?: string
	formAttribute: string
	formValues: any
	errorMessage?: any | undefined
	setSnackbar: React.Dispatch<SetStateAction<SnackbarData>>
}

const CustomFileInputWithTitle = ({
	sx,
	titleWeight = 400,
	titleSize = 14,
	formValues,
	formAttribute,
	translationLanguage,
	setSnackbar,
}: Props) => {
	const { t } = useTranslation()
	const [field, meta] = useField(formAttribute)
	const [rerenderValue, setRerenderValue] = useState(true)

	const initSx: SxProps = {}
	//https://www.youtube.com/watch?v=EUIRvQbkf0Y

	const rerender = () => {
		setRerenderValue(!rerenderValue)
	}

	const errorText: () => JSX.Element | null = () => {
		if (meta.touched && meta.error) {
			return <FormHelperText error={true}>{meta.error}</FormHelperText>
		} else {
			return null
		}
	}

	const handleInputChange: (e: any) => void = (e) => {
		if (e.target.files !== null) {
			if (e.target.files.length > 0) {
				if (e.target.files[0].type !== 'application/pdf') {
					setSnackbar({
						open: true,
						severity: 'error',
						message: t('workPrep.pdfTooltip'),
					})
					return
				}
				//Maximum filesize for assently is 15mb, so we hardcap all PDF at 15mb
				if (e.target.files[0].size > 15000000) {
					setSnackbar({
						open: true,
						severity: 'error',
						message: t('workPrep.pdfSizeLimit'),
					})
					return
				}
				if (Array.isArray(formValues[formAttribute])) {
					formValues[formAttribute].push(e.target.files[0])
				} else {
					formValues[formAttribute] = e.target.files[0]
				}
			}
			field.onChange(e)
		}
	}
	const createNameComponentsFromMultipleFiles: (
		fileNameComponents: JSX.Element[],
		attribute: string,
		filesArray: File[]
	) => JSX.Element[] = (fileNameComponents, attribute, filesArray) => {
		if (filesArray.length > 0) {
			const namesArrComponents = filesArray.map(
				(f: File, index: number) => {
					return (
						<UploadFileFieldName
							key={f.name}
							name={f.name}
							handleDelete={() =>
								deleteMultiFileUpload(attribute, index)
							}
						/>
					)
				}
			)
			return namesArrComponents
		} else {
			return []
		}
	}

	const deleteSingelFileUpload: (attribute: string) => void = (attribute) => {
		formValues[attribute] = null
		rerender()
	}

	const deleteMultiFileUpload: (attribute: string, index: number) => void = (
		attribute,
		index
	) => {
		const filesArray = formValues[attribute]
		filesArray.splice(index, 1)
		formValues[attribute] = filesArray
		rerender()
	}

	const getFileNameComponents: (formValues: any) => any = (formValues) => {
		let fileNameComponents: JSX.Element[] = []
		if (formValues === null) {
			return fileNameComponents
		}
		let possibleName = null
		if (Array.isArray(formValues[formAttribute])) {
			fileNameComponents = createNameComponentsFromMultipleFiles(
				fileNameComponents,
				formAttribute,
				formValues[formAttribute]
			)
		} else {
			possibleName = formValues[formAttribute]?.name
		}

		if (possibleName) {
			fileNameComponents.push(
				<UploadFileFieldName
					key={possibleName}
					name={possibleName}
					handleDelete={() => deleteSingelFileUpload(formAttribute)}
				/>
			)
		}
		return fileNameComponents
	}

	return (
		<Box marginBottom={'10px'} sx={{ ...initSx, ...sx }}>
			{translationLanguage && (
				<Typography
					marginTop={'15px'}
					pb={2}
					fontWeight={titleWeight}
					fontSize={titleSize}
				>
					<Trans i18nKey={translationLanguage} />
				</Typography>
			)}
			<FileInput
				fileNameComponents={getFileNameComponents(formValues)}
				file={formAttribute}
				onFileChange={handleInputChange}
			></FileInput>
			{errorText()}
		</Box>
	)
}

export default CustomFileInputWithTitle
