import { gql } from '@apollo/client'

export const CHANGE_USER_INFORMATION = gql`
	mutation changeUserInformation(
		$user: String!
		$name: String!
		$role: String!
		$jwt: String!
	) {
		changeUserInformation(user: $user, name: $name, role: $role, jwt: $jwt)
	}
`

export const DELETE_USER = gql`
	mutation deleteUser($user: String!, $jwt: String!) {
		deleteUser(user: $user, jwt: $jwt)
	}
`

export const UPDATE_PREFERRED_MALL = gql`
	mutation updatePreferredMall($jwt: String!, $mall: String!) {
		updatePreferredMall(jwt: $jwt, mall: $mall)
	}
`
