import HomeLayout from '../../components/common/HomeLayout'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import CustomTextInput from '../../components/formik/CustomTextInput'
import { CircularProgress } from '@mui/material'
import { useAuth } from '../../hooks/useAuth'
import { LocationState } from '../../types'

interface FormValues {
	email: string
	password: string
}

const SignIn = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const location = useLocation()
	const state = location.state as LocationState

	const [errorText, setErrorText] = useState<string | null>(null)

	const { login } = useAuth()

	const initialFormikValues = {
		email: '',
		password: '',
	}

	const validationSchema = Yup.object({
		email: Yup.string()
			.email(t('common.invalidEmailAddress'))
			.required(t('common.required')),
		password: Yup.string().required(t('common.required')),
	})

	const forgotPasswordPage = () => {
		navigate('/forgot-password')
	}

	const navigateToCorrectPage = () => {
		if (state) {
			if (state.isFromCompleteRequest && state.navigationObject) {
				const navigationObject = state.navigationObject
				const redirectUrl = `/admin-panel/editWorkPrep/${navigationObject.workPrepId}`
				navigate(redirectUrl, {
					state: {
						isFromCompleteRequest: true,
						navigationObject: navigationObject,
					},
				})
				return
			}
		}
		navigate('/')
	}

	const handleLogin = async (
		values: FormValues,
		helpers: FormikHelpers<FormValues>
	) => {
		setErrorText(null)

		const { error } = await login({
			email: values.email.toLowerCase(),
			password: values.password,
		})

		if (error) {
			const errorMsg = error.message.split(': ')[1]
			if (
				errorMsg === 'Error (auth/user-not-found).' ||
				errorMsg === 'Error (auth/wrong-password).'
			) {
				setErrorText(t('login.wrongCredentials'))
				helpers.resetForm()
			} else {
				setErrorText(t('login.somethingWentWrong'))
			}
		} else {
			helpers.setSubmitting(false)
			navigateToCorrectPage()
		}
	}

	return (
		<HomeLayout>
			<Grid
				width={'100%'}
				maxWidth={'500px'}
				container
				direction={'column'}
				spacing={2}
			>
				<Grid item>
					<Typography component="h1" variant="h5" sx={{ mb: '10px' }}>
						{t('login.login')}
					</Typography>
				</Grid>
				<Grid item>
					<Formik
						initialValues={initialFormikValues}
						validationSchema={validationSchema}
						onSubmit={handleLogin}
					>
						{({ isSubmitting }) => (
							<Form>
								<CustomTextInput
									label={t('login.email')}
									name="email"
								/>
								<CustomTextInput
									label={t('login.password')}
									name="password"
									type="password"
								/>

								{errorText && (
									<Typography
										sx={{
											color: '#d32f2f',
											fontSize: '14px',
											mb: '10px',
										}}
									>
										{errorText}
									</Typography>
								)}

								<Button
									disabled={isSubmitting}
									type="submit"
									variant="contained"
									sx={{ width: '100%', height: '50px' }}
									endIcon={
										isSubmitting && (
											<CircularProgress size={10} />
										)
									}
								>
									{t('login.login')}
								</Button>
							</Form>
						)}
					</Formik>
				</Grid>
				<Grid item>
					<Link
						component={'button'}
						onClick={forgotPasswordPage}
						variant="body2"
					>
						{t('login.forgotPassword')}
					</Link>
				</Grid>
			</Grid>
		</HomeLayout>
	)
}

export default SignIn
