import { gql } from '@apollo/client'

export const SUBMIT_USER_DOCUMENT = gql`
	mutation SubmitUserDocument(
		$documentFileName: String!
		$documentType: String!
		$documentPersonName: String!
		$userDocument: [Upload]!
		$jwt: String!
	) {
		submitUserDocument(
			documentFileName: $documentFileName
			documentType: $documentType
			documentPersonName: $documentPersonName
			userDocument: $userDocument
			jwt: $jwt
		)
	}
`

export const UPDATE_USER_DOCUMENT_EXPIRY_DATE = gql`
	mutation UpdateUserDocumentExpiryDate($documentID: String!) {
		updateUserDocumentExpiryDate(documentID: $documentID)
	}
`

export const DELETE_USER_DOCUMENT = gql`
	mutation DeleteUserDocument($documentID: String!) {
		deleteUserDocument(documentID: $documentID)
	}
`
