import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import {
	getFirestore,
	collection,
	addDoc,
	doc,
	setDoc,
	getDoc,
} from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseConfig'

const app = initializeApp(firebaseConfig)

//Secondary app is created to avoid logging in when creating new account
const secondaryApp = initializeApp(firebaseConfig, 'Secondary')
const secondaryAppAuth = getAuth(secondaryApp)

const db = getFirestore()
const auth = getAuth(app)

export {
	db,
	auth,
	collection,
	addDoc,
	doc,
	setDoc,
	getDoc,
	getAnalytics,
	secondaryAppAuth,
}
export default app
