interface Props {
	children: JSX.Element
}

const Layout = ({ children }: Props) => {
	return (
		<div className="w-full px-[5%] 1xl:px-[1.5rem] mx-auto py-[1.5rem] min-h-screen max-w-6xl">
			{children}
		</div>
	)
}

export default Layout
