import { Grid, MenuItem, Typography, Link, FormGroup } from '@mui/material'
import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import Bullet from '../common/Bullet'
import CustomDivider from '../common/CustomDivider'
import InfoText from '../common/InfoText'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import CustomDateInput from '../formik/CustomDateInput'
import CustomFileInputWithTitle from '../formik/CustomFileInputWithTitle'
import CustomInfoField from '../formik/CustomInfoField'
import CustomSelectInput from '../formik/CustomSelectInput'
import CustomSwitchInput from '../formik/CustomSwitchInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'
import CustomTextInput from '../formik/CustomTextInput'
import CustomTimeInput from '../formik/CustomTimeInput'
import CustomSelectFile from '../formik/CustomSelectFile'

interface Props {
	setSnackbarData: any
	isCreatedLoggedIn: boolean
}

const WorkForm = ({ setSnackbarData, isCreatedLoggedIn }: Props) => {
	const { values } = useFormikContext<any>()
	const { t } = useTranslation()

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.workSteps')}
					name="workSteps"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<CustomTextInput
					label={t('workPrep.workArea')}
					name="workArea"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomSelectInput
					label={t('workPrep.relevantMall')}
					name="relevantMall"
					sx={{ width: '100%' }}
				>
					<MenuItem value="Westfield Mall of Scandinavia">
						Westfield Mall of Scandinavia
					</MenuItem>
					<MenuItem value="Nacka Forum">Nacka Forum</MenuItem>
					<MenuItem value="Täby Centrum">
						Westfield Täby Centrum
					</MenuItem>
				</CustomSelectInput>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.numPeople')}
					name="numPeople"
					type="text"
					inputMode="numeric"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.floor')}
					name="floor"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.storeIdOrName')}
					name="storeIdOrName"
					type="text"
				/>
			</Grid>
			<CustomDivider />
			<Grid item xs={12} sm={4}>
				<CustomDateInput
					label={t('workPrep.startDate')}
					name="startDate"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomDateInput label={t('workPrep.endDate')} name="endDate" />
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.startingTime')}
					name="startingTime"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.endingTime')}
					name="endingTime"
				/>
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<CustomDivider />
			<Grid item xs={12}>
				<Typography marginBottom={'30px'} fontWeight={500}>
					{t('workPrep.insuranceExistsQuestion')}
				</Typography>
				<Grid container columnSpacing={4} rowSpacing={1}>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('workPrep.insuranceCompanyNameQuestion')}
							name="insuranceCompanyName"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t(
								'workPrep.insuranceContractNumberQuestion'
							)}
							name="insuranceContractNumber"
							type="text"
						/>
					</Grid>
				</Grid>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<Typography fontWeight={500}>
					{t('workPrep.scheduleForTheProject')}
				</Typography>

				<CustomFileInputWithTitle
					translationLanguage={
						'workPrep.attachScheduleForTheProjectComment'
					}
					formAttribute={'scheduleForTheProject'}
					formValues={values}
					setSnackbar={setSnackbarData}
				/>
			</Grid>
			<CustomDivider />
			{/* Project client */}
			<Grid item xs={12}>
				<Typography fontWeight={500} marginBottom={'30px'}>
					{t('workPrep.clientTitle')}
				</Typography>

				<Grid container columnSpacing={4} rowSpacing={1}>
					<Grid item xs={12} sm={4}>
						<CustomTextInput
							label={t('workPrep.clientName')}
							name="clientName"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<CustomTextInput
							label={t('workPrep.clientTelephone')}
							name="clientTelephone"
							type="text"
							inputMode="tel"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<CustomTextInput
							label={t('workPrep.clientEmail')}
							name="clientEmail"
							type="text"
							inputMode="email"
						/>
					</Grid>
				</Grid>
			</Grid>
			<CustomDivider />
			{/* Project manager */}
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.projectManagerExistsQuestion')}
					name="projectManagerExists"
					sx={{ mb: '20px' }}
				/>
				{values.projectManagerExists && (
					<Grid container columnSpacing={4} rowSpacing={1}>
						<Grid item xs={12} sm={4}>
							<CustomTextInput
								label={t('workPrep.nameToProjectManager')}
								name="nameToProjectManager"
								type="text"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<CustomTextInput
								label={t('workPrep.telephoneToProjectManager')}
								name="telephoneToProjectManager"
								type="text"
								inputMode="tel"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<CustomTextInput
								label={t('workPrep.emailToProjectManager')}
								name="emailToProjectManager"
								type="text"
								inputMode="email"
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			<CustomDivider />

			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.workWithWaterWillBeDoneQuestion')}
					name="workWithWaterWillBeDone"
				/>
				{values.workWithWaterWillBeDone && (
					<>
						{isCreatedLoggedIn ? (
							<>
								<Typography fontSize={14}>
									{t('workPrep.isLoggedInFileInfo')}
								</Typography>
								<CustomSelectFile
									translationLanguage={
										'workPrep.chooseWaterCertificate'
									}
									formAttribute={'workWithWaterCertificate'}
									formValues={values}
								/>
								<CustomFileInputWithTitle
									formAttribute={'workWithWaterCertificate'}
									formValues={values}
									setSnackbar={setSnackbarData}
								/>
							</>
						) : (
							<CustomFileInputWithTitle
								translationLanguage={
									'workPrep.workWithWaterCertificateComment'
								}
								formAttribute={'workWithWaterCertificate'}
								formValues={values}
								setSnackbar={setSnackbarData}
							/>
						)}
					</>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.safeWaterComment">
						Here you can read more about the
						<a
							href="https://www.sakervatten.se"
							rel="noreferrer"
							target="_blank"
						>
							safe water certificate
						</a>
					</Trans>
				</Typography>
			</Grid>
			<CustomDivider />

			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.electricalWorkWillBeDoneQuestion')}
					name="electricalWorkWillBeDone"
				/>
				{values.electricalWorkWillBeDone && (
					<>
						{isCreatedLoggedIn ? (
							<>
								<Typography fontSize={14}>
									{t('workPrep.isLoggedInFileInfo')}
								</Typography>
								<CustomSelectFile
									translationLanguage={
										'workPrep.chooseElectricalWorkCertificate'
									}
									formAttribute={'electricalWorkCertificate'}
									formValues={values}
								/>
								<CustomFileInputWithTitle
									formAttribute={'electricalWorkCertificate'}
									formValues={values}
									setSnackbar={setSnackbarData}
								/>
							</>
						) : (
							<CustomFileInputWithTitle
								translationLanguage={
									'workPrep.electricalWorkCertificateComment'
								}
								formAttribute={'electricalWorkCertificate'}
								formValues={values}
								setSnackbar={setSnackbarData}
							/>
						)}
					</>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.electricalWorkWillBeDoneComment">
						Here you can get the
						<a
							href="https://e-tjanster.elsakerhetsverket.se/foretag/kolla-elforetaget"
							rel="noreferrer"
							target="_blank"
						>
							certificate for the electrical installer from the
							Swedish Electrical Safety Authority
						</a>
					</Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.constructionWorkWillTakePlaceQuestion')}
					name="constructionWorkWillTakePlace"
					sx={{ mb: '0px' }}
				/>
				{values.constructionWorkWillTakePlace && (
					<CustomFileInputWithTitle
						translationLanguage={
							'workPrep.attachConstructionEnvironmentWorkPlanComment'
						}
						formAttribute={'constructionEnvironmentWorkPlan'}
						formValues={values}
						setSnackbar={setSnackbarData}
					/>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.constructionWorkWillTakePlaceComment">
						What counts as
						<a
							href="https://www.av.se/produktion-industri-och-logistik/bygg/"
							rel="noreferrer"
							target="_blank"
						>
							construction work?
						</a>
						<br />
						<a
							href="https://www.av.se/produktion-industri-och-logistik/bygg/arbetsmiljoplan/"
							rel="noreferrer"
							target="_blank"
						>
							Here you will find more information about a work
							environment plan
						</a>
					</Trans>
				</Typography>
				{values.constructionWorkWillTakePlace && (
					<>
						{/* URW BAS-U */}
						<CustomSwitchInput
							label={t('workPrep.urwBaseUExistsQuestion')}
							name="baseUAndBasePExists"
						/>
						{values.baseUAndBasePExists && (
							<Grid container columnSpacing={4} rowSpacing={1}>
								<Grid item xs={12}>
									{isCreatedLoggedIn ? (
										<>
											<Typography fontSize={14}>
												{t(
													'workPrep.isLoggedInFileInfo'
												)}
											</Typography>
											<CustomSelectFile
												translationLanguage={
													'workPrep.chooseBaseUCertificate'
												}
												formAttribute={
													'baseUCertificate'
												}
												formValues={values}
											/>
											<CustomFileInputWithTitle
												formAttribute={
													'baseUCertificate'
												}
												formValues={values}
												setSnackbar={setSnackbarData}
											/>
										</>
									) : (
										<CustomFileInputWithTitle
											translationLanguage={
												'workPrep.baseUCertificateComment'
											}
											formAttribute={'baseUCertificate'}
											formValues={values}
											setSnackbar={setSnackbarData}
										/>
									)}
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.nameToURWBaseU')}
										name="nameToURWBaseU"
										type="text"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t(
											'workPrep.telephoneToURWBaseU'
										)}
										name="telephoneToURWBaseU"
										type="text"
										inputMode="tel"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.emailToURWBaseU')}
										name="emailToURWBaseU"
										type="text"
										inputMode="email"
									/>
								</Grid>
							</Grid>
						)}
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.urwBaseUExistsComment">
								The work preparation applies together with URW´s
								guidelines for working environment with
								associated rules of procedure and protection,
								these must be available to the executor.
								<br />
								<a
									href="https://www.av.se/produktion-industri-och-logistik/bygg/ansvar-vid-byggnads--och-anlaggningsarbete/byggarbetsmiljosamordnare/"
									rel="noreferrer"
									target="_blank"
								>
									Here you will find more information about
									BAS-U/P
								</a>
							</Trans>
						</Typography>

						{/* BAS-P */}
						<CustomSwitchInput
							label={t('workPrep.basePExistsQuestion')}
							name="baseUAndBasePExists"
						/>
						{values.baseUAndBasePExists && (
							<Grid container columnSpacing={4} rowSpacing={1}>
								<Grid item xs={12}>
									{isCreatedLoggedIn ? (
										<>
											<Typography fontSize={14}>
												{t(
													'workPrep.isLoggedInFileInfo'
												)}
											</Typography>
											<CustomSelectFile
												translationLanguage={
													'workPrep.chooseBasePCertificate'
												}
												formAttribute={
													'basePCertificate'
												}
												formValues={values}
											/>
											<CustomFileInputWithTitle
												formAttribute={
													'basePCertificate'
												}
												formValues={values}
												setSnackbar={setSnackbarData}
											/>
										</>
									) : (
										<CustomFileInputWithTitle
											translationLanguage={
												'workPrep.basePCertificateComment'
											}
											formAttribute={'basePCertificate'}
											formValues={values}
											setSnackbar={setSnackbarData}
										/>
									)}
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.nameToBaseP')}
										name="nameToBaseP"
										type="text"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.telephoneToBaseP')}
										name="telephoneToBaseP"
										type="text"
										inputMode="tel"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.emailToBaseP')}
										name="emailToBaseP"
										type="text"
										inputMode="email"
									/>
								</Grid>
							</Grid>
						)}
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.urwBaseUExistsComment">
								The work preparation applies together with URW´s
								guidelines for working environment with
								associated rules of procedure and protection,
								these must be available to the executor.
								<br />
								<a
									href="https://www.av.se/produktion-industri-och-logistik/bygg/ansvar-vid-byggnads--och-anlaggningsarbete/byggarbetsmiljosamordnare/"
									rel="noreferrer"
									target="_blank"
								>
									Here you will find more information about
									BAS-U/P
								</a>
							</Trans>
						</Typography>
					</>
				)}
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t(
						'workPrep.willTheWorkTakeLongerThan30DaysQuestion'
					)}
					name="willTheWorkTakeLongerThan30Days"
				/>
				{values.willTheWorkTakeLongerThan30Days && (
					<CustomFileInputWithTitle
						translationLanguage={
							'workPrep.willTheWorkTakeLongerThan30DaysCertificateComment'
						}
						formAttribute={
							'willTheWorkTakeLongerThan30DaysCertificate'
						}
						formValues={values}
						setSnackbar={setSnackbarData}
					/>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.willTheWorkTakeLongerThan30DaysComment">
						Here you will find more information about
						<a
							href="https://www.av.se/produktion-industri-och-logistik/bygg/forhandsanmalan-av-byggarbetsplats/"
							rel="noreferrer"
							target="_blank"
						>
							advance notification of construction sites
						</a>
					</Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomInfoField
					title={t('workPrep.followUpInfoBric')}
					comment={t('workPrep.onlyBiggerContracts')}
				>
					<Typography fontWeight={300} fontSize={14}>
						{t('workPrep.registrationOfProject') + ' '}
					</Typography>
					<p>&nbsp;</p>
					<Link
						target="_blank"
						href="https://ca-11293.startdeliver.io/project-form?verificationToken=9e7ee0b57660e598431539dad6c865d7"
						underline="hover"
						fontWeight={300}
						fontSize={14}
						color={'normal'}
					>
						{t('common.here')}
					</Link>
				</CustomInfoField>
			</Grid>
			<CustomDivider />

			<Grid item xs={12} sm={6}>
				<FormGroup>
					<Typography fontWeight={500}>
						{t('workPrep.certainRisks.question')}
					</Typography>

					<Typography fontWeight={300} fontSize={14}>
						{t('workPrep.certainRisks.comment')}
					</Typography>

					<CustomCheckboxInput
						label={t('workPrep.certainRisks.fallRisk')}
						name="fallRisk"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.certainRisks.hazardousChemOrBioSubstances'
						)}
						name="hazardousChemOrBioSubstances"
					/>

					<CustomCheckboxInput
						label={t('workPrep.certainRisks.heavyLifts')}
						name="heavyLifts"
					/>

					<CustomCheckboxInput
						label={t('workPrep.certainRisks.passingVehicleTraffic')}
						name="passingVehicleTraffic"
					/>

					<CustomCheckboxInput
						label={t('workPrep.certainRisks.dust')}
						name="dust"
					/>

					<CustomCheckboxInput
						label={t('workPrep.certainRisks.vibrationsOrnoise')}
						name="vibrationsOrnoise"
					/>

					<CustomCheckboxInput
						label={t('workPrep.certainRisks.fireHazard')}
						name="fireHazard"
					/>

					<CustomTextInput
						label={t('workPrep.certainRisks.other')}
						name="otherCertainRisk"
						type="text"
						sx={{ maxWidth: '300px' }}
					/>
				</FormGroup>
			</Grid>
			<Grid item xs={12} sm={6}>
				<FormGroup>
					<Typography fontWeight={500}>
						{t('workPrep.areaWhereWorkWillBePerformed.question')}
					</Typography>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.restaurant'
						)}
						name="restaurant"
					/>

					<CustomCheckboxInput
						label={t('workPrep.areaWhereWorkWillBePerformed.store')}
						name="store"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.shoppingMallAisle'
						)}
						name="shoppingMallAisle"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.garage'
						)}
						name="garage"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.technicalSpace'
						)}
						name="technicalSpace"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.serviceCorridor'
						)}
						name="serviceCorridor"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.workOutsideFacade'
						)}
						name="workOutsideFacade"
					/>

					<CustomCheckboxInput
						label={t(
							'workPrep.areaWhereWorkWillBePerformed.squareOrEntrance'
						)}
						name="squareOrEntrance"
					/>
				</FormGroup>
			</Grid>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.measureForWorkEnvironmentRisks')}
					name="measureForWorkEnvironmentRisks"
				/>

				<Typography fontWeight={300} fontSize={14} sx={{ mt: '-20px' }}>
					{t('workPrep.measureForWorkEnvironmentRisksComment')}
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						my: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('workPrep.infoBox2.title')}
					</Typography>
					<ul className="ml-4">
						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet1')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet2')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet3')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet4')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet5')}
							</Typography>
						</Bullet>
					</ul>
				</InfoText>
			</Grid>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.describeWorkSteps')}
					name="describeWorkSteps"
				/>

				<Typography fontWeight={300} fontSize={14} sx={{ mt: '-20px' }}>
					{t('workPrep.describeWorkStepsComment')}
				</Typography>
			</Grid>
			<CustomDivider />
		</Grid>
	)
}

export default WorkForm
