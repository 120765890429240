import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from '@react-pdf/renderer'
import RobotoThinFont from '../../../resources/fonts/Roboto-Thin.ttf'
import RobotoLightFont from '../../../resources/fonts/Roboto-Light.ttf'
import RobotoRegularFont from '../../../resources/fonts/Roboto-Regular.ttf'
import RobotoBoldFont from '../../../resources/fonts/Roboto-Bold.ttf'
import { ServiceWorkAnswers } from '../../../types'
import { mallToImg } from '../../../pages/admin/WorkPrepPDF'
import { useTranslation } from 'react-i18next'

Font.register({
	family: 'RobotoThin',
	src: RobotoThinFont,
})

Font.register({
	family: 'RobotoLight',
	src: RobotoLightFont,
})

Font.register({
	family: 'RobotoRegular',
	src: RobotoRegularFont,
})

Font.register({
	family: 'RobotoBold',
	src: RobotoBoldFont,
})

Font.registerHyphenationCallback((word) => [word])

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		margin: '43px',
		fontSize: 14,
		fontFamily: 'RobotoLight',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		paddingRight: '27px',
		paddingLeft: '43px',
		marginLeft: '-43px',
		marginTop: '10px',
	},
	fieldContainer: {
		display: 'flex',
		flex: '1 1 200px',
		flexDirection: 'column',
		marginRight: '15px',
	},
	fieldHeader: {
		fontFamily: 'RobotoBold',
		fontSize: 10,
	},
	fieldValue: {
		marginTop: '5px',
		fontSize: 10,
		lineHeight: 1.65,
	},
})

interface Props {
	currentServiceWork: ServiceWorkAnswers
	customLang?: 'sv' | 'en'
}

const ServiceWorkPDF = ({ currentServiceWork, customLang }: Props) => {
	const { t, i18n } = useTranslation()

	let lang: 'sv' | 'en'

	if (customLang) {
		lang = customLang
	} else {
		lang = i18n.resolvedLanguage as 'sv' | 'en'
	}

	const prettyDate = (dateString: Date) => {
		return new Date(dateString).toLocaleDateString(
			{ sv: 'sv-SE', en: 'en-US' }[lang]
		)
	}

	return (
		<Document title={`Servicejobb - ${currentServiceWork.id}`}>
			<Page size="A4" style={styles.page}>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						width: '100%',
						paddingRight: '43px',
						paddingLeft: '43px',
						marginLeft: '-43px',
					}}
				>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<View>
							<Text
								style={{
									fontFamily: 'RobotoLight',
									fontSize: 20,
									marginRight: '4px',
								}}
							>
								{t('serviceWork.title')}
							</Text>
						</View>
						{currentServiceWork.status ? (
							<View>
								<Text
									style={{
										fontFamily: 'RobotoBold',
										fontSize: 17,
										paddingTop: '3px',
										color: '#475569',
									}}
								>
									ID: {currentServiceWork.id}
								</Text>
							</View>
						) : null}
					</View>

					<Image
						src={mallToImg[currentServiceWork.relevantMall]}
						style={{ height: '30px' }}
					/>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.event')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.workSteps}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.establishedDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(currentServiceWork.establishedDate)}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.status')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.status || '-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.statusChangedBy')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.statusChangedBy || '-'}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.relevantMall')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.relevantMall == 'Täby Centrum'
								? 'Westfield Täby Centrum'
								: currentServiceWork.relevantMall}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.createdByCompany')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.creatorOfWorkPrep.company}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.createdByPersonName')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.creatorOfWorkPrep.name}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.telephoneNumberToCreator')}
						</Text>
						<Text style={styles.fieldValue}>
							{
								currentServiceWork.creatorOfWorkPrep
									.telephoneNumber
							}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.emailToCreator')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.creatorOfWorkPrep.email}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.workArea')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.workArea}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.floor')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.floor}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.storeIdOrName')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.storeIdOrName}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.numPeople')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.numPeople}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.startDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(currentServiceWork.startDate)}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.endDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(currentServiceWork.endDate)}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.startingTime')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.workingHours.startingTime}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.endingTime')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentServiceWork.workingHours.endingTime}
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	)
}

export default ServiceWorkPDF
