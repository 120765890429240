import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CheckIcon from '@mui/icons-material/Check'
import { Button, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

function WorkPrepStatusDisplay(props: { status: string }) {
	const { t } = useTranslation()
	let statusButton: JSX.Element = <Box></Box>
	const status = props.status
	switch (status) {
		case 'AwaitingSigning': {
			statusButton = (
				<Button
					disabled
					variant="contained"
					sx={{
						my: 1,
						'&.Mui-disabled': {
							color: '#565656',
							backgroundColor: '#CACACA',
						},
					}}
				>
					<AutorenewIcon sx={{ mr: 1 }} />
					<Typography
						sx={{
							fontWeight: 500,
							textTransform: 'lowercase',
							'&:first-letter': { textTransform: 'uppercase' },
						}}
					>
						{t('workPrepStatusDisplay.status.awaitingSigning')}
					</Typography>
				</Button>
			)
			break
		}
		case 'AwaitingApproval': {
			statusButton = (
				<Button
					disabled
					variant="contained"
					sx={{
						my: 1,
						'&.Mui-disabled': {
							color: '#565656',
							backgroundColor: '#CACACA',
						},
					}}
				>
					<AutorenewIcon sx={{ mr: 1 }} />
					<Typography
						sx={{
							fontWeight: 500,
							textTransform: 'lowercase',
							'&:first-letter': { textTransform: 'uppercase' },
						}}
					>
						{t('workPrepStatusDisplay.status.awaitingReview')}
					</Typography>
				</Button>
			)
			break
		}
		case 'Rejected': {
			statusButton = (
				<Button
					disabled
					variant="contained"
					sx={{
						my: 1,
						'&.Mui-disabled': {
							color: '#FFFFFF',
							backgroundColor: '#902F2F',
						},
					}}
				>
					<DoNotDisturbIcon sx={{ mr: 1 }} />
					<Typography
						sx={{
							fontWeight: 500,
							textTransform: 'lowercase',
							'&:first-letter': { textTransform: 'uppercase' },
						}}
					>
						{t('workPrepStatusDisplay.status.denied')}
					</Typography>
				</Button>
			)
			break
		}
		case 'Approved': {
			statusButton = (
				<Button
					disabled
					variant="contained"
					sx={{
						my: 1,
						'&.Mui-disabled': {
							color: '#FFFFFF',
							backgroundColor: '#004D1E',
						},
					}}
				>
					<CheckIcon sx={{ mr: 1 }} />
					<Typography
						sx={{
							fontWeight: 500,
							textTransform: 'lowercase',
							'&:first-letter': { textTransform: 'uppercase' },
						}}
					>
						{t('workPrepStatusDisplay.status.approved')}
					</Typography>
				</Button>
			)
			break
		}
		default: {
			statusButton = (
				<Button
					disabled
					variant="contained"
					sx={{
						my: 1,
						'&.Mui-disabled': {
							color: '#565656',
							backgroundColor: '#CACACA',
						},
					}}
				>
					<AutorenewIcon sx={{ mr: 1 }} />
					<Typography
						sx={{
							fontWeight: 500,
							textTransform: 'lowercase',
							'&:first-letter': { textTransform: 'uppercase' },
						}}
					>
						{t('workPrepStatusDisplay.status.awaitingReview')}
					</Typography>
				</Button>
			)
			break
		}
	}

	return statusButton
}

export default WorkPrepStatusDisplay
