import { SxProps, TextField } from '@mui/material'
import { useField } from 'formik'

interface Props {
	label: string
	name: string
	type?: string
	sx?: SxProps
	rows?: number
	placeholder?: string
}

const CustomMultiLineFieldInput = ({
	label,
	name,
	sx,
	rows = 10,
	type = 'text',
	placeholder = '',
}: Props) => {
	const [field, meta] = useField({ name, placeholder, type })

	return (
		<TextField
			fullWidth
			id="outlined-multiline-static-completionRequestMessage"
			label={label}
			name={name}
			rows={rows}
			multiline
			sx={sx}
			value={meta.value}
			placeholder={placeholder}
			onChange={field.onChange}
			onBlur={field.onBlur}
			error={meta.touched && Boolean(meta.error)}
			helperText={(meta.touched && meta.error) || ' '}
		/>
	)
}

export default CustomMultiLineFieldInput
