import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from '@react-pdf/renderer'
import RobotoThinFont from '../../../resources/fonts/Roboto-Thin.ttf'
import RobotoLightFont from '../../../resources/fonts/Roboto-Light.ttf'
import RobotoRegularFont from '../../../resources/fonts/Roboto-Regular.ttf'
import RobotoBoldFont from '../../../resources/fonts/Roboto-Bold.ttf'
import { FireAlarmAnswers } from '../../../types'
import { mallToImg } from '../../../pages/admin/WorkPrepPDF'
import { useTranslation } from 'react-i18next'
import checkboxNo from '../../../resources/checkboxNo.png'
import checkboxYes from '../../../resources/checkboxYes.png'

Font.register({
	family: 'RobotoThin',
	src: RobotoThinFont,
})

Font.register({
	family: 'RobotoLight',
	src: RobotoLightFont,
})

Font.register({
	family: 'RobotoRegular',
	src: RobotoRegularFont,
})

Font.register({
	family: 'RobotoBold',
	src: RobotoBoldFont,
})

Font.registerHyphenationCallback((word) => [word])

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		margin: '43px',
		fontSize: 14,
		fontFamily: 'RobotoLight',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		paddingRight: '27px',
		paddingLeft: '43px',
		marginLeft: '-43px',
		marginTop: '10px',
	},
	fieldContainer: {
		display: 'flex',
		flex: '1 1 200px',
		flexDirection: 'column',
		marginRight: '15px',
	},
	checkBoxContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	fieldHeader: {
		fontFamily: 'RobotoBold',
		fontSize: 10,
	},
	fieldValue: {
		marginTop: '5px',
		fontSize: 10,
		lineHeight: 1.65,
	},
})

interface Props {
	fireAlarmAnswers: FireAlarmAnswers
	customLang?: 'sv' | 'en'
}

const FireAlarmPDF = ({ fireAlarmAnswers, customLang }: Props) => {
	const { t, i18n } = useTranslation()

	let lang: 'sv' | 'en'

	if (customLang) {
		lang = customLang
	} else {
		lang = i18n.resolvedLanguage as 'sv' | 'en'
	}

	const prettyDate = (dateString: Date) => {
		return new Date(dateString).toLocaleDateString(
			{ sv: 'sv-SE', en: 'en-US' }[lang]
		)
	}

	return (
		<Document title={`Brandlarmsavstängning - ${fireAlarmAnswers.id}`}>
			<Page size="A4" style={styles.page}>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						width: '100%',
						paddingRight: '43px',
						paddingLeft: '43px',
						marginLeft: '-43px',
					}}
				>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<View>
							<Text
								style={{
									fontFamily: 'RobotoLight',
									fontSize: 20,
									marginRight: '4px',
								}}
							>
								{t('fireAlarmPDF.title') +
									': ' +
									fireAlarmAnswers.id}
							</Text>
						</View>
					</View>

					<Image
						src={mallToImg[fireAlarmAnswers.relevantMall]}
						style={{ height: '30px' }}
					/>
				</View>
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('fireAlarmDetails.fireAlarmOrHotWork')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.fireAlarmOrHotWork == 'fireAlarm'
								? t('fireAlarmDetails.fireAlarm')
								: fireAlarmAnswers.fireAlarmOrHotWork ==
								  'hotWork'
								? t('fireAlarmDetails.hotWork')
								: '-'}
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('fireAlarmShutdown.form.workArea')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.workArea}
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('fireAlarmShutdown.form.shutdownReason')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.shutdownReason}
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.relevantMall')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.relevantMall == 'Täby Centrum'
								? 'Westfield Täby Centrum'
								: fireAlarmAnswers.relevantMall}
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.createdByCompany')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.createdByCompany}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.createdByPersonName')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.createdByPersonName}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.telephoneNumberToCreator')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.telephoneNumberToCreator}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.emailToCreator')}
						</Text>
						<Text style={styles.fieldValue}>
							{fireAlarmAnswers.emailToCreator}
						</Text>
					</View>
				</View>
				{fireAlarmAnswers.contactPersonsOnSite.contactPerson1 && (
					<>
						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t(
										'fireAlarmShutdown.form.companyApplicant'
									)}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson1.company
									}
								</Text>
							</View>

							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('fireAlarmShutdown.form.nameApplicant')}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson1.name
									}
								</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t(
										'fireAlarmShutdown.form.phonenumberApplicant'
									)}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson1.number
									}
								</Text>
							</View>

							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('fireAlarmShutdown.form.emailApplicant')}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson1.email
									}
								</Text>
							</View>
						</View>
					</>
				)}
				{fireAlarmAnswers.contactPersonsOnSite.contactPerson2 && (
					<>
						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t(
										'fireAlarmShutdown.form.companyApplicant'
									)}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson2.company
									}
								</Text>
							</View>

							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('fireAlarmShutdown.form.nameApplicant')}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson2.name
									}
								</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t(
										'fireAlarmShutdown.form.phonenumberApplicant'
									)}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson2.number
									}
								</Text>
							</View>

							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('fireAlarmShutdown.form.emailApplicant')}
								</Text>
								<Text style={styles.fieldValue}>
									{
										fireAlarmAnswers.contactPersonsOnSite
											.contactPerson2.email
									}
								</Text>
							</View>
						</View>
					</>
				)}
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.startDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(fireAlarmAnswers.startDate)}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.endDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(fireAlarmAnswers.endDate)}
						</Text>
					</View>
				</View>
				{/* TODO remove startingTime and edingTime since we use seperate times for weekdays and weekends*/}
				{fireAlarmAnswers.hours.startingTime &&
					fireAlarmAnswers.hours.endingTime && (
						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('workPrep.startingTime')}
								</Text>
								<Text style={styles.fieldValue}>
									{fireAlarmAnswers.hours.startingTime}
								</Text>
							</View>

							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('workPrep.endingTime')}
								</Text>
								<Text style={styles.fieldValue}>
									{fireAlarmAnswers.hours.endingTime}
								</Text>
							</View>
						</View>
					)}

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<View style={styles.checkBoxContainer}>
							<Image
								src={
									fireAlarmAnswers.weekdays
										? checkboxYes
										: checkboxNo
								}
								style={{
									height: '10px',
									width: '10px',
									marginRight: '10px',
								}}
							/>
							<Text style={styles.fieldHeader}>
								{t('fireAlarmShutdown.form.weekdays')}
							</Text>
						</View>
					</View>
				</View>
				{fireAlarmAnswers.weekdays && (
					<View style={styles.row}>
						<View style={styles.fieldContainer}>
							<Text style={styles.fieldHeader}>
								{t('workPrep.startingTime')}
							</Text>
							<Text style={styles.fieldValue}>
								{fireAlarmAnswers.hours.weekdayStartingTime}
							</Text>
						</View>

						<View style={styles.fieldContainer}>
							<Text style={styles.fieldHeader}>
								{t('workPrep.endingTime')}
							</Text>
							<Text style={styles.fieldValue}>
								{fireAlarmAnswers.hours.weekdayEndingTime}
							</Text>
						</View>
					</View>
				)}
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<View style={styles.checkBoxContainer}>
							<Image
								src={
									fireAlarmAnswers.weekends
										? checkboxYes
										: checkboxNo
								}
								style={{
									height: '10px',
									width: '10px',
									marginRight: '10px',
								}}
							/>
							<Text style={styles.fieldHeader}>
								{t('fireAlarmShutdown.form.weekends')}
							</Text>
						</View>
					</View>
				</View>
				{fireAlarmAnswers.weekends && (
					<View style={styles.row}>
						<View style={styles.fieldContainer}>
							<Text style={styles.fieldHeader}>
								{t('workPrep.startingTime')}
							</Text>
							<Text style={styles.fieldValue}>
								{fireAlarmAnswers.hours.weekendStartingTime}
							</Text>
						</View>

						<View style={styles.fieldContainer}>
							<Text style={styles.fieldHeader}>
								{t('workPrep.endingTime')}
							</Text>
							<Text style={styles.fieldValue}>
								{fireAlarmAnswers.hours.weekendEndingTime}
							</Text>
						</View>
					</View>
				)}
				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<View style={styles.checkBoxContainer}>
							<Image
								src={
									fireAlarmAnswers.aroundTheClock
										? checkboxYes
										: checkboxNo
								}
								style={{
									height: '10px',
									width: '10px',
									marginRight: '10px',
								}}
							/>
							<Text style={styles.fieldHeader}>
								{t(
									'fireAlarmShutdown.form.aroundTheClockWithoutInfo'
								)}
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	)
}

export default FireAlarmPDF
