import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { EN_TRANSLATIONS } from './locales/en_translation'
import { SV_TRANSLATIONS } from './locales/sv_translation'

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: 'sv',
		interpolation: {
			escapeValue: false,
		},
		resources: {
			sv: {
				translation: SV_TRANSLATIONS,
			},
			en: {
				translation: EN_TRANSLATIONS,
			},
		},
	})

export default i18n
