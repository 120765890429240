import {
	Grid,
	MenuItem,
	Typography,
	Link,
	Dialog,
	DialogTitle,
	IconButton,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import CustomFileInputWithTitle from '../formik/CustomFileInputWithTitle'
import CustomTextInput from '../formik/CustomTextInput'
import CustomSelectInput from '../formik/CustomSelectInput'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'

interface Props {
	setSnackbarData: any
}

const UploadDocumentForm = ({ setSnackbarData }: Props) => {
	const { values } = useFormikContext<any>()
	const [legalTextisOpen, setLegalTextIsOpen] = useState(false)
	const { t } = useTranslation()

	const handleOpenLegal = () => {
		setLegalTextIsOpen(!legalTextisOpen)
	}
	const handleClose = () => {
		setLegalTextIsOpen(!legalTextisOpen)
	}

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item>
				<Dialog onClose={handleClose} open={legalTextisOpen}>
					<DialogTitle>
						<IconButton
							onClick={handleClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
						{t('submitUserDocumentModal.legalTitle')}
					</DialogTitle>
					<Typography marginX={5} marginY={3}>
						{t('submitUserDocumentModal.legalText')}
					</Typography>
				</Dialog>
			</Grid>
			<Grid item xs={12}>
				<Typography
					variant={'h5'}
					component={'h2'}
					sx={{ fontWeight: 500 }}
					marginBottom={'20px'}
				>
					{t('submitUserDocumentModal.uploadDocument')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<CustomTextInput
					label={t('submitUserDocumentModal.documentName')}
					name="fileName"
					type="text"
				/>
			</Grid>
			<Grid item xs={12}>
				<CustomTextInput
					label={t('submitUserDocumentModal.documentPerson')}
					name="documentPersonName"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<CustomSelectInput
					label={t('submitUserDocumentModal.documentType')}
					name="documentType"
					sx={{ width: '100%' }}
				>
					<MenuItem value="electricalWorkCertificate">
						{t('submitUserDocumentModal.electricalWorkCertificate')}
					</MenuItem>
					<MenuItem value="workWithWaterCertificate">
						{t('submitUserDocumentModal.workWithWaterCertificate')}
					</MenuItem>
					<MenuItem value="baseUCertificate">
						{t('submitUserDocumentModal.baseUCertrificate')}
					</MenuItem>
					<MenuItem value="basePCertificate">
						{t('submitUserDocumentModal.basePCertrificate')}
					</MenuItem>
					<MenuItem value="liftCardsForPersonalWorkingWithLifts">
						{t(
							'submitUserDocumentModal.liftCardsForPersonalWorkingWithLifts'
						)}
					</MenuItem>
				</CustomSelectInput>
			</Grid>
			<Grid item xs={12}>
				<CustomFileInputWithTitle
					formAttribute={'userDocument'}
					formValues={values}
					setSnackbar={setSnackbarData}
				/>
			</Grid>
			<Grid item xs={12}>
				<CustomCheckboxInput
					label={t('submitUserDocumentModal.agreeLegal')}
					name="iHaveRead"
					sx={{ marginRight: '6px' }}
				/>
				<Link
					component="button"
					type="button"
					onClick={handleOpenLegal}
				>
					{t('submitUserDocumentModal.openLegalText')}
				</Link>
			</Grid>
			<Grid item xs={12}></Grid>
		</Grid>
	)
}

export default UploadDocumentForm
