import { useTranslation } from 'react-i18next'
import {
	Button,
	Typography,
	Box,
	Grid,
	Backdrop,
	Modal,
	Fade,
	IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const style = {
	// eslint-disable-next-line
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 605,
	bgcolor: 'background.paper',
	border: '1px solid #555',
	borderRadius: 1,
	boxShadow: 24,
	p: 2,
}

type Props = {
	isOpen: boolean
	setIsOpen: (newIsOpenValue: boolean) => void
	submit: any
}

const DeleteWorkModal = ({ isOpen, setIsOpen, submit }: Props) => {
	const { t } = useTranslation()

	const handleClose = () => setIsOpen(false)

	const handleDelete = () => {
		setIsOpen(false)
		submit()
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<Box sx={style}>
						<IconButton
							onClick={handleClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
						<Grid
							container
							paddingX={5}
							paddingY={5}
							direction={'column'}
							rowSpacing={1}
						>
							<Grid item>
								<Typography
									variant={'h5'}
									textAlign="center"
									component={'h2'}
									sx={{ fontWeight: 500 }}
								>
									{t('workPrepDetails.deleteModal.title')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography textAlign="center">
									{t(
										'workPrepDetails.deleteModal.helperText'
									)}
								</Typography>
							</Grid>

							<Grid item mt={2}>
								<Button
									fullWidth
									variant="contained"
									color="error"
									onClick={handleDelete}
								>
									{t(
										'workPrepDetails.deleteModal.submitRequest'
									)}
								</Button>
							</Grid>
							<Grid item>
								<Button
									fullWidth
									variant="outlined"
									onClick={handleClose}
								>
									{t(
										'workPrepDetails.deleteModal.closeModal'
									)}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default DeleteWorkModal
