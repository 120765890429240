import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
	SERVICE_WORKS_BY_MALL,
	SERVICE_WORKS_BY_ACCOUNT,
} from '../../graphql/queries/workPreps'
import { ServiceWorkAnswers } from '../../types'
import { GridColDef } from '@mui/x-data-grid'
import CustomDataGrid from '../../components/admin/CustomDataGrid'
import { Add } from '@mui/icons-material'
import ErrorOccurred from '../../components/common/ErrorOccurred'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import ClearIcon from '@mui/icons-material/Clear'
import { UPDATE_PREFERRED_MALL } from '../../graphql/mutations/users'
import { useAuth } from '../../hooks/useAuth'
import { dateComparator, normalizeDate } from '../../utils/helperFunctions'
import FilterCalender from '../../components/admin/FilterCalender'

export interface ServiceWorkItem {
	id: string
	establishedDate: string
	status: string
	relevantMall: string
	createdBy: string
	startDate: string
	endDate: string
	startingTime: string
	endingTime: string
	workArea: string
}

const ServiceWorkPanel = () => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const [allServiceWorks, setAllServiceWorks] = useState<ServiceWorkItem[]>(
		[]
	)
	const [search, setSearch] = useState<string>('')
	const { role, jwt, userEmail, preferredMall, setPreferredMall } = useAuth()
	const [filterMall, setFilterMall] = useState<string>(
		preferredMall ? preferredMall : ''
	)
	const [filterDate, setFilterDate] = useState<Date | null>(null)
	const [updatePreferredMall] = useMutation(UPDATE_PREFERRED_MALL)

	let mall = ''

	if (role === 'SuperAdmin' || role === 'Admin - Alla centrum') {
		mall = 'all'
	} else if (role!.split(' - ')[1]) {
		mall = role!.split(' - ')[1]
	}

	if (mall === 'MOS') {
		mall = 'Westfield Mall of Scandinavia'
	}

	const getOnlyOwnServiceWorks = role === 'Upprättare'

	const { loading, error, data } = getOnlyOwnServiceWorks
		? useQuery(SERVICE_WORKS_BY_ACCOUNT, {
				variables: { jwt },
				fetchPolicy: 'network-only',
		  })
		: useQuery(SERVICE_WORKS_BY_MALL, {
				variables: { mall, jwt },
				fetchPolicy: 'network-only',
		  })

	const newServiceWork = () => {
		navigate('/service-work', { state: { user: userEmail } })
	}

	const handleUpdatedPreferredMall = (event: any) => {
		const mall = event.target.value
		setFilterMall(mall)
		updatePreferredMall({
			variables: {
				jwt,
				mall,
			},
		})
		setPreferredMall(mall)
	}

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 105,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'status',
			headerName: t('workPrep.status'),
			width: 125,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'establishedDate',
			headerName: t('workPrep.establishedDate'),
			width: 100,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'relevantMall',
			headerName: t('workPrep.relevantMall'),
			width: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'createdBy',
			headerName: t('workPrep.createdBy'),
			width: 220,
			editable: false,
			sortable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'workArea',
			headerName: t('workPrep.workArea'),
			sortable: false,
			width: 140,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startDate',
			headerName: t('workPrep.startDate'),
			sortComparator: dateComparator,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endDate',
			headerName: t('workPrep.endDate'),
			sortComparator: dateComparator,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startingTime',
			headerName: t('workPrep.startingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endingTime',
			headerName: t('workPrep.endingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
	]

	useEffect(() => {
		if (data) {
			const allServiceWorksParsed = getOnlyOwnServiceWorks
				? JSON.parse(data.serviceWorksByAccount)
				: JSON.parse(data.serviceWorksByMall)

			let allServiceWorksFiltered: ServiceWorkAnswers[] =
				allServiceWorksParsed

			allServiceWorksFiltered = allServiceWorksFiltered.filter(
				(serviceWork: ServiceWorkAnswers) => {
					if (
						serviceWork.id.includes(search) ||
						serviceWork.creatorOfWorkPrep.company
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						serviceWork.creatorOfWorkPrep.name
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						serviceWork.workArea
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						serviceWork.storeIdOrName
							.toLowerCase()
							.includes(search.toLowerCase())
					) {
						return true
					}
					return false
				}
			)

			allServiceWorksFiltered = allServiceWorksFiltered.filter(
				(serviceWork: ServiceWorkAnswers) =>
					serviceWork.relevantMall.includes(filterMall)
			)

			if (filterDate) {
				const filterDateNormalized = normalizeDate(filterDate)
				allServiceWorksFiltered = allServiceWorksFiltered.filter(
					(workPrep: ServiceWorkAnswers) => {
						return (
							filterDateNormalized >=
								normalizeDate(workPrep.startDate) &&
							filterDateNormalized <=
								normalizeDate(workPrep.endDate)
						)
					}
				)
			}

			allServiceWorksFiltered.sort((a, b) => {
				const aDate = new Date(a.establishedDate)
				const bDate = new Date(b.establishedDate)

				if (aDate < bDate) return 1
				else if (aDate > bDate) return -1
				else return 0
			})

			const serviceWorkItems: ServiceWorkItem[] =
				allServiceWorksFiltered.map((serviceWork) => {
					const establishedDate = new Date(
						serviceWork.establishedDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					const startDate = new Date(
						serviceWork.startDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					const endDate = new Date(
						serviceWork.endDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					return {
						id: serviceWork.id || '',
						establishedDate,
						status: serviceWork.status,
						relevantMall:
							serviceWork.relevantMall == 'Täby Centrum'
								? 'Westfield Täby Centrum'
								: serviceWork.relevantMall,
						createdBy: `${serviceWork.creatorOfWorkPrep.company}, ${
							serviceWork.creatorOfWorkPrep.name
						} (${
							(serviceWork.creatorOfWorkPrep.email,
							serviceWork.creatorOfWorkPrep.telephoneNumber)
						})`,
						workArea: serviceWork.workArea,
						startDate,
						endDate,
						startingTime: serviceWork.workingHours.startingTime,
						endingTime: serviceWork.workingHours.endingTime,
					}
				})

			setAllServiceWorks(serviceWorkItems)
		}
	}, [loading, data, i18n.resolvedLanguage, search, filterDate, filterMall])

	if (loading) return <LoadingSpinner />

	if (error) return <ErrorOccurred />

	if (role === null) return <NotLoggedIn />

	const canCreateServiceWork =
		role === 'Upprättare' ||
		role === 'SuperAdmin' ||
		role === 'Admin - Alla centrum' ||
		role === 'Admin - Westfield Mall of Scandinavia' ||
		role === 'Admin - Nacka Forum' ||
		role === 'Admin - Täby Centrum'

	return (
		<Grid
			container
			direction="column"
			spacing={2}
			paddingX={{ xs: 1, sm: 5 }}
		>
			<Grid item marginTop={'50px'}>
				<Typography variant="h5" fontWeight={'500'}>
					{t('serviceWorkPanel.serviceWorks')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>{t('serviceWorkPanel.helperText')}</Typography>
			</Grid>
			<Grid item>
				<Grid
					container
					justifyContent={'space-between'}
					columnGap={1}
					rowGap={1}
				>
					<Grid item>
						<Grid
							container
							direction={'row'}
							columnSpacing={1}
							rowSpacing={1}
							display={'flex'}
						>
							<Grid item>
								<TextField
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '250px',
										},
									}}
									id="filterName"
									label={t('serviceWorkPanel.search')}
									variant="outlined"
									value={search}
									onChange={(
										event: React.ChangeEvent<HTMLInputElement>
									) => {
										setSearch(event.target.value)
									}}
									InputProps={{
										endAdornment: (
											<IconButton
												sx={{
													visibility: search.length
														? 'visible'
														: 'hidden',
												}}
												onClick={() => setSearch('')}
											>
												<ClearIcon />
											</IconButton>
										),
									}}
								/>
							</Grid>
							{role === 'SuperAdmin' && (
								<Grid item>
									<TextField
										fullWidth
										sx={{
											minWidth: {
												xs: 'calc(100vw - 16px)',
												sm: '250px',
											},
										}}
										id="filterRole"
										label={t('adminPanel.relevantMall')}
										value={filterMall}
										select
										onChange={handleUpdatedPreferredMall}
									>
										<MenuItem value="">
											{t('adminPanel.allMall')}
										</MenuItem>
										<MenuItem value="Westfield Mall of Scandinavia">
											Westfield Mall of Scandinavia
										</MenuItem>
										<MenuItem value="Täby Centrum">
											Westfield Täby Centrum
										</MenuItem>
										<MenuItem value="Nacka Forum">
											Nacka Forum
										</MenuItem>
									</TextField>
								</Grid>
							)}
							<Grid item>
								<Grid
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '250px',
										},
									}}
								>
									<FilterCalender
										selectedDate={filterDate}
										setSelectedDate={setFilterDate}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						{canCreateServiceWork && (
							<Button
								sx={{
									height: '50px',
									minWidth: {
										xs: 'calc(100vw - 16px)',
										sm: '220px',
									},
								}}
								variant="contained"
								startIcon={<Add />}
								onClick={newServiceWork}
							>
								{t('serviceWorkPanel.newServiceWork')}
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Box height={750}>
					<CustomDataGrid
						type={'service-work-panel'}
						data={allServiceWorks}
						columns={columns}
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export default ServiceWorkPanel
