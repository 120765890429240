import { Divider, SxProps } from '@mui/material'

interface Props {
	sx?: SxProps
}

const CustomDivider = ({ sx }: Props) => {
	return (
		<Divider
			sx={{
				...sx,
				marginY: '20px',
				width: 'calc(100% - 20px)',
				marginLeft: '20px',
			}}
		/>
	)
}
export default CustomDivider
