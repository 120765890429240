import { CircularProgress } from '@mui/material'

const LoadingSpinner = () => {
	return (
		<div className="w-full  h-full flex justify-center pt-20">
			<CircularProgress style={{ height: '75px', width: '75px' }} />
		</div>
	)
}

export default LoadingSpinner
