import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CustomMultiLineFieldInput from '../formik/CustomMultiLineFieldInput'
import { completionRequestForm } from '../../types'
import { apolloClient } from '../../index'
import {
	SEND_COMPLETION_REQUEST,
	SEND_COMPLETION_REQUEST_FIRE_ALARM,
	SEND_COMPLETION_REQUEST_SERVICE_WORK,
} from '../../graphql/mutations/workPreps'
import { useAuth } from '../../hooks/useAuth'

const style = {
	// eslint-disable-next-line
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 600,
	bgcolor: 'background.paper',
	border: '1px solid #555',
	borderRadius: 1,
	boxShadow: 24,
	p: 4,
}

const commonRow = {
	mt: 2,
	width: '100%',
}

const fullWidth = {
	width: '100%',
}
const commonRowTitle = {
	fontSize: 15,
	mt: 4,
	mb: 2,
}

type Props = {
	isOpen: boolean
	setIsOpen: (newIsOpenValue: boolean) => void
	setSnackbarData: any
	workPrepId: string
	type: 'workPrep' | 'serviceWork' | 'fireAlarm'
}

const RequestCompletionModal = ({
	isOpen,
	setIsOpen,
	setSnackbarData,
	workPrepId,
	type,
}: Props) => {
	const { jwt, userEmail } = useAuth()
	const { t } = useTranslation()

	const handleClose = () => setIsOpen(false)

	const completionRequestValidationSchema = Yup.object({
		completionRequestMessage: Yup.string().required(t('common.required')),
	})

	const initialFormikValues = {
		completionRequestMessage: '',
	}

	const submitCompletionRequest = async (values: any, { resetForm }: any) => {
		const completionRequest: completionRequestForm = {
			completionRequestMessage: values.completionRequestMessage,
			completionRequestSentBy: userEmail ? userEmail : '-',
			completionRequestSentOn: new Date().toString(),
			workPrepId: workPrepId,
		}
		const completionRequestJsonString = JSON.stringify(completionRequest)
		setSnackbarData({
			message: t(
				'workPrepDetails.completeWorkPrepRequestModal.sendingRequest'
			),
			severity: 'info',
			open: true,
		})

		if (type === 'workPrep') {
			await apolloClient.mutate({
				mutation: SEND_COMPLETION_REQUEST,
				variables: {
					completionRequest: completionRequestJsonString,
				},
			})
		}
		if (type === 'serviceWork') {
			await apolloClient.mutate({
				mutation: SEND_COMPLETION_REQUEST_SERVICE_WORK,
				variables: {
					completionRequest: completionRequestJsonString,
				},
			})
		}
		if (type === 'fireAlarm') {
			await apolloClient.mutate({
				mutation: SEND_COMPLETION_REQUEST_FIRE_ALARM,
				variables: {
					completionRequest: completionRequestJsonString,
					jwt,
				},
			})
		}
		setSnackbarData({
			message: t(
				'workPrepDetails.completeWorkPrepRequestModal.requestSent'
			),
			severity: 'success',
			open: true,
		})
		resetForm()
		setIsOpen(false)
	}
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<Box sx={style}>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
						>
							<Trans
								i18nKey={
									'workPrepDetails.completeWorkPrepRequestModal.title'
								}
							/>
						</Typography>
						<Formik
							initialValues={initialFormikValues}
							validationSchema={completionRequestValidationSchema}
							onSubmit={submitCompletionRequest}
						>
							{({ errors, values, handleSubmit }) => {
								return (
									<form
										onSubmit={(e) => {
											handleSubmit(e)
											if (
												Object.keys(errors).length > 0
											) {
												setSnackbarData({
													message: t(
														'common.seeErrorsAbove'
													),
													severity: 'error',
													open: true,
												})
											}
										}}
									>
										<Box sx={commonRow}>
											<Typography
												id="transition-modal-description"
												sx={commonRowTitle}
											>
												<Trans
													i18nKey={
														'workPrepDetails.completeWorkPrepRequestModal.title'
													}
												/>
											</Typography>

											<CustomMultiLineFieldInput
												rows={10}
												label={t(
													'workPrepDetails.completeWorkPrepRequestModal.completionRequestMessageTitle'
												)}
												name={
													'completionRequestMessage'
												}
											/>
										</Box>
										<Box sx={commonRow}>
											<Button
												variant="contained"
												type="submit"
												fullWidth
												sx={{ mt: '15px' }}
											>
												<Trans
													i18nKey={
														'workPrepDetails.completeWorkPrepRequestModal.submitRequest'
													}
												/>
											</Button>
											<Button
												variant="outlined"
												onClick={handleClose}
												fullWidth
												color="error"
												sx={{ mt: '15px' }}
											>
												<Trans
													i18nKey={
														'workPrepDetails.completeWorkPrepRequestModal.closeModal'
													}
												/>
											</Button>
										</Box>
									</form>
								)
							}}
						</Formik>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default RequestCompletionModal
