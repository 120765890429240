import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'

import enLocale from 'date-fns/locale/en-US'
import svLocale from 'date-fns/locale/sv'
import { useTranslation } from 'react-i18next'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import CloseIcon from '@mui/icons-material/Close'

const localeMap = {
	en: enLocale,
	sv: svLocale,
}

interface Props {
	selectedDate: Date | null
	setSelectedDate: Dispatch<SetStateAction<Date | null>>
}

export default function FilterCalender({
	selectedDate,
	setSelectedDate,
}: Props) {
	const { i18n } = useTranslation()
	const { t } = useTranslation()

	const locale = i18n.resolvedLanguage.split('-')[0] === 'sv' ? 'sv' : 'en'

	return (
		<LocalizationProvider
			dateAdapter={DateAdapter}
			locale={localeMap[locale]}
		>
			<DesktopDatePicker
				mask={locale === 'sv' ? '____-__-__' : '__/__/____'}
				value={selectedDate}
				label={t('adminPanel.activeWorks')}
				onChange={(val) => setSelectedDate(val)}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						onKeyDown={(e) => e.preventDefault()}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<InputAdornment position="end">
									{selectedDate ? (
										<IconButton
											onClick={() =>
												setSelectedDate(null)
											}
										>
											<CloseIcon />
										</IconButton>
									) : (
										params.InputProps?.endAdornment
									)}
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
		</LocalizationProvider>
	)
}
