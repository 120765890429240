import { useTranslation } from 'react-i18next'

interface PrettyDateProps {
	dateString: Date | string
}

export const PrettyDateWithTime = ({ dateString }: PrettyDateProps) => {
	const { i18n } = useTranslation()
	const lang = i18n.resolvedLanguage as 'sv' | 'en'

	return (
		<span className="whitespace-nowrap">
			{new Date(dateString).toLocaleString(
				{ sv: 'sv-SE', en: 'en-US' }[lang],
				{
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
				}
			)}
		</span>
	)
}

export const PrettyDate = ({ dateString }: PrettyDateProps) => {
	const { i18n } = useTranslation()
	const lang = i18n.resolvedLanguage as 'sv' | 'en'

	return (
		<p className="whitespace-nowrap">
			{new Date(dateString).toLocaleDateString(
				{ sv: 'sv-SE', en: 'en-US' }[lang]
			)}
		</p>
	)
}
