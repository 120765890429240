import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CustomMultiLineFieldInput from '../formik/CustomMultiLineFieldInput'
import CustomTextInput from '../formik/CustomTextInput'

const style = {
	// eslint-disable-next-line
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 600,
	bgcolor: 'background.paper',
	border: '1px solid #555',
	borderRadius: 1,
	boxShadow: 24,
	p: 4,
}

const commonRow = {
	mt: 2,
	width: '100%',
}

const fullWidth = {
	width: '100%',
}
const commonRowTitle = {
	fontSize: 15,
	mt: 4,
	mb: 2,
}

type Props = {
	isOpen: boolean
	setIsOpen: (newIsOpenValue: boolean) => void
	submitComment: any
}

const CommentModal = ({ isOpen, setIsOpen, submitComment }: Props) => {
	const { t } = useTranslation()

	const handleClose = () => setIsOpen(false)

	const commentValidationSchema = Yup.object({
		commentMessage: Yup.string().required(t('common.required')),
		commentHeading: Yup.string().required(t('common.required')),
		commentCreator: Yup.string().required(t('common.required')),
	})

	const initialFormikValues = {
		commentMessage: '',
		commentHeading: '',
		commentCreator: '',
	}

	const submit = async (values: any, { resetForm }: any) => {
		submitComment(values)
		resetForm()
		setIsOpen(false)
	}
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<Box sx={style}>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
						>
							<Trans
								i18nKey={'workPrepDetails.commentModal.title'}
							/>
						</Typography>
						<Formik
							initialValues={initialFormikValues}
							validationSchema={commentValidationSchema}
							onSubmit={submit}
						>
							{({ errors, values, handleSubmit }) => {
								return (
									<form
										onSubmit={(e) => {
											handleSubmit(e)
										}}
									>
										<Box sx={commonRow}>
											<Typography
												id="transition-modal-description"
												sx={commonRowTitle}
											>
												<Trans
													i18nKey={
														'workPrepDetails.commentModal.title'
													}
												/>
											</Typography>

											<CustomTextInput
												label={t(
													'workPrepDetails.commentModal.commentCreator'
												)}
												name={'commentCreator'}
											/>

											<CustomTextInput
												label={t(
													'workPrepDetails.commentModal.messageHeaderTitle'
												)}
												name={'commentHeading'}
											/>

											<CustomMultiLineFieldInput
												rows={10}
												label={t(
													'workPrepDetails.commentModal.messageTitle'
												)}
												name={'commentMessage'}
											/>
										</Box>
										<Box sx={commonRow}>
											<Button
												variant="contained"
												type="submit"
												fullWidth
												sx={{ mt: '15px' }}
											>
												<Trans
													i18nKey={
														'workPrepDetails.commentModal.submitRequest'
													}
												/>
											</Button>
											<Button
												variant="outlined"
												onClick={handleClose}
												fullWidth
												color="error"
												sx={{ mt: '15px' }}
											>
												<Trans
													i18nKey={
														'workPrepDetails.commentModal.closeModal'
													}
												/>
											</Button>
										</Box>
									</form>
								)
							}}
						</Formik>
					</Box>
				</Fade>
			</Modal>
		</div>
	)
}

export default CommentModal
