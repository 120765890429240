import { DesktopDatePicker } from '@mui/lab'
import { SxProps, TextField } from '@mui/material'
import { useField } from 'formik'

import enLocale from 'date-fns/locale/en-US'
import svLocale from 'date-fns/locale/sv'

// Material UI: Date picker
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useTranslation } from 'react-i18next'

interface Props {
	label: string
	name: string
	disabled?: boolean
	sx?: SxProps
	placeholder?: string
}

const localeMap = {
	en: enLocale,
	sv: svLocale,
}

const CustomDateInput = ({ label, name, sx, disabled = false }: Props) => {
	const [field, meta, helpers] = useField({ name })

	const { setValue } = helpers

	const { i18n } = useTranslation()

	const locale = i18n.resolvedLanguage.split('-')[0] === 'sv' ? 'sv' : 'en'

	// TODO: Fixa mask nedan
	return (
		<LocalizationProvider
			dateAdapter={DateAdapter}
			locale={localeMap[locale]}
		>
			<DesktopDatePicker
				mask={locale === 'sv' ? '____-__-__' : '__/__/____'}
				value={field.value}
				label={label}
				views={['year', 'month', 'day']}
				onChange={(val) => setValue(val)}
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={sx}
						onBlur={field.onBlur}
						fullWidth
						id={name}
						error={meta.touched && Boolean(meta.error)}
						label={label}
						helperText={(meta.touched && meta.error) || ' '}
						type="date"
						disabled={disabled}
					/>
				)}
			/>
		</LocalizationProvider>
	)
}

export default CustomDateInput
