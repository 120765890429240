import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
	FIRE_ALARMS_BY_MALL,
	FIRE_ALARMS_BY_ACCOUNT,
} from '../../graphql/queries/workPreps'
import { FireAlarmAnswers } from '../../types'
import { GridColDef } from '@mui/x-data-grid'
import CustomDataGrid from '../../components/admin/CustomDataGrid'
import { Add } from '@mui/icons-material'
import ErrorOccurred from '../../components/common/ErrorOccurred'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import ClearIcon from '@mui/icons-material/Clear'
import { UPDATE_PREFERRED_MALL } from '../../graphql/mutations/users'
import { useAuth } from '../../hooks/useAuth'
import { dateComparator, normalizeDate } from '../../utils/helperFunctions'
import FilterCalender from '../../components/admin/FilterCalender'

export interface FireAlarmItem {
	id: string
	fireAlarmOrHotWork: string
	establishedDate: string
	status: string
	relevantMall: string
	createdBy: string
	startDate: string
	endDate: string
	startingTime: string
	endingTime: string
	workArea: string
}

const FireAlarmPanel = () => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const [allFireAlarms, setAllFireAlarms] = useState<FireAlarmItem[]>([])
	const { role, jwt, preferredMall, setPreferredMall } = useAuth()
	const [filterMall, setFilterMall] = useState<string>(
		preferredMall ? preferredMall : ''
	)
	const [search, setSearch] = useState<string>('')
	const [filterDate, setFilterDate] = useState<Date | null>(null)
	const [updatePreferredMall] = useMutation(UPDATE_PREFERRED_MALL)

	let mall = ''

	if (role === 'SuperAdmin' || role === 'Admin - Alla centrum') {
		mall = 'all'
	} else if (role!.split(' - ')[1]) {
		mall = role!.split(' - ')[1]
	}

	if (mall === 'MOS') {
		mall = 'Westfield Mall of Scandinavia'
	}

	const getOnlyOwnFireAlarms =
		role === 'Upprättare' || role === 'projectManager'

	const { loading, error, data } = getOnlyOwnFireAlarms
		? useQuery(FIRE_ALARMS_BY_ACCOUNT, {
				variables: { jwt },
				fetchPolicy: 'network-only',
		  })
		: useQuery(FIRE_ALARMS_BY_MALL, {
				variables: { mall, jwt },
				fetchPolicy: 'network-only',
		  })

	const newFireAlarm = () => {
		navigate('/fire-alarm-id')
	}

	const handleUpdatedPreferredMall = (event: any) => {
		const mall = event.target.value
		setFilterMall(mall)
		updatePreferredMall({
			variables: {
				jwt,
				mall,
			},
		})
		setPreferredMall(mall)
	}

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 105,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'fireAlarmOrHotWork',
			headerName: t('fireAlarmPanel.type'),
			width: 200,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'status',
			headerName: t('workPrep.status'),
			width: 125,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
		},
		{
			field: 'establishedDate',
			headerName: t('workPrep.establishedDate'),
			width: 100,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'relevantMall',
			headerName: t('workPrep.relevantMall'),
			width: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'createdBy',
			headerName: t('workPrep.createdBy'),
			width: 220,
			editable: false,
			headerAlign: 'left',
			align: 'left',
			sortable: false,
		},
		{
			field: 'workArea',
			headerName: t('workPrep.workArea'),
			sortable: false,
			width: 140,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'startDate',
			headerName: t('workPrep.startDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'endDate',
			headerName: t('workPrep.endDate'),
			width: 150,
			headerAlign: 'left',
			align: 'left',
			sortComparator: dateComparator,
		},
		{
			field: 'startingTime',
			headerName: t('workPrep.startingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'endingTime',
			headerName: t('workPrep.endingTime'),
			sortable: false,
			width: 150,
			headerAlign: 'left',
			align: 'left',
		},
	]

	useEffect(() => {
		if (data) {
			const allFireAlarmsParsed = getOnlyOwnFireAlarms
				? JSON.parse(data.fireAlarmsByAccount)
				: JSON.parse(data.fireAlarmsByMall)

			let allFireAlarmsFiltered: FireAlarmAnswers[] = allFireAlarmsParsed

			allFireAlarmsFiltered = allFireAlarmsFiltered.filter(
				(fireAlarm: FireAlarmAnswers) => {
					if (
						fireAlarm.id.includes(search) ||
						fireAlarm.createdByCompany
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						fireAlarm.createdByPersonName
							.toLowerCase()
							.includes(search.toLowerCase()) ||
						fireAlarm.workArea
							.toLowerCase()
							.includes(search.toLowerCase())
					) {
						return true
					}
					return false
				}
			)

			allFireAlarmsFiltered = allFireAlarmsFiltered.filter(
				(fireAlarm: FireAlarmAnswers) =>
					fireAlarm.relevantMall.includes(filterMall)
			)

			if (filterDate) {
				const filterDateNormalized = normalizeDate(filterDate)
				allFireAlarmsFiltered = allFireAlarmsFiltered.filter(
					(workPrep: FireAlarmAnswers) => {
						return (
							filterDateNormalized >=
								normalizeDate(workPrep.startDate) &&
							filterDateNormalized <=
								normalizeDate(workPrep.endDate)
						)
					}
				)
			}

			allFireAlarmsFiltered.sort((a, b) => {
				const aDate = new Date(a.establishedDate)
				const bDate = new Date(b.establishedDate)

				if (aDate < bDate) return 1
				else if (aDate > bDate) return -1
				else return 0
			})

			const fireAlarmItems: FireAlarmItem[] = allFireAlarmsFiltered.map(
				(fireAlarm) => {
					const establishedDate = new Date(
						fireAlarm.establishedDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					const startDate = new Date(
						fireAlarm.startDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					const endDate = new Date(
						fireAlarm.endDate
					).toLocaleDateString(
						{ sv: 'sv-SE', en: 'en-US' }[i18n.resolvedLanguage]
					)

					return {
						id: fireAlarm.id || '',
						fireAlarmOrHotWork:
							fireAlarm.fireAlarmOrHotWork == 'fireAlarm'
								? t('fireAlarmDetails.fireAlarm')
								: fireAlarm.fireAlarmOrHotWork == 'hotWork'
								? t('fireAlarmDetails.hotWork')
								: '-',
						establishedDate,
						status: fireAlarm.status,
						relevantMall:
							fireAlarm.relevantMall == 'Täby Centrum'
								? 'Westfield Täby Centrum'
								: fireAlarm.relevantMall,
						createdBy: `${fireAlarm.createdByCompany}, ${
							fireAlarm.createdByPersonName
						} (${
							(fireAlarm.emailToCreator,
							fireAlarm.telephoneNumberToCreator)
						})`,
						workArea: fireAlarm.workArea,
						startDate,
						endDate,
						startingTime: fireAlarm.hours.startingTime || '',
						endingTime: fireAlarm.hours.endingTime || '',
					}
				}
			)

			setAllFireAlarms(fireAlarmItems)
		}
	}, [loading, data, i18n.resolvedLanguage, search, filterDate, filterMall])

	if (loading) return <LoadingSpinner />

	if (error) return <ErrorOccurred />

	if (role === null) return <NotLoggedIn />

	return (
		<Grid
			container
			direction="column"
			spacing={2}
			paddingX={{ xs: 1, sm: 5 }}
		>
			<Grid item marginTop={'50px'}>
				<Typography variant="h5" fontWeight={'500'}>
					{t('fireAlarmPanel.fireAlarms')}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>{t('fireAlarmPanel.helperText')}</Typography>
			</Grid>

			<Grid item>
				<Grid
					container
					justifyContent={'space-between'}
					columnGap={1}
					rowGap={1}
				>
					<Grid item>
						<Grid
							container
							direction={'row'}
							columnSpacing={1}
							rowSpacing={1}
							display={'flex'}
						>
							<Grid item>
								<TextField
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '250px',
										},
									}}
									id="filterName"
									label={t('fireAlarmPanel.search')}
									variant="outlined"
									value={search}
									onChange={(
										event: React.ChangeEvent<HTMLInputElement>
									) => {
										setSearch(event.target.value)
									}}
									InputProps={{
										endAdornment: (
											<IconButton
												sx={{
													visibility: search.length
														? 'visible'
														: 'hidden',
												}}
												onClick={() => setSearch('')}
											>
												<ClearIcon />
											</IconButton>
										),
									}}
								/>
							</Grid>
							{role === 'SuperAdmin' && (
								<Grid item>
									<TextField
										fullWidth
										sx={{
											minWidth: {
												xs: 'calc(100vw - 16px)',
												sm: '250px',
											},
										}}
										id="filterRole"
										label={t('adminPanel.relevantMall')}
										value={filterMall}
										select
										onChange={handleUpdatedPreferredMall}
									>
										<MenuItem value="">
											{t('adminPanel.allMall')}
										</MenuItem>
										<MenuItem value="Westfield Mall of Scandinavia">
											Westfield Mall of Scandinavia
										</MenuItem>
										<MenuItem value="Täby Centrum">
											Westfield Täby Centrum
										</MenuItem>
										<MenuItem value="Nacka Forum">
											Nacka Forum
										</MenuItem>
									</TextField>
								</Grid>
							)}
							<Grid item>
								<Grid
									sx={{
										minWidth: {
											xs: 'calc(100vw - 16px)',
											sm: '250px',
										},
									}}
								>
									<FilterCalender
										selectedDate={filterDate}
										setSelectedDate={setFilterDate}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						{role === 'Upprättare' ? (
							<Button
								sx={{
									height: '50px',
									minWidth: {
										xs: 'calc(100vw - 16px)',
										sm: '340px',
									},
									maxWidth: {
										xs: 'calc(100vw - 16px)',
										sm: '340px',
									},
								}}
								variant="contained"
								startIcon={<Add />}
								onClick={newFireAlarm}
							>
								{t('fireAlarmPanel.newFireAlarm')}
							</Button>
						) : null}
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Box height={750}>
					<CustomDataGrid
						type={'fire-alarm-panel'}
						data={allFireAlarms}
						columns={columns}
					/>
				</Box>
			</Grid>
		</Grid>
	)
}

export default FireAlarmPanel
