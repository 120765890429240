import CircleIcon from '@mui/icons-material/Circle'
import { Box } from '@mui/material'

interface Props {
	children: JSX.Element | string
}

const Bullet = ({ children }: Props) => {
	return (
		<li className="mb-1 last:mb-0">
			<Box
				sx={{
					display: 'flex',
					alignItems: 'start',
					columnGap: '5px',
				}}
			>
				<CircleIcon sx={{ fontSize: '0.5rem', mt: '0.45rem' }} />
				<div>{children}</div>
			</Box>
		</li>
	)
}

export default Bullet
