import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import { GET_USER_DOCUMENTS } from '../../graphql/queries/userDocuments'
import LoadingSpinner from './LoadingSpinner'
import { useAuth } from '../../hooks/useAuth'

type Props = {
	updateKey: any
	setIdOfRow: any
	setDeleteDocumentIsOpen: any
	userDocumentUpdate: any
}

interface UserDocumentsPanelItem {
	id: string
	documentFileName: string[]
	documentPersonName: string
	documentType: string
	expiryDate: string
}

const UserDocumentsDataGrid = ({
	updateKey,
	setIdOfRow,
	setDeleteDocumentIsOpen,
	userDocumentUpdate,
}: Props) => {
	const { t } = useTranslation()
	const { jwt } = useAuth()

	const [allUserDocuments, setAllUserDocuments] = useState<
		UserDocumentsPanelItem[]
	>([])

	const { loading, error, data, refetch } = useQuery(GET_USER_DOCUMENTS, {
		variables: { jwt, type: 'all' },
	})

	const columns: GridColDef[] = [
		{
			field: 'documentFileName',
			headerName: t('userDocuments.document'),
			flex: 20,
			minWidth: 250,
			editable: false,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params: GridRenderCellParams<String[]>) => (
				<strong>
					<Link href={params.value[1].valueOf()} target="_blank">
						<TextSnippetOutlinedIcon sx={{ mr: 1 }} />
						{params.value[0]}.pdf
					</Link>
				</strong>
			),
		},
		{
			field: 'documentPersonName',
			headerName: t('userDocuments.name'),
			flex: 15,
			minWidth: 150,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'documentType',
			headerName: t('userDocuments.type'),
			flex: 15,
			minWidth: 120,
			editable: false,
			headerAlign: 'left',
			align: 'left',
		},
		{
			field: 'expiryDate',
			headerName: t('userDocuments.expiryDate'),
			flex: 30,
			minWidth: 260,
			editable: false,
			headerAlign: 'left',
			align: 'left',
			renderCell: (params: GridRenderCellParams<String>) => (
				<>
					<Typography>{params.value}</Typography>
					<Button
						sx={{
							textDecoration: 'none',
							borderRadius: '999999px',
							backgroundColor: '#00000014',
							color: 'primary',
							textTransform: 'none',
							ml: 2,
						}}
						onClick={() => userDocumentUpdate(params.id)}
					>
						{t('userDocuments.renewDocument')}
					</Button>
				</>
			),
		},
		{
			field: 'deleteDocument',
			headerName: '',
			flex: 1,
			editable: false,
			headerAlign: 'left',
			align: 'right',
			renderCell: (params: GridRenderCellParams<String>) => (
				<DeleteOutlineIcon
					sx={{
						color: '#ACACAC',
						cursor: 'pointer',
					}}
				/>
			),
		},
	]

	useEffect(() => {
		if (data) {
			const allUserDocumentsParsed = JSON.parse(data.getUserDocuments)

			const userDocumentsPanelItems: UserDocumentsPanelItem[] =
				allUserDocumentsParsed.map((userDocument: any) => {
					return {
						id: userDocument.documentID,
						documentFileName: [
							userDocument.documentFileName,
							userDocument.documentURLObj[0],
						],
						documentPersonName: userDocument.documentPersonName,
						documentType: t(
							`userDocuments.${userDocument.documentType}`
						),
						expiryDate: userDocument.updatedOn,
					}
				})

			setAllUserDocuments(userDocumentsPanelItems)
		}
		if (updateKey) {
			refetch()
		}
	}, [loading, data, updateKey])

	const refetchData = () => {
		refetch()
	}

	const handleCellClick = (params: any) => {
		setIdOfRow(params.row.id)
		if (params.field === 'deleteDocument') {
			setDeleteDocumentIsOpen(true)
		}
	}

	if (loading) return <LoadingSpinner />

	return (
		<DataGrid
			rows={allUserDocuments}
			columns={columns}
			pageSize={10}
			autoHeight={true}
			rowHeight={60}
			headerHeight={80}
			hideFooterSelectedRowCount={true}
			sx={{
				backgroundColor: 'white',
				'.MuiDataGrid-columnSeparator': {
					display: 'none',
				},
				'.MuiDataGrid-columnHeaders': {
					borderRadius: '10px',
					backgroundColor: 'primary.main',
				},
				'.MuiDataGrid-columnHeader': {
					backgroundColor: 'primary.main',
				},
				'.MuiDataGrid-columnHeaderTitle': {
					color: 'white',
				},
				'&.MuiDataGrid-root': {
					backgroundColor: 'rgb(249 250 251)',
					border: 'none',
				},
				'& .MuiDataGrid-virtualScrollerRenderZone': {
					'& .MuiDataGrid-row': {
						'&:nth-of-type(2n)': {
							backgroundColor: 'white',
						},
						'&:hover': {
							background: ' #EBF0F4 !important',
						},
					},
				},
			}}
			rowsPerPageOptions={[10]}
			onCellClick={handleCellClick}
		/>
	)
}

export default UserDocumentsDataGrid
