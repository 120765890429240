import { Box, SxProps } from '@mui/material'

interface Props {
	sx?: SxProps
	children: JSX.Element[] | JSX.Element | string
}

const InfoText = ({ sx, children }: Props) => {
	const initSx: SxProps = {
		py: '12px',
		px: '16px',
		borderLeftWidth: '0.3rem',
		borderColor: '#6b7280',
		backgroundColor: '#f9fafb',
		fontWeight: 300,
	}

	return <Box sx={{ ...initSx, ...sx }}>{children}</Box>
}

export default InfoText
