import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './i18n'

import { RecoilRoot } from 'recoil'

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { AuthProvider } from './contexts/AuthProvider'
import { createUploadLink } from 'apollo-upload-client'

export const devEnv = process.env.NODE_ENV === 'development'

export const API_BASE_URL = devEnv
	? 'http://localhost:8080/graphql'
	: 'https://backend-wpphichdpa-ew.a.run.app/graphql'

const httpLink = createUploadLink({
	uri: `${API_BASE_URL}`,
})

export const apolloClient = new ApolloClient({
	uri: API_BASE_URL,
	cache: new InMemoryCache(),
	link: httpLink,
})

ReactDOM.render(
	<React.StrictMode>
		<RecoilRoot>
			<ApolloProvider client={apolloClient}>
				<AuthProvider>
					<App />
				</AuthProvider>
			</ApolloProvider>
		</RecoilRoot>
	</React.StrictMode>,
	document.getElementById('root')
)
