import { Grid, MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import CustomDateInput from '../formik/CustomDateInput'
import CustomFileInputWithTitle from '../formik/CustomFileInputWithTitle'
import CustomSelectInput from '../formik/CustomSelectInput'
import CustomSwitchInput from '../formik/CustomSwitchInput'
import CustomTextAreaInput from '../formik/CustomTextAreaInput'
import CustomTextInput from '../formik/CustomTextInput'
import CustomTimeInput from '../formik/CustomTimeInput'

interface Props {
	setSnackbarData: any
	getUploadedFiles: any
	isCompleteRequest: boolean
}

const EditWorkPrepForm = ({
	setSnackbarData,
	getUploadedFiles,
	isCompleteRequest,
}: Props) => {
	const { values } = useFormikContext<any>()
	const { t } = useTranslation()

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByCompany')}
					name="createdByCompany"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByPersonName')}
					name="createdByPersonName"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.telephoneNumberToCreator')}
					name="telephoneNumberToCreator"
					type="text"
					inputMode="tel"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.emailToCreator')}
					name="emailToCreator"
					type="text"
					inputMode="email"
				/>
			</Grid>
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.workSteps')}
					name="workSteps"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={8}>
				<CustomTextInput
					label={t('workPrep.workArea')}
					name="workArea"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomSelectInput
					label={t('workPrep.relevantMall')}
					name="relevantMall"
					sx={{ width: '100%' }}
					disabled={isCompleteRequest}
				>
					<MenuItem value="Westfield Mall of Scandinavia">
						Westfield Mall of Scandinavia
					</MenuItem>
					<MenuItem value="Nacka Forum">Nacka Forum</MenuItem>
					<MenuItem value="Täby Centrum">
						Westfield Täby Centrum
					</MenuItem>
				</CustomSelectInput>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.numPeople')}
					name="numPeople"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.floor')}
					name="floor"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTextInput
					label={t('workPrep.storeIdOrName')}
					name="storeIdOrName"
					type="text"
				/>
			</Grid>
			<CustomDivider />
			<Grid item xs={12} sm={4}>
				<CustomDateInput
					label={t('workPrep.startDate')}
					name="startDate"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomDateInput label={t('workPrep.endDate')} name="endDate" />
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.startingTime')}
					name="startingTime"
				/>
			</Grid>
			<Grid item xs={12} sm={4}>
				<CustomTimeInput
					label={t('workPrep.endingTime')}
					name="endingTime"
				/>
			</Grid>
			<Box
				component={Grid}
				item
				display={{ xs: 'none', sm: 'block' }}
				xs={0}
				sm={4}
			/>
			<CustomDivider />
			<Grid item xs={12}>
				<Typography marginBottom={'30px'} fontWeight={500}>
					{t('workPrep.insuranceExistsQuestion')}
				</Typography>
				<Grid container columnSpacing={4} rowSpacing={1}>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('workPrep.insuranceCompanyNameQuestion')}
							name="insuranceCompanyName"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t(
								'workPrep.insuranceContractNumberQuestion'
							)}
							name="insuranceContractNumber"
							type="text"
						/>
					</Grid>
				</Grid>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<Typography fontWeight={500}>
					{t('workPrep.scheduleForTheProject')}
				</Typography>
				{getUploadedFiles('scheduleForTheProject')}
				<CustomFileInputWithTitle
					translationLanguage={
						'workPrep.attachScheduleForTheProjectComment'
					}
					formAttribute={'scheduleForTheProject'}
					formValues={values}
					setSnackbar={setSnackbarData}
				/>
			</Grid>
			<CustomDivider />
			{/* Project client */}
			<Grid item xs={12}>
				<Typography fontWeight={500} marginBottom={'30px'}>
					{t('workPrep.clientTitle')}
				</Typography>

				<Grid container columnSpacing={4} rowSpacing={1}>
					<Grid item xs={12} sm={4}>
						<CustomTextInput
							label={t('workPrep.clientName')}
							name="clientName"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<CustomTextInput
							label={t('workPrep.clientTelephone')}
							name="clientTelephone"
							type="text"
							inputMode="tel"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<CustomTextInput
							label={t('workPrep.clientEmail')}
							name="clientEmail"
							type="text"
							inputMode="email"
						/>
					</Grid>
				</Grid>
			</Grid>
			<CustomDivider />
			{/* Project manager */}
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.projectManagerExistsQuestion')}
					name="projectManagerExists"
					sx={{ mb: '20px' }}
				/>
				{values.projectManagerExists && (
					<Grid container columnSpacing={4} rowSpacing={1}>
						<Grid item xs={12} sm={4}>
							<CustomTextInput
								label={t('workPrep.nameToProjectManager')}
								name="nameToProjectManager"
								type="text"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<CustomTextInput
								label={t('workPrep.telephoneToProjectManager')}
								name="telephoneToProjectManager"
								type="text"
								inputMode="tel"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<CustomTextInput
								label={t('workPrep.emailToProjectManager')}
								name="emailToProjectManager"
								type="text"
								inputMode="email"
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			<CustomDivider />

			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.workWithWaterWillBeDoneQuestion')}
					name="workWithWaterWillBeDone"
				/>
				{values.workWithWaterWillBeDone && (
					<>
						{getUploadedFiles('workWithWaterCertificate')}
						<CustomFileInputWithTitle
							translationLanguage={
								'workPrep.workWithWaterCertificateComment'
							}
							formAttribute={'workWithWaterCertificate'}
							formValues={values}
							setSnackbar={setSnackbarData}
						/>
					</>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.safeWaterComment">
						Here you can read more about the
						<a
							href="https://www.sakervatten.se"
							rel="noreferrer"
							target="_blank"
						>
							safe water certificate
						</a>
					</Trans>
				</Typography>
			</Grid>
			<CustomDivider />

			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.electricalWorkWillBeDoneQuestion')}
					name="electricalWorkWillBeDone"
				/>
				{values.electricalWorkWillBeDone && (
					<>
						{getUploadedFiles('electricalWorkCertificate')}
						<CustomFileInputWithTitle
							translationLanguage={
								'workPrep.electricalWorkCertificateComment'
							}
							formAttribute={'electricalWorkCertificate'}
							formValues={values}
							setSnackbar={setSnackbarData}
						/>
					</>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.electricalWorkWillBeDoneComment">
						Here you can get the
						<a
							href="https://e-tjanster.elsakerhetsverket.se/foretag/kolla-elforetaget"
							rel="noreferrer"
							target="_blank"
						>
							certificate for the electrical installer from the
							Swedish Electrical Safety Authority
						</a>
					</Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.constructionWorkWillTakePlaceQuestion')}
					name="constructionWorkWillTakePlace"
					sx={{ mb: '0px' }}
				/>
				{values.constructionWorkWillTakePlace && (
					<>
						{getUploadedFiles('constructionEnvironmentWorkPlan')}
						<CustomFileInputWithTitle
							translationLanguage={
								'workPrep.attachConstructionEnvironmentWorkPlanComment'
							}
							formAttribute={'constructionEnvironmentWorkPlan'}
							formValues={values}
							setSnackbar={setSnackbarData}
						/>
					</>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.constructionWorkWillTakePlaceComment">
						What counts as
						<a
							href="https://www.av.se/produktion-industri-och-logistik/bygg/"
							rel="noreferrer"
							target="_blank"
						>
							construction work?
						</a>
						<br />
						<a
							href="https://www.av.se/produktion-industri-och-logistik/bygg/arbetsmiljoplan/"
							rel="noreferrer"
							target="_blank"
						>
							Here you will find more information about a work
							environment plan
						</a>
					</Trans>
				</Typography>
				{values.constructionWorkWillTakePlace && (
					<>
						{/* URW BAS-U */}
						<CustomSwitchInput
							label={t('workPrep.urwBaseUExistsQuestion')}
							name="baseUAndBasePExists"
						/>
						{values.baseUAndBasePExists && (
							<Grid container columnSpacing={4} rowSpacing={1}>
								<Grid item xs={12}>
									{getUploadedFiles('baseUCertificate')}
									<CustomFileInputWithTitle
										translationLanguage={
											'workPrep.baseUCertificateComment'
										}
										formAttribute={'baseUCertificate'}
										formValues={values}
										setSnackbar={setSnackbarData}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.nameToURWBaseU')}
										name="nameToURWBaseU"
										type="text"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t(
											'workPrep.telephoneToURWBaseU'
										)}
										name="telephoneToURWBaseU"
										type="text"
										inputMode="tel"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.emailToURWBaseU')}
										name="emailToURWBaseU"
										type="text"
										inputMode="email"
									/>
								</Grid>
							</Grid>
						)}
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.urwBaseUExistsComment">
								The work preparation applies together with URW´s
								guidelines for working environment with
								associated rules of procedure and protection,
								these must be available to the executor.
								<br />
								<a
									href="https://www.av.se/produktion-industri-och-logistik/bygg/ansvar-vid-byggnads--och-anlaggningsarbete/byggarbetsmiljosamordnare/"
									rel="noreferrer"
									target="_blank"
								>
									Here you will find more information about
									BAS-U/P
								</a>
							</Trans>
						</Typography>

						{/* BAS-P */}
						<CustomSwitchInput
							label={t('workPrep.basePExistsQuestion')}
							name="baseUAndBasePExists"
						/>
						{values.baseUAndBasePExists && (
							<Grid container columnSpacing={4} rowSpacing={1}>
								<Grid item xs={12}>
									{getUploadedFiles('basePCertificate')}
									<CustomFileInputWithTitle
										translationLanguage={
											'workPrep.basePCertificateComment'
										}
										formAttribute={'basePCertificate'}
										formValues={values}
										setSnackbar={setSnackbarData}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.nameToBaseP')}
										name="nameToBaseP"
										type="text"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.telephoneToBaseP')}
										name="telephoneToBaseP"
										type="text"
										inputMode="tel"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<CustomTextInput
										label={t('workPrep.emailToBaseP')}
										name="emailToBaseP"
										type="text"
										inputMode="email"
									/>
								</Grid>
							</Grid>
						)}
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.urwBaseUExistsComment">
								The work preparation applies together with URW´s
								guidelines for working environment with
								associated rules of procedure and protection,
								these must be available to the executor.
								<br />
								<a
									href="https://www.av.se/produktion-industri-och-logistik/bygg/ansvar-vid-byggnads--och-anlaggningsarbete/byggarbetsmiljosamordnare/"
									rel="noreferrer"
									target="_blank"
								>
									Here you will find more information about
									BAS-U/P
								</a>
							</Trans>
						</Typography>
					</>
				)}
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t(
						'workPrep.willTheWorkTakeLongerThan30DaysQuestion'
					)}
					name="willTheWorkTakeLongerThan30Days"
				/>
				{values.willTheWorkTakeLongerThan30Days && (
					<>
						{getUploadedFiles(
							'willTheWorkTakeLongerThan30DaysCertificate'
						)}
						<CustomFileInputWithTitle
							translationLanguage={
								'workPrep.willTheWorkTakeLongerThan30DaysCertificateComment'
							}
							formAttribute={
								'willTheWorkTakeLongerThan30DaysCertificate'
							}
							formValues={values}
							setSnackbar={setSnackbarData}
						/>
					</>
				)}
				<Typography fontWeight={300} fontSize={14}>
					<Trans i18nKey="workPrep.willTheWorkTakeLongerThan30DaysComment">
						Here you will find more information about
						<a
							href="https://www.av.se/produktion-industri-och-logistik/bygg/forhandsanmalan-av-byggarbetsplats/"
							rel="noreferrer"
							target="_blank"
						>
							advance notification of construction sites
						</a>
					</Trans>
				</Typography>
			</Grid>
			<CustomDivider />

			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.certainRisks.question')}
					name="certainRisks"
				/>

				<Typography fontWeight={300} fontSize={14} sx={{ mt: '-20px' }}>
					{t('workPrep.certainRisks.comment')}
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.areaWhereWorkWillBePerformed.question')}
					name="areaWhereWorkWillBePerformed"
				/>
				<Typography fontWeight={300} fontSize={14} sx={{ mt: '-20px' }}>
					{t('workPrep.areaWhereWorkWillBePerformed.question')}
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.measureForWorkEnvironmentRisks')}
					name="measureForWorkEnvironmentRisks"
				/>

				<Typography fontWeight={300} fontSize={14} sx={{ mt: '-20px' }}>
					{t('workPrep.measureForWorkEnvironmentRisksComment')}
				</Typography>
			</Grid>
			<CustomDivider />
			{/* //Should infobox be shown in edit?
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						my: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('workPrep.infoBox2.title')}
					</Typography>
					<ul className="ml-4">
						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet1')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet2')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet3')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet4')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox2.bullet5')}
							</Typography>
						</Bullet>
					</ul>
				</InfoText>
			</Grid>
				*/}
			<Grid item xs={12}>
				<CustomTextAreaInput
					label={t('workPrep.describeWorkSteps')}
					name="describeWorkSteps"
				/>

				<Typography fontWeight={300} fontSize={14} sx={{ mt: '-20px' }}>
					{t('workPrep.describeWorkStepsComment')}
				</Typography>
			</Grid>
			<CustomDivider />
			{/* //Should infobox be shown in edit?
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						mt: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('workPrep.infoBox1.title')}
					</Typography>

					<ul className="ml-4">
						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet1')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet2')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet3')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet4')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet5')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet6')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								<Trans i18nKey="workPrep.infoBox1.bullet7">
									So called Dust-Control
									<span className="font-medium underline">
										SHOULD
									</span>
									be on the work surface during dust and smoke
									generating operations.
								</Trans>
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet8')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet9')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography fontWeight={300}>
								{t('workPrep.infoBox1.bullet10')}
							</Typography>
						</Bullet>

						<Bullet>
							<Typography
								fontWeight={500}
								sx={{
									textDecoration: 'underline',
								}}
							>
								{t('workPrep.infoBox1.bullet11')}
							</Typography>
						</Bullet>
					</ul>
				</InfoText>
			</Grid>
			<CustomDivider />
			*/}

			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.hotWorkPermissionQuestion')}
					name="hotWorkPermission"
				/>
				<Typography
					fontWeight={300}
					fontSize={14}
					marginBottom={'10px'}
				>
					<Trans i18nKey="workPrep.hotWorkPermissionComment"></Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.fireAlarmWillBeShutOffQuestion')}
					name="fireAlarmWillBeShutOff"
				/>
				<Typography
					fontWeight={300}
					fontSize={14}
					marginBottom={'10px'}
				>
					<Trans i18nKey="workPrep.fireAlarmWillBeShutOffComment"></Trans>
				</Typography>
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.ventilationWillBeShutOffQuestion')}
					name="ventilationWillBeShutOff"
					comment={
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.ventilationWillBeShutOffComment1" />
							<Box fontWeight={500} component={'span'}>
								<Trans i18nKey="workPrep.ventilationWillBeShutOffComment2" />
							</Box>
							<Trans i18nKey="workPrep.ventilationWillBeShutOffComment3" />
						</Typography>
					}
					sx={{ mb: '20px' }}
				/>
				{values.ventilationWillBeShutOff && (
					<CustomTextInput
						label={t('workPrep.duringWhatTimes')}
						name="whenWillVentilationBeShutOff"
						type="text"
					/>
				)}
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<CustomSwitchInput
					label={t('workPrep.sprinklerSystemWillBeShutOffQuestion')}
					name="sprinklerSystemWillBeShutOff"
					comment={
						<Typography fontWeight={300} fontSize={14}>
							<Trans i18nKey="workPrep.sprinklerSystemWillBeShutOffComment1" />
							<Box fontWeight={500} component={'span'}>
								<Trans i18nKey="workPrep.sprinklerSystemWillBeShutOffComment2" />
							</Box>
							<Trans i18nKey="workPrep.sprinklerSystemWillBeShutOffComment3" />
						</Typography>
					}
					sx={{ mb: '20px' }}
				/>
				{values.sprinklerSystemWillBeShutOff && (
					<CustomTextInput
						label={t('workPrep.duringWhatTimes')}
						name="whenWillSprinklerSystemBeShutOff"
						type="text"
					/>
				)}
			</Grid>
			<CustomDivider />
			<Grid item xs={12}>
				<Typography fontWeight={500}>
					{t('workPrep.additionalResources.title')}
				</Typography>
				<Typography
					fontWeight={300}
					fontSize={14}
					marginBottom={'10px'}
				>
					{t('workPrep.additionalResources.comment')}
				</Typography>

				<CustomTextAreaInput
					label={t('workPrep.additionalResources.title')}
					name={'additionalResources'}
				/>

				<Typography fontWeight={500} mb={'10px'}>
					{t(
						'workPrep.additionalResources.attachLiftCardsForPersonalWorkingWithLifts'
					)}
				</Typography>
				{getUploadedFiles('liftCardsForPersonalWorkingWithLifts')}
				<CustomFileInputWithTitle
					formAttribute={'liftCardsForPersonalWorkingWithLifts'}
					formValues={values}
					sx={{ mb: '10px' }}
					setSnackbar={setSnackbarData}
				/>
			</Grid>
			<CustomDivider />
		</Grid>
	)
}

export default EditWorkPrepForm
