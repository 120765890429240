import { Box, Button, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UploadFileIcon from '@mui/icons-material/UploadFile'

interface Props {
	file: string
	className?: string
	titleWeight?: number
	titleSize?: number
	fileNameWeight?: number
	fileNameSize?: number
	onFileChange: (e: any) => void
	fileNameComponents?: string
	inputName?: string
}

const FileInput = ({
	titleWeight = 400,
	titleSize = 13,
	file,
	fileNameComponents,
	onFileChange,
	inputName,
	className = '',
}: Props) => {
	const { t } = useTranslation()

	const handleClick = () => {
		const element = document.getElementById('fileInput' + file)
		if (element !== null) {
			element.click()
		}
	}

	return (
		<div className={'flex flex-col flex-[1_1_200px] ' + className}>
			<Box sx={{ mt: '0.1rem', mb: '0.1rem' }}>{fileNameComponents}</Box>
			<Tooltip title={t('workPrep.pdfTooltip')}>
				<Button
					id={'fileInputBtn' + file}
					value={'Choose file'}
					onClick={() => handleClick()}
					style={{ maxWidth: '170px', height: '50px' }}
					variant="outlined"
					startIcon={<UploadFileIcon />}
				>
					{t('workPrep.uploadButton')}
				</Button>
			</Tooltip>
			<input
				type="file"
				accept="application/pdf"
				style={{ display: 'none' }}
				id={'fileInput' + file}
				name={inputName}
				onChange={(event) => {
					{
						onFileChange(event)
					}
				}}
			/>
		</div>
	)
}

export default FileInput
