import { PDFViewer } from '@react-pdf/renderer'
import { FireAlarmAnswers } from '../../types'
import { Button } from '@mui/material'
import { FIRE_ALARM_BY_ID } from '../../graphql/queries/workPreps'
import { useNavigate, useParams } from 'react-router-dom'
import mosIcon from '../../resources/mos.png'
import nackaForumIcon from '../../resources/nackaForum.png'
import tabyCentrumIcon from '../../resources/tabyCentrum.png'
import FireAlarmPDF from '../../components/admin/pdf/FireAlarmPDF'
import { useQuery } from '@apollo/client'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import { useAuth } from '../../hooks/useAuth'

export const mallToImg = {
	'Nacka Forum': nackaForumIcon,
	'Mall of Scandinavia': mosIcon,
	'Täby Centrum': tabyCentrumIcon,
	'Westfield Mall of Scandinavia': mosIcon,
}
interface Props {
	customLang: 'sv' | 'en'
}

const OpenFireAlarmPDF = ({ customLang }: Props) => {
	const navigate = useNavigate()
	const params = useParams()

	const { role, jwt } = useAuth()

	const { loading, data } = useQuery(FIRE_ALARM_BY_ID, {
		variables: { fireAlarmId: params.fireAlarmId, jwt },
	})

	if (loading && !data) return <LoadingSpinner />

	const currentFireAlarm: FireAlarmAnswers = JSON.parse(data.fireAlarmById)

	//TODO: Change/add to look at the role and if role != some type of admin - "You need permission to vied this page" etc.
	//Could maybe done in "App.tsx"?? Might be worth looking into - to prevent duplicate code
	if (role === null) return <NotLoggedIn />

	return (
		<div className="flex flex-col h-screen">
			<Button
				onClick={() =>
					navigate(`/fire-alarm-panel/${currentFireAlarm.id}`)
				}
				style={{ height: '35px', borderRadius: 0 }}
				variant="contained"
			>
				{{ sv: 'Tillbaka', en: 'Back' }[customLang]}
			</Button>

			<div className="h-[calc(100%-35px)]">
				<PDFViewer style={{ flex: 1, width: '100%', height: '100%' }}>
					<FireAlarmPDF
						fireAlarmAnswers={currentFireAlarm}
						customLang={customLang}
					/>
				</PDFViewer>
			</div>
		</div>
	)
}

export default OpenFireAlarmPDF
