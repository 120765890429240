import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { WORK_PREP_BY_ID } from '../graphql/queries/workPreps'
import { useEffect, useState } from 'react'
import { LocationState, SnackbarData, WorkPrepAnswers } from '../types'
import { deepCopyObject } from '../utils/helperFunctions'
import LoadingSpinner from '../components/common/LoadingSpinner'
import ErrorOccurred from '../components/common/ErrorOccurred'
import PaperLooksContainer from '../components/common/PaperLooksContainer'
import {
	Alert,
	Button,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomDivider from '../components/common/CustomDivider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ArrowForward } from '@mui/icons-material'
import InfoText from '../components/common/InfoText'
import CustomDateInput from '../components/formik/CustomDateInput'
import { apolloClient } from '..'
import { EDIT_ENDDATE_WORK_PREP } from '../graphql/mutations/workPreps'

const EditEndDateWorkPrep = () => {
	const [workPrep, setWorkPrep] = useState<WorkPrepAnswers | null>(null)
	const location = useLocation()
	const state = location.state as LocationState
	const params = useParams()
	const { t } = useTranslation()
	const { loading, data } = useQuery(WORK_PREP_BY_ID, {
		variables: { workPrepId: params.workPrepId, jwt: '' },
	})
	const [workPrepHasBeenUpdated, setWorkPrepHasBeenUpdated] =
		useState<boolean>(false)
	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})
	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	useEffect(() => {
		if (data) {
			const workPrep: WorkPrepAnswers = JSON.parse(data.workPrepById)
			setWorkPrep(deepCopyObject(workPrep))
		}
	}, [data])

	const handleSubmit = async (values: any) => {
		setSnackbarData({
			message: t('workPrepDetails.updating'),
			severity: 'info',
			open: true,
		})
		await apolloClient.mutate({
			mutation: EDIT_ENDDATE_WORK_PREP,
			variables: {
				workPrepId: workPrep?.id,
				endDate: values.endDate,
			},
		})
		setSnackbarData({
			message: t('editWorkPrep.workPrepUpdated'),
			severity: 'success',
			open: true,
		})
		setWorkPrepHasBeenUpdated(true)
	}

	const isFromEmailRedirect = (state && state.isFromEmailRedirect) || false

	if (!isFromEmailRedirect) {
		return <ErrorOccurred />
	}
	if (loading) return <LoadingSpinner />

	if (!workPrep) return <ErrorOccurred />

	const today = new Date()
	today.setHours(0, 0, 0, 0)
	const initialFormikValues = {
		endDate: workPrep.endDate,
	}
	const validationSchema = Yup.object({
		endDate: Yup.date()
			.typeError(t('common.invalidDate'))
			.required(t('common.required'))
			.min(today, t('common.beforeToday')),
	})

	return (
		<>
			<PaperLooksContainer isCompleteRequest backBtnPath="/">
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
					rowSpacing={2}
				>
					<Grid item>
						<h1>{t('workPrep.title') + ': ' + workPrep.id}</h1>
					</Grid>
					<CustomDivider />
					<Grid item>
						<Formik
							initialValues={initialFormikValues}
							onSubmit={handleSubmit}
							validationSchema={validationSchema}
						>
							{({ isSubmitting }) => (
								<Form id={'1'}>
									<Grid item xs={12} sm={4}>
										<CustomDateInput
											label={t('workPrep.endDate')}
											name="endDate"
										/>
									</Grid>
									<Grid item>
										<InfoText
											sx={{
												borderColor: '#ef4444',
												backgroundColor: '#fef2f2',
												marginBottom: '10px',
											}}
										>
											<Typography>
												{t('editWorkPrep.warning')}
											</Typography>
										</InfoText>
									</Grid>
									<Grid
										item
										display={'flex'}
										justifyContent={'right'}
									>
										<Button
											disabled={
												isSubmitting ||
												workPrepHasBeenUpdated
											}
											type="submit"
											variant="contained"
											endIcon={
												isSubmitting ? (
													<CircularProgress
														size={20}
													/>
												) : (
													<ArrowForward />
												)
											}
											sx={{ height: 50 }}
										>
											{t('workPrep.save')}
										</Button>
									</Grid>
								</Form>
							)}
						</Formik>
					</Grid>
				</Grid>
			</PaperLooksContainer>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default EditEndDateWorkPrep
